import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting: 'content',
        isRequired: true,
        validate: 'type',
        possibleValues: ['string', 'object'],
        errorMessage: ['GDK PasswordMeter : Content must be defined and set to a DOM selector or Node']
    },
    {
        setting: 'fairRegex',
        isRequired: true,
        validate: 'type',
        possibleValues: ['string', 'object'],
        errorMessage: ['GDK PasswordMeter : fairRegex must be a regex string to pass into the RegExp object constructor function or a regex literal']
    },
    {
        setting: 'strongRegex',
        isRequired: true,
        validate: 'type',
        possibleValues: ['string', 'object'],
        errorMessage: ['GDK PasswordMeter : strongRegex must be a regex string to pass into the RegExp object constructor function or a regex literal']
    },
    {
        setting: 'weakString',
        isRequired: false,
        validate: 'type',
        possibleValues: ['string'],
        errorMessage: ['GDK PasswordMeter : weakString must be a string to specify another term instead of the default Weak']
    },
    {
        setting: 'fairString',
        isRequired: false,
        validate: 'type',
        possibleValues: ['string'],
        errorMessage: ['GDK PasswordMeter : fairString must be a string to specify another term instead of the default Fair']
    },
    {
        setting: 'strongString',
        isRequired: false,
        validate: 'type',
        possibleValues: ['string'],
        errorMessage: ['GDK PasswordMeter : strongString must be a string to specify another term instead of the default Strong']
    },
    {
        setting: 'onInputChange',
        isRequired: false,
        validate: 'type',
        possibleValues: ['function'],
        errorMessage: ['GDK PasswordMeter : onInputChange must be a defined and set function']
    },

];

class PasswordMeter {
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the password form field container wrapping both the field and meter
     *
     * @param {string|Object} fairRegex
     * A regex string to pass into the RegExp object constructor function or a regex literal for fair password strength
     *
     * @param {string|Object} strongRegex
     * A regex string to pass into the RegExp object constructor function or a regex literal for strong password strength
     *
     * @param {string} [weakString="Weak"]
     * A string to indicate a Weak password strength
     *
     * @param {string} [fairString="Fair"]
     * A string to indicate a Fair password strength
     *
     * @param {string} [strongString="Strong"]
     * A string to indicate a Strong password strength
     *
     * @param {function} [onInputChange]
     * A callback function with each password character input
     */
    constructor(options) {
        this._internalVars = {
            node: null //used for current node
        };

        //options with defaults set
        this._defaults = {
            weakString: 'Weak',
            fairString: 'Fair',
            strongString: 'Strong'
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);

            if(!this._internalVars.meterContainerParent.getAttribute('aria-live'))
                this._internalVars.meterContainerParent.setAttribute('aria-live', 'polite');
        }
    }

    //Public Methods

    /**
     * getCurrentStrength()
     * returns a string indicating the current password strength level
     * @returns {string}
     */

    getCurrentStrength(){
        return this._internalVars.meterContainer.getAttribute('class');
    }

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
}

// Private Methods
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.meterContainerParent = this._internalVars.node.querySelector('.password-meter-container');
    this._internalVars.meterContainer = this._internalVars.node.querySelector('.password-meter-container > div');
    this._internalVars.passwordField = this._internalVars.node.querySelector('input[type="password"]');
    this._internalVars.weakBar =  this._internalVars.meterContainer.querySelector('.weak');
    this._internalVars.fairBar =  this._internalVars.meterContainer.querySelector('.fair');
    this._internalVars.strongBar =  this._internalVars.meterContainer.querySelector('.strong');
    this._internalVars.handler = meterChange.bind(this);

    this._internalVars.strongRegexObj = new RegExp(this._options.strongRegex);
    if(this._options.strongRegex.toString().charAt(0)==='/'){
        this._internalVars.strongRegexObj = this._options.strongRegex;
    }
    this._internalVars.fairRegexObj = new RegExp(this._options.fairRegex);
    if(this._options.fairRegex.toString().charAt(0)==='/'){
        this._internalVars.fairRegexObj = this._options.fairRegex;
    }

}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    this._internalVars.passwordField.addEventListener('input', this._internalVars.handler);
}

function removeEvents(){
    this._internalVars.passwordField.removeEventListener('input', this._internalVars.handler);
}


/**
 * whatever()
 * whatever about the function
 */
function removeAll(){
    this._internalVars.meterContainer.removeAttribute('class');
}

function meterChange(e){
    if(this._internalVars.strongRegexObj.test(e.target.value)){
        removeAll.call(this);
        this._internalVars.meterContainer.classList.add('password-strong');
        this._internalVars.strongBar.innerHTML = this._options.strongString;
    }
    else if(this._internalVars.fairRegexObj.test(e.target.value)){
        removeAll.call(this);
        this._internalVars.meterContainer.classList.add('password-fair');
        this._internalVars.fairBar.innerHTML = this._options.fairString;
    }
    else if(e.target.value===""){
        removeAll.call(this);
    }
    else{
        removeAll.call(this);
        this._internalVars.meterContainer.classList.add('password-weak');
        this._internalVars.weakBar.innerHTML = this._options.weakString;
    }

    if(this._options.onInputChange){
        this._options.onInputChange();
    }
}

export default PasswordMeter;