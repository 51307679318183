import appState from '../appState';

import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
  {
    setting                 :   "content",
    isRequired              :   true,
    validate                :   "type",
    possibleValues          :   ["string","object"],
    errorMessage            :   ["GDK Tabs : Content must be defined and set to a DOM selector or Node"]
  },
  {
    setting                 :   "initialTabOpen",
    isRequired              :   false,
    validate                :   "type",
    possibleValues          :   ["number"],
    errorMessage            :   ["GDK Tabs : initialTabOpen must be set to a number"]
  },
  {
    setting                 : "tabClicked",
    isRequired              : false,
    validate                : "type",
    possibleValues          : ["function"],
    errorMessage            : ["GDK Accordion : accordionClicked must be a function"]
  }
];

class Tabs{
  /**
   * Refer to the design kit section of this component for JS examples and setting details.
   * @param {string, Object} content
   * A reference to the html tabs node
   *
   * @param {number} [initialTabOpen=1]
   * The number index of the tab to display on initialization
   *
   * @param {function} [tabClicked]
   * A callback function that gets fired when a tab is clicked to open
   *
   */
  constructor(options) {

    this._internalVars = {
      node: null,//used for content item
      tabs: null,
      panels: null,
      breakpoint : 767
    };

    //options with defaults set
    this._defaults = {
      initialTabOpen: 1
    };

    // Create options by extending defaults with the passed in arugments
    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }

    //if the required options are valid set up the environment
    if( baseComponent.validateSettings(this._options, validateSettings) ){
      this._internalVars.contentType = baseComponent.getContentType(this);
      setLocalVars.call(this);
      setEvents.call(this);
      openInitialTab.call(this);

      Array.prototype.forEach.call(this._internalVars.tabs, (element)=>{
        element.setAttribute('role', 'tab');
      });
      Array.prototype.forEach.call(this._internalVars.panels, (element)=>{
        element.setAttribute('role', 'tabpanel');
      });
    }
  }

  //Public Methods
  /**
   * destroy()
   * removes the node from the dom and any events attached
   */
  destroy(){
    removeEvents.call(this);
    this._internalVars.node.parentNode.removeChild(this._internalVars.node);

    //a little garbage collection
    for (var variableKey in this){
      if (this.hasOwnProperty(variableKey)){
        delete this[variableKey];
      }
    }
  }

}

// Private Methods

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
  //determine the type of content passed in
  if(this._internalVars.contentType === 'string'){
    this._internalVars.node = document.querySelector(this._options.content);
  }else if(this._internalVars.contentType === 'domNode'){
    this._internalVars.node = this._options.content;
  }

  this._internalVars.tabs = this._internalVars.node.querySelectorAll(".tab");
  this._internalVars.panels = this._internalVars.node.querySelectorAll(".panel");
  this._internalVars.globalPanels = document.querySelectorAll('.tabs>.panel');

  this._internalVars.handler = tabClickHandler.bind(this);
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
  window.onresize = switchMobileToDesktopTabs.bind(this);

  const eventName = UserAgentService._clickEventName();
  //set tab click events
  Array.prototype.forEach.call(this._internalVars.tabs, (element)=>{
    element.addEventListener(eventName,this._internalVars.handler);
  });
}

/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
  const eventName = UserAgentService._clickEventName();

  Array.prototype.forEach.call(this._internalVars.tabs, (element)=>{
    element.removeEventListener(eventName,this._internalVars.handler);
  });
}

/**
 * tabClickHandler()
 * Opens tab content for clicked tab and hides the rest
 */
function tabClickHandler(element){
  element.preventDefault();
  let currentNode = element.currentTarget;
  //get index of clicked tab
  let index = [].indexOf.call(this._internalVars.tabs, currentNode);
  if (this._options.tabClicked)
    if(this._options.tabClicked(currentNode)===false) return false;

  Array.prototype.map.call(this._internalVars.tabs, (element, i)=> {
    if(element===currentNode){
      if(appState.mode === "mobile" && element.classList.contains("active")) {
        element.classList.remove("active");
        element.setAttribute('aria-selected', false);
      }
      else {
        element.classList.add("active");
        element.setAttribute('aria-selected', true);
      }
    } else {
      element.classList.remove("active");
      element.setAttribute('aria-selected', false);
    }
  });

  Array.prototype.map.call(this._internalVars.panels, (element, i)=> {
    if (appState.mode === "mobile") {
      if (i === index && !element.classList.contains('active')) {
        openPanel.call(this, element);
      } else {
        closePanel.call(this, element);
      }
    } else {
      if (i === index) {
        openPanel.call(this, element);
      } else {
        closePanel.call(this, element);
      }
    }
  });
}

/**
 * openPanel()
 * Opens panel at the same index as the clicked tab and hides the other panels
 */
function openPanel(element){
  if(appState.mode === "mobile"){
    $(element).slideDown();
  }
  element.classList.add("active");
  element.setAttribute('aria-hidden', false);

}

/**
 * closePanel()
 * closes the past in panel
 */
function closePanel(element){
  if(appState.mode === "mobile"){
    $(element).slideUp();
  }
  element.classList.remove("active");
  element.setAttribute('aria-hidden', true);
}

/**
 * switchMobileToDesktopTabs()
 * keeps the current tab open while going from desktop to mobile window mode.
 */
function switchMobileToDesktopTabs(){
  Array.prototype.map.call(this._internalVars.globalPanels, (element)=> {
    element.removeAttribute("style");
    if(element.classList.contains("active")){
      openPanel.call(this,element);
    } else {
      closePanel.call(this,element);
    }
  });
}



function openInitialTab(){
  let tabIndex = this._options.initialTabOpen - 1;

  Array.prototype.map.call(this._internalVars.tabs, (element, i)=> {
    if(i===tabIndex){
      element.classList.add("active");
      element.setAttribute('aria-selected', true);
    } else {
      element.classList.remove("active");
      element.setAttribute('aria-selected', false);
    }
  });

  Array.prototype.map.call(this._internalVars.panels, (element, i)=> {
    element.removeAttribute("style");
    if(i===tabIndex){
      openPanel.call(this,element);
    } else {
      closePanel.call(this,element);
    }
  });
}


export default Tabs;