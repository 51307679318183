import baseComponent from "../baseComponent";

const validateSettings = [
    {
      setting: "content",
      isRequired: true,
      validate: "type",
      possibleValues: ["string", "object"],
      errorMessage: ["GDK DockedMessage : Content must be defined and set to a DOM selector or Node"]
    },
    {
      setting: "autoHide",
      isRequired: false,
      validate: "type",
      possibleValues: ["boolean"],
      errorMessage: ["GDK DockedMessage : AutoHide must be defined as true or false"]
    }
];

class DockedMessage {
  /**
   * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
   * @param {string|Object} content
   *  A reference to the docked real time message node
   */
  constructor(options) {
    this._internalVars = {
      node: null//used for current node
    };

    //options with defaults set
    this._defaults = {
      autoHide: false
    };
    // Create options by extending defaults with the passed in arugments
    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }
    //if the required options are valid set up the environment
    if (baseComponent.validateSettings(this._options, validateSettings)) {
      this._internalVars.contentType = baseComponent.getContentType(this);
      setLocalVars.call(this);
      init.call(this);
    }
  }

  //Public Methods

  /**
   * hides/shows the component
   */
  toggleDockedMessage() {
    toggleComponent.call(this);
  }

  /**
   * removes the node from the dom and any events attached
   */
  destroy(){
    this._internalVars.node.parentNode.removeChild(this._internalVars.node);

    //a little garbage collection
    for (var variableKey in this){
      if (this.hasOwnProperty(variableKey)){
        delete this[variableKey];
      }
    }
  }
}


function init() {
  if (this._options.autoHide) {
    if (this._options.autoHide === true) {
        hideComponent.call(this);
    } else {
        showComponent.call(this);
    }
  } else {
    showComponent.call(this);
  }
}

/**
 * Toggle Component Function
 * Displays and hides the component
 */
function toggleComponent() {
  if(this._internalVars.node.classList.contains('hidden')){
    showComponent.call(this);
  }
  else {
    hideComponent.call(this);
  }
}

function showComponent() {
    this._internalVars.node.classList.remove('hidden');
    this._internalVars.main.classList.add("docked-message-on");
    if (this._internalVars.toTopExists === true) {
        this._internalVars.toTop.classList.add("docked-message-on");
    }
    if (!this._internalVars.footer) {
        this._internalVars.footerPush.classList.add('no-footer');
    }
    else {
        this._internalVars.footer.classList.add("docked-message-on");
    }
}

function hideComponent() {
    this._internalVars.node.classList.add('hidden');
    this._internalVars.main.classList.remove("docked-message-on");
    if (this._internalVars.toTopExists === true) {
        this._internalVars.toTop.classList.remove("docked-message-on");
    }
    if (!this._internalVars.footer) {
        this._internalVars.footerPush.classList.remove('no-footer');
    }
    else {
        this._internalVars.footer.classList.remove("docked-message-on");
    }
}

function setLocalVars() {
  if (this._internalVars.contentType === 'string') {
    this._internalVars.node = document.querySelector(this._options.content);
  } else if (this._internalVars.contentType === 'domNode') {
    this._internalVars.node = this._options.content;
  }
  this._internalVars.toTopExists = false;
  if(document.querySelector(".to-top-arrow") !== null && document.querySelector(".to-top-arrow") !== 'undefined') {
    this._internalVars.toTopExists = true;
    this._internalVars.toTop = document.querySelector(".to-top-arrow");
  }
  this._internalVars.main = document.querySelector('#wrapper');
  this._internalVars.footer = document.querySelector('#primary-footer');
  this._internalVars.footerPush = document.querySelector('.footer-push');
}

export default DockedMessage;

