import appState from '../appState';

import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK NavigationalBox : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "urlSetting",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string"],
        errorMessage            :   ["GDK NavigationalBox : urlSetting must be defined and set to a URL string"]
    },
    {
        setting                 :   "targetSetting",
        isRequired              :   false,
        validate                :   "value",
        possibleValues          :   ["_self","_blank"],
        errorMessage            :   ["GDK NavigationalBox : targetSetting must be defined and set to '_self' or '_blank'"]
    }
];

class NavigationalBox{

    /**
     * Refer to the design kit section of this component for JS examples and setting details.
     * @param {string, Object} content
     * A reference to the navigational box
     *
     * @param {string} urlSetting
     * Used to set the initial URL for the navigational box
     *
     * @param {string} [targetSetting="_self"]
     * Used to set URL target to open in same page or in a new window/tab
     */
    constructor(options) {

        this._internalVars = {
            node: null//used for content item
        };

        //options with defaults set
        this._defaults = {
            targetSetting : "_self"
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            if(!this._internalVars.node.getAttribute('tabindex'))
                this._internalVars.node.setAttribute('tabindex', '0');

            this._internalVars.node.setAttribute('role', 'link');
        }
    }

    //Public Methods
    /**
     * @param {string} [url]
     * url is new URL string to set to component
     */
    setURL(url){
        this._options.urlSetting = url;
    }

    /**
     * @param {string} [target="_self"]
     * target is new target string to set to component with '_self' or '_blank'
     */
    setTarget(target){
        this._options.targetSetting = target;
    }

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    const eventName = UserAgentService._clickEventName();

    if(appState.mode == 'mobile' && appState.isTouchDevice === true && screen.width <= 480){
        this._internalVars.node.addEventListener("click", this._internalVars.handler);
    }else{
        this._internalVars.node.addEventListener(eventName, this._internalVars.handler);
        this._internalVars.node.addEventListener("keyup", this._internalVars.handler);
    }


}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    const eventName = UserAgentService._clickEventName();

    this._internalVars.node.removeEventListener(eventName, this._internalVars.handler);
    this._internalVars.node.removeEventListener("keyup", this._internalVars.handler);
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.handler = redirectionToURL.bind(this);
}

function redirectionToURL(el){
    if(el.type == 'keypress' || el.type == 'keyup' && ((el.keyCode || el.which) != 13)){
        return;
    }
    if(appState.mode == 'desktop'){
        window.open(this._options.urlSetting, this._options.targetSetting);
    }else if(appState.mode == 'mobile'){
        window.open(this._options.urlSetting, '_self');
    }
}


export default NavigationalBox;
