import baseComponent from '../baseComponent';

const validateSettings = [{
    setting: 'content',
    isRequired: true,
    validate: 'type',
    possibleValues: ['string', 'object'],
    errorMessage: ['GDK Alert : Content must be defined and set to a DOM selector or Node']
}];

class CoverageGraph {
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     *  A reference to the coverage graph node
     */
    constructor(options) {
        this._internalVars = {
            node: null, //used for current node
            breakdownNode: null,
            legendNode: null,
            labelArray: null,
            costArray: null,
            percentArray: [],
            totalSum: 0,
            percentSum: 0,
            centerCircle: null
        };

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if (baseComponent.validateSettings(this._options, validateSettings)) {
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            appendCenterCost.call(this);
            createSlices.call(this);
            createLegend.call(this);
        }
    }


    //Public Methods

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        $(this._internalVars.node).datepicker("destroy");
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
}

// Private Methods
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.breakdownNode = this._internalVars.node.querySelector('.coverage-graph-breakdown');
    if(this._internalVars.breakdownNode.dataset !== undefined){
        this._internalVars.costArray = this._internalVars.breakdownNode.dataset.costbreakdown.split(" ");
        this._internalVars.labelArray = this._internalVars.breakdownNode.dataset.labels.split(",");
    }else{
        this._internalVars.labelArray = this._internalVars.breakdownNode.getAttribute('data-labels').split(",");
        this._internalVars.costArray = this._internalVars.breakdownNode.getAttribute('data-costbreakdown').split(" ");
    }
    this._internalVars.centerCircle = this._internalVars.breakdownNode.querySelector('.circle');
    this._internalVars.legendNode = this._internalVars.node.querySelector('.legend');

}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {

}

function appendCenterCost(){
    var monetary = this._internalVars.centerCircle.querySelector('.monetary');
    var sumText = getCostSum.call(this);
    var costBreakdownArray = sumText.toString().split(".");
    var textNodeSum = document.createTextNode(costBreakdownArray[0]);
    monetary.appendChild(textNodeSum);
    var supElement = document.createElement('SUP');
    var textNodeSumDecimal = document.createTextNode("."+costBreakdownArray[1]);
    supElement.appendChild(textNodeSumDecimal);
    monetary.appendChild(supElement);
}

function createSlices(){
    var percentarray = createPercentageArray.call(this);
    var sheet = (function(){
        var style = document.createElement('STYLE');
        style.appendChild(document.createTextNode(""));
        document.head.appendChild(style);
        return style.sheet;
    })();
    for(var j=0; j<percentarray.length;j++){
        var newDiv = document.createElement('DIV');
        this._internalVars.breakdownNode.appendChild(newDiv);
        if (percentarray[j]>=180) {
            newDiv.setAttribute('class', 'pie big');
        }
        else{
            newDiv.setAttribute('class', 'pie');}
        newDiv.setAttribute('data-start', this._internalVars.percentSum.toFixed(1));
        if (j!==0) {
            setPrefixes(newDiv, "Transform", "rotate("+this._internalVars.percentSum+"deg)");
            sheet.insertRule(insertDataStartCSS(this._internalVars.percentSum), 0);
        }
        this._internalVars.percentSum += parseFloat(percentarray[j]);
        newDiv.setAttribute('data-value', percentarray[j]);
        sheet.insertRule(insertDataValueCSS(percentarray[j], percentarray.length, j), 0);
    }
}

function insertDataValueCSS(degree, length, index){
    degree=parseFloat(degree);
    var degree1 = degree;
    if(index!=length-1){
        degree1 = parseFloat(degree+1);
    }
    var cssString = ".pie[data-value='"+degree+"']:BEFORE { -moz-transform: rotate("+degree1+"deg); -ms-transform: rotate("+degree1+"deg); -webkit-transform: rotate("+degree1+"deg); -o-transform: rotate("+degree1+"deg); transform:rotate("+degree1+"deg);}";
    return cssString;
}

function insertDataStartCSS(degree){
    degree = parseFloat(degree).toFixed(1);
    var cssString = ".pie[data-start='"+degree+"'] { -moz-transform: rotate("+degree+"deg); /* Firefox */ -ms-transform: rotate("+degree+"deg); /* IE */ -webkit-transform: rotate("+degree+"deg); /* Safari and Chrome */ -o-transform: rotate("+degree+"deg); /* Opera */transform:rotate("+degree+"deg);}";
    return cssString;
}

function setPrefixes(element, property, value) {
    element.style["webkit" + property] = value;
    element.style["moz" + property] = value;
    element.style["ms" + property] = value;
    element.style["o" + property] = value;
}

function getCostSum(){
    var costArray = this._internalVars.costArray;
    var sum = this._internalVars.totalSum;
    for(var i=0;i<costArray.length;i++){
        sum += parseFloat(costArray[i]);
    }
    sum = sum.toFixed(2);
    return sum;
}

function createLegend(){
    var costArray = this._internalVars.costArray;
    var labelArray = this._internalVars.labelArray;
    for(var i=0;i<costArray.length;i++){
        this._internalVars.legendNode.querySelector('ul').appendChild(createLi(labelArray[i], parseFloat(costArray[i])));
    }
}

function createPercentageArray(){
    var costArray = this._internalVars.costArray;
    var costSum = getCostSum.call(this);
    for(var j=0;j<costArray.length;j++){
        this._internalVars.percentArray.push(costToPercentage(costArray[j], costSum));
    }
    return this._internalVars.percentArray;
}

function costToPercentage(cost, sum){
    var percent = (((cost/sum))*360);
    return percent;
}

function createLi(item, cost){
    var liEl = document.createElement("LI");
    var divEl = document.createElement("DIV");
    divEl.setAttribute('class', 'legend-item');
    liEl.appendChild(divEl);
    var labelString = item + " ($" + cost.toFixed(2) + ")";
    var hello = document.createTextNode(labelString);
    liEl.appendChild(hello);
    return liEl;
}

export default CoverageGraph;
