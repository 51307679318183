import * as utils from '../utils';
import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK UpsellCarousel : Content must be defined and set to a DOM selector or Node"]
    }
];

class UpsellCarousel {
    constructor(options) {

        console.log('UpsellCarousel initialized');

        this._internalVars = {
            node: null
        };

        //options with defaults set
        this._defaults = {

        };

        // Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            if(this._internalVars.node) {
                carouselDefaults.call(this);
            }
        }
    }
}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    window.addEventListener("load", carouselDefaults.bind(this));
    window.addEventListener("resize", carouselDefaults.bind(this));

    for(let i=0;i<this._internalVars.carouselChevronBtn.length;i++){
        this._internalVars.carouselChevronBtn[i].addEventListener("click", carouselClick.bind(this, this._internalVars.carouselChevronBtn[i]));
    }
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    window.removeEventListener("load", carouselDefaults.bind(this));
    window.removeEventListener("resize", carouselDefaults.bind(this));

    for(let i=0;i<this._internalVars.carouselChevronBtn.length;i++){
        this._internalVars.carouselChevronBtn[i].removeEventListener("click", carouselClick.bind(this, this._internalVars.carouselChevronBtn[i]));
    }
}


/**
 * toggleView()
 * instantiate View More/Less
 */
function carouselDefaults() {
    if(window.innerWidth > this._internalVars.carouselIconDisplay) {
        this._internalVars.carouselIconNumber = 4;
    }
    else if(window.innerWidth > this._internalVars.carouselIconSmallDisplay) {
        this._internalVars.carouselIconNumber = 2;
    }
    else {
        this._internalVars.carouselIconNumber = 1;
    }

    this._internalVars.carouselIconWidth = this._internalVars.carouselObject.querySelector(this._internalVars.carouselIconClass).clientWidth;

    this._internalVars.carouselObject.style.width = (this._internalVars.carouselIconNumber + 2) * this._internalVars.carouselIconWidth + 'px';
    this._internalVars.carouselItemsLeft = this._internalVars.carouselUpsellNumber - this._internalVars.carouselIconNumber;
    this._internalVars.carouselLeftVal = 0;
    this._internalVars.carouselObject.querySelector(this._internalVars.carouselIconWrapperClass).style.left = 0 + 'px';

    var wrapperWidth = this._internalVars.carouselIconWidth * this._internalVars.carouselIconNumber,
        containerWidth = this._internalVars.carouselIconWidth * this._internalVars.carouselUpsellNumber;

    document.querySelector(this._internalVars.carouselWrapperClass).style.width = wrapperWidth + 'px';
    document.querySelector(this._internalVars.carouselIconWrapperClass).style.width = containerWidth + 'px';

    chevronState.call(this);
}

function carouselClick(e) {

    if(!e.classList.contains("disabled")) {

        let itemsLeftWidth = document.querySelector(this._internalVars.carouselIconWrapperClass).style.getPropertyValue("left"),
            itemsLeftWidthValue = itemsLeftWidth.slice(0, -2),
            itemsLeftWidthVal = Number(itemsLeftWidthValue.replace(/^-/, "")),
            itemsRightWidth = (this._internalVars.carouselIconWidth * this._internalVars.carouselUpsellNumber) - itemsLeftWidthVal - (this._internalVars.carouselIconWidth * this._internalVars.carouselIconNumber);

        var operand = null;
        var totalScrollValue = null;
        var movementValue = null;

        if(e.querySelector("span").classList.contains("icon-chevron-right")){
            operand = "-";
            totalScrollValue = itemsRightWidth;
        }
        else {
            operand = "+";
            totalScrollValue = itemsLeftWidthVal;
        }

        if(this._internalVars.carouselIconWidth * this._internalVars.carouselIconNumber > totalScrollValue){
            movementValue = totalScrollValue;
        }
        else {
            movementValue = this._internalVars.carouselIconWidth * this._internalVars.carouselIconNumber;
        }

        if(operand === "+") {
            this._internalVars.carouselObject.querySelector(this._internalVars.carouselIconWrapperClass).style.left = Number(itemsLeftWidthValue) + movementValue + 'px';
        } else {
            this._internalVars.carouselObject.querySelector(this._internalVars.carouselIconWrapperClass).style.left = Number(itemsLeftWidthValue) - movementValue + 'px';
        }

        chevronState.call(this);
    }

}


function chevronState() {
    let itemsLeftWidth = document.querySelector(this._internalVars.carouselIconWrapperClass).style.getPropertyValue("left"),
        itemsLeftWidthValue = itemsLeftWidth.slice(0, -2),
        itemsLeftWidthVal = Number(itemsLeftWidthValue.replace(/^-/, "")),
        itemsRightWidth = (this._internalVars.carouselIconWidth * this._internalVars.carouselUpsellNumber) - itemsLeftWidthVal - (this._internalVars.carouselIconWidth * this._internalVars.carouselIconNumber);

  if(itemsLeftWidthVal === 0) {
      this._internalVars.carouselChevronBtn[0].classList.add("disabled");
      this._internalVars.carouselChevronBtn[0].children[0].setAttribute("disabled","disabled");
  }
    else {
      this._internalVars.carouselChevronBtn[0].classList.remove("disabled");
      this._internalVars.carouselChevronBtn[0].children[0].removeAttribute("disabled");
  }

    if(itemsRightWidth === 0){
        this._internalVars.carouselChevronBtn[1].classList.add("disabled");
        this._internalVars.carouselChevronBtn[1].children[0].setAttribute("disabled","disabled");
    }
    else {
        this._internalVars.carouselChevronBtn[1].classList.remove("disabled");
        this._internalVars.carouselChevronBtn[1].children[0].removeAttribute("disabled");
    }
}

/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.carouselIconDisplay = 645;
    this._internalVars.carouselIconSmallDisplay = 440;
    this._internalVars.carouselIconNumber = 0;
    this._internalVars.carouselIconClass = '.carousel-upsell-product';
    this._internalVars.carouselWrapperClass = '.icon-carousel';
    this._internalVars.carouselIconWrapperClass = '.icon-carousel-display';
    this._internalVars.carouselIconChevronClass = '.carousel-upsell-chevron';
    this._internalVars.carouselObject = this._internalVars.node;
    this._internalVars.carouselUpsellNumber = this._internalVars.carouselObject.querySelectorAll(this._internalVars.carouselIconClass).length;
    this._internalVars.carouselChevronBtn = this._internalVars.carouselObject.querySelectorAll(this._internalVars.carouselIconChevronClass);

}

export default UpsellCarousel;