var Tablesort = require("tablesort");

import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK SortableTableDeprecated : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "descending",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK SortableTableDeprecated : descending must be a boolean"]
    }
];


class SortableTable {
    /**
     * Refer to the design kit section of this component for JS examples and setting details.
     * @param {string, Object} content
     * A reference to the sortable table node
     *
     * @param {boolean} [descending=false]
     * The number of the item to set as the active selection on initialization. Must be greater than 0 and equal to or less than the number of segment options
     *
     */
    constructor(options) {

        this._internalVars = {
            node: null,//used for current node
            sortableTable: null//hold reference to the sortable table
        };

        //options with defaults set
        this._defaults = {
            descending : false
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            init.call(this);
        }

    }

    /**
    * update()
    * Updates the sort if you have added content
    */
    update(){
        this._internalVars.sortableTable.refresh();
    }


    /**
    * destroy()
    * removes the node from the dom and any events attached
    */
    destroy(){
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

/**
* init()
* instantiate the Tablesort
*/
function init() {

    let options = {};

    if(this._options.descending){
        options.descending = true;
    }

    Tablesort.extend('date', function(item) {
      return (
        item.search(/(Mon|Tue|Wed|Thu|Fri|Sat|Sun)\.?\,?\s*/i) !== -1 ||
        item.search(/\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}/) !== -1 ||
        item.search(/(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/i) !== -1
    ) && !isNaN(parseDate.call(this,item));
    }, function(a, b) {
      a = a.toLowerCase();
      b = b.toLowerCase();

      return parseDate.call(this,b) - parseDate.call(this,a);
    });


    Tablesort.extend('number', function(item) {
      return item.match(/^-?[£\x24Û¢´€]?\d+\s*([,\.]\d{0,2})/) || // Prefixed currency
        item.match(/^-?\d+\s*([,\.]\d{0,2})?[£\x24Û¢´€]/) || // Suffixed currency
        item.match(/^-?(\d)*-?([,\.]){0,1}-?(\d)+([E,e][\-+][\d]+)?%?$/); // Number
    }, function(a, b) {
      a = cleanNumber.call(this,a);
      b = cleanNumber.call(this,b);

      return compareNumber.call(this,b,a);
    });

    this._internalVars.sortableTable = new Tablesort(this._internalVars.node, options);

}

/**
* cleanNumber()
* regex replace for extending Tablsort to sort by numbers (and currency)
*/
function cleanNumber(i) {
  return i.replace(/[^\-?0-9.]/g, '');
}

/**
* compareNumber()
* sort function for numbers
*/
function compareNumber(a, b) {
  a = parseFloat(a);
  b = parseFloat(b);

  a = isNaN(a) ? 0 : a;
  b = isNaN(b) ? 0 : b;

  return a - b;
}

/**
* parseDate()
* prepares date for sorting
*/
function parseDate(date) {
  date = date.replace(/\-/g, '/');
  date = date.replace(/(\d{1,2})[\/\-](\d{1,2})[\/\-](\d{2})/, '$1/$2/$3'); // format before getTime

  return new Date(date).getTime();
}


/**
* setLocalVars()
* set all the local vars to passed in options
*/
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }
}



export default SortableTable;
