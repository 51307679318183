import baseComponent from "../baseComponent";
import UserAgentService from '../services/UserAgentService';

let PromiseIE = require('es6-promise-polyfill').Promise;
// get the correct click event
const clickEventName = UserAgentService._clickEventName();

const validateSettings = [
  {
    setting: "content",
    isRequired: true,
    validate: "type",
    possibleValues: ["string", "object"],
    errorMessage: ["GDK Consolidated Summary : Content must be defined and set to a DOM selector or Node"]
  },
  {
    setting: "autoLoad",
    isRequired: false,
    validate: "type",
    possibleValues: ["boolean"],
    errorMessage: ["GDK Consolidated Summary : autoLoad must be a boolean"]
  },
  {
    setting: "loadPlog",
    isRequired: false,
    validate: "type",
    possibleValues: ["function"],
    errorMessage: ["GDK Consolidated Summary : loadPlog must be a function"]
  }
];

/**
 * Consolidated Summary Component: This is integrated informative component for GEICO Counselors. Main purpose is to ease counselors
 * work by displaying various information on selected policy holder.
 */
class ConsolidatedSummary {


  /**
   *
   * @param options
   */
  constructor(options) {

    this._internalVars = {
      node: null,   //used for the current node
      consolidatedSummary: null    //holds reference to the consolidated Summary node;
    };

    this._defaults = {
      initialTabOpen: 1,
      autoLoad: true,
      loadPlog: function () { //Return an empty promise
        return new PromiseIE((resolve, reject) => {
          resolve();
        });
      }
    };

    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }

    if (baseComponent.validateSettings(this._options, validateSettings)) {
      this._internalVars.contentType = baseComponent.getContentType(this);
      setLocalVars.call(this);
      setEvents.call(this);
      init.call(this);
    }
  }

  /**
   * This method cleans up all Consolidated Summary tracks in the DOM, but does NOT destroy
   * the Consolidated Summary Object.
   */
  hide() {
    hideComponent.call(this);
  }

  /**
   * This global method displays consolodated summary.
   * @param initFix
   */
  show(initFix) {
    const node = this._internalVars.node;
    if (initFix) {
      this._internalVars.pageWrapper.classList.add('consolidated-summary-enabled');
      if (this._internalVars.toTop !== null) {
        this._internalVars.toTop.classList.add('consolidated-summary-enabled');
      }
    } else {
      displayComponent.call(this);
    }
  }

  /**
   * Destroy the Consolidated Summary component.
   */
  destroy() {
    removeEvents.call(this);
    delete this._internalVars;
  }
}

/**
 * Initializing method
 */
function init() {

  // make sure the #wrapper don't have the .pinned-consolidated-summary class;
  this._internalVars.csWrapper.classList.remove('pinned-consolidated-summary');

  if (this._options.autoLoad && this._internalVars.node.classList.contains('hidden')) {
    this.show();
  } else if (!this._options.autoLoad && !this._internalVars.node.classList.contains('hidden')) {
    this.hide();
  } else if (this._options.autoLoad && !this._internalVars.node.classList.contains('hidden')) {
    this.show(true);
  }
  this._internalVars.node.classList.remove('extended');
  if (this._internalVars.plogComponent && (!this._internalVars.plogComponent.classList || !this._internalVars.plogComponent.classList.contains('extended'))) {
    this._internalVars.plogComponent.classList.add('hidden');
  }

  /* Instantiate the tabs */
  openInitialTab.call(this);

}

/**
 * method to set events for the component
 */
function setEvents() {

  /* Tabs */
  let tabs = this._internalVars.verticalTabs.querySelectorAll('.tab');
  let plogBtn, plogResizeBtn, plogMinimizeBtn;

  /* PLOG Controls */
  if (this._internalVars.plogComponent !== null && this._internalVars.plogBtn !== null) {
    plogBtn = this._internalVars.plogBtn;
    /*PLOG Window Controls */
    plogResizeBtn = this._internalVars.plogComponent.querySelector(".plogResizeBtn");
    plogMinimizeBtn = this._internalVars.plogComponent.querySelector(".plogMinimizeBtn");
  }

  // On mouse click behavior
  this._internalVars.csWrapper.addEventListener(clickEventName, extendCSHandler.bind(this));
  // this._internalVars.csWrapper.addEventListener(clickEventName, function () {
  //   console.log("opa opa");
  // });
  // On mouse leave behavior
  // this._internalVars.csWrapper.addEventListener("mouseleave", retractCSHandler.call(this));
  this._internalVars.csWrapper.addEventListener("mouseleave", retractCSHandler.bind(this));

  // implement "pinned" functionality
  this._internalVars.node.querySelector(".consolidated-summary-pin").addEventListener(clickEventName, pinHandler.bind(this));

  Array.prototype.map.call(tabs, function (element) {
    element.addEventListener(clickEventName, tabClickHandler.bind(this));
  }.bind(this));

  if (plogBtn) {
    plogBtn.addEventListener(clickEventName, pLogToggleHandler.bind(this));
  }
  if (plogMinimizeBtn) {
    plogMinimizeBtn.addEventListener(clickEventName, minimizePlogHandler.bind(this));
  }
  if (plogResizeBtn) {
    plogResizeBtn.addEventListener(clickEventName, resizePlogHandler.bind(this));
  }

  if(this._internalVars.delete_switch !== null) {
    this._internalVars.delete_switch.addEventListener(clickEventName, toggleNotesDeleteButtons.bind(this));
  }
}

/**
 * method to remove events for the component
 */
function removeEvents() {

  /* Tabs */
  let tabs = this._internalVars.verticalTabs.querySelectorAll('.tab');
  let plogBtn, plogResizeBtn, plogMinimizeBtn;

  /* PLOG Controls */
  if (this._internalVars.plogComponent !== null && this._internalVars.plogBtn !== null) {
    plogBtn = this._internalVars.plogBtn;
    /*PLOG Window Controls */
    plogResizeBtn = this._internalVars.plogComponent.querySelector(".plogResizeBtn");
    plogMinimizeBtn = this._internalVars.plogComponent.querySelector(".plogMinimizeBtn");
  }

  // On mouse click behavior
  this._internalVars.csWrapper.removeEventListener(clickEventName, extendCSHandler.bind(this));
  // On mouse leave behavior
  this._internalVars.csWrapper.removeEventListener("mouseleave", retractCSHandler.bind(this));
  // implement "pinned" functionality
  this._internalVars.node.querySelector(".consolidated-summary-pin").removeEventListener(clickEventName, pinHandler.bind(this));

  Array.prototype.map.call(tabs, function (element) {
    element.removeEventListener(clickEventName, tabClickHandler.bind(this));
  }.bind(this));
  if (plogBtn) {
    plogBtn.removeEventListener(clickEventName, pLogToggleHandler.bind(this));
  }
  if (plogMinimizeBtn) {
    plogMinimizeBtn.removeEventListener(clickEventName, minimizePlogHandler.bind(this));
  }
  if (plogResizeBtn) {
    plogResizeBtn.removeEventListener(clickEventName, resizePlogHandler.bind(this));
  }
}

function extendCSHandler() {
  console.log('Wrapper is clicked');
  this._internalVars.node.classList.add("extended");
  if (this._internalVars.toTop !== null) {
    this._internalVars.toTop.classList.add('consolidated-summary-extended');
  }
}

function retractCSHandler() {
  this._internalVars.node.classList.remove("extended");
  if (this._internalVars.toTop !== null) {
    this._internalVars.toTop.classList.remove('consolidated-summary-extended');
  }
}

function pinHandler() {
  if (!this._internalVars.node.classList.contains("pinned")) {
    this._internalVars.pageWrapper.classList.add('pinned-consolidated-summary');
    if (this._internalVars.toTop !== null) {
      this._internalVars.toTop.classList.add('consolidated-summary-pinned');
    }
    if (this._internalVars.drtMessage !== null) {
      let dockedMsgArray = document.querySelectorAll('.docked-real-time-message-wrapper');
      Array.prototype.forEach.call(dockedMsgArray, (element) => {
        if (!element.classList.contains('con-sum-pinned')) {
          if (element.hasAttribute('id')) {
            element.classList.add('con-sum-three-hundred-fix');
          }
          element.classList.add('con-sum-pinned');
        }
      });

    }
    this._internalVars.node.classList.add("pinned");
  } else {
    this._internalVars.pageWrapper.classList.remove('pinned-consolidated-summary');
    if (this._internalVars.toTop !== null) {
      if (this._internalVars.toTop.classList.contains('consolidated-summary-pinned')) {
        this._internalVars.toTop.classList.remove('consolidated-summary-pinned');
      }
    }
    if (this._internalVars.drtMessage !== null) {
      let dockedMsgArray = document.querySelectorAll('.docked-real-time-message-wrapper');
      Array.prototype.forEach.call(dockedMsgArray, (element) => {
        if (element.classList.contains('con-sum-pinned')) {
          if (element.hasAttribute('id')) {
            element.classList.remove('con-sum-three-hundred-fix');
          }
          element.classList.remove('con-sum-pinned');
        }
      });

    }
    this._internalVars.node.classList.remove("pinned");
  }
}

function pLogToggleHandler() {
  this._options.loadPlog().then(plogToggle.call(this));

  function plogToggle() {
    // hide Policy Notes
    if (!this._internalVars.plogComponent.classList.contains("hidden")) {
      this._internalVars.plogComponent.classList.add("hidden");
      if (this._internalVars.toTop !== null) {
        this._internalVars.toTop.classList.remove('plog-enabled');
      }
    } else {
      // Display Policy Notes
      this._internalVars.plogComponent.classList.remove("hidden");
      if (this._internalVars.toTop !== null) {
        this._internalVars.toTop.classList.add('plog-enabled');
      }
    }
  }
}

function toggleNotesDeleteButtons (e) {
  e.target.parentElement.parentElement.parentElement.classList.add('admin-on');
  const notes = this._internalVars.plogComponent.querySelectorAll('.policy-note>.note-details>.delete-note');
  for( let note in notes ) {
    if (typeof notes[note] === 'object') {
        notes[note].classList.remove('hidden');
    }
  }
}

function minimizePlogHandler() {
  if (!this._internalVars.plogComponent.classList.contains("hidden")) {
    this._internalVars.plogComponent.classList.remove("maximized");
    if (this._internalVars.toTop !== null) {
      if (!this._internalVars.node.classList.contains('extended') && this._internalVars.toTop.classList.contains('consolidated-summary-extended')) {
        this._internalVars.toTop.classList.remove('consolidated-summary-extended');
      }
      if (!this._internalVars.node.classList.contains("pinned") && this._internalVars.toTop.classList.contains('consolidated-summary-pinned')) {
        this._internalVars.toTop.classList.remove('consolidated-summary-pinned');
      }
      this._internalVars.toTop.classList.remove('plog-enabled');
    }
    this._internalVars.plogComponent.classList.add("hidden");
  } else {
    this._internalVars.plogComponent.classList.remove("hidden");
  }
}

function resizePlogHandler() {
  if (!this._internalVars.plogComponent.classList.contains("maximized")) {
    if (this._internalVars.node.classList && this._internalVars.node.classList.contains("extended")) {
      this._internalVars.node.classList.remove("extended");
    }
    if (this._internalVars.node.classList && this._internalVars.node.classList.contains("pinned")) {
      this._internalVars.pageWrapper.classList.remove('pinned-consolidated-summary');
      this._internalVars.node.classList.remove("pinned");
    }
    this._internalVars.plogComponent.classList.add("maximized");
  } else {
    this._internalVars.plogComponent.classList.remove("maximized");
  }
}

/* Tabs Methods */

/**
 * tabClickHandler()
 * Opens tab content for clicked tab and hides the rest
 */
function tabClickHandler(element) {
  let currentNode = element.currentTarget;
  //get index of clicked tab
  let index = [].indexOf.call(this._internalVars.tabs, currentNode);
  if (this._options.tabClicked)
    if (this._options.tabClicked(currentNode) === false) return false;

  Array.prototype.map.call(this._internalVars.tabs, (element) => {
    if (element === currentNode) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
  });

  Array.prototype.map.call(this._internalVars.panels, (element, i) => {
    if (i === index) {
      openPanel.call(this, element);
    } else {
      closePanel.call(this, element);
    }
  });
}

/**
 * openInitialTab()
 * Opens tab content for tab set by initialTabOpen
 */
function openInitialTab() {
  let tabIndex = this._options.initialTabOpen - 1;

  Array.prototype.map.call(this._internalVars.tabs, (element, i) => {
    if (i === tabIndex) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
  });

  Array.prototype.map.call(this._internalVars.panels, (element, i) => {
    if (i === tabIndex) {
      openPanel.call(this, element);
    } else {
      closePanel.call(this, element);
    }
  });
}


/**
 * openPanel()
 * Opens panel at the same index as the clicked tab and hides the other panels
 */
function openPanel(element) {
  element.classList.add("active");
}

/**
 * closePanel()
 * closes the past in panel
 */
function closePanel(element) {
  element.classList.remove("active");
}

/**
 * Displays the component (display).
 */
function displayComponent() {
  const node = this._internalVars.node;
  /* con-sum-on */
  if (this._internalVars.node.classList) {
    if (this._internalVars.node.classList.contains('hidden')) {
      this._internalVars.node.classList.remove("hidden");
      if (!this._internalVars.pageWrapper.classList.contains('consolidated-summary-enabled')) {
        this._internalVars.pageWrapper.classList.add('consolidated-summary-enabled');
      }
      if (this._internalVars.toTop !== null) {
        if (!this._internalVars.toTop.classList.contains('consolidated-summary-enabled')) {
          this._internalVars.toTop.classList.add('consolidated-summary-enabled');
        }
      }
      if (this._internalVars.drtMessage !== null) {
        let dockedMsgArray = document.querySelectorAll('.docked-real-time-message-wrapper');
        Array.prototype.forEach.call(dockedMsgArray, (element) => {
          if (!element.classList.contains('con-sum-on')) {
            if (element.hasAttribute('id')) {
              element.classList.add('con-sum-fifty-fix');
            }
            element.classList.add('con-sum-on');
          }
        });
      }
    }
  }
}

/**
 * Hides the component (hide)
 */
function hideComponent() {
  const node = this._internalVars.node;
  // Hide Consolidated Summary if exists on the DOM
  if (this._internalVars.node.classList.contains('extended')) {
    this._internalVars.node.classList.remove('extended');
  }
  if (this._internalVars.node.classList.contains('pinned')) {
    this._internalVars.node.classList.remove('pinned');
  }
  if (!this._internalVars.node.classList.contains('hidden')) {
    this._internalVars.node.classList.add('hidden');
  }


// Clean up any Docked Real Time Message classes
//   const drtMessage = document.querySelector('.docked-real-time-message-wrapper') || null;
  if (this._internalVars.drtMessage !== null) {
    let dockedMsgArray = document.querySelectorAll('.docked-real-time-message-wrapper');
    Array.prototype.forEach.call(dockedMsgArray, (element) => {
      if (element.classList.contains('con-sum-pinned')) {
        if (element.hasAttribute('id')) {
          element.classList.remove('con-sum-three-hundred-fix');
        }
        element.classList.remove('con-sum-pinned');
      }
    });
  }
// Remove classes of the HTML DOM elements #wrapper and #to-top
  this._internalVars.pageWrapper.classList.remove('consolidated-summary-enabled');
  this._internalVars.pageWrapper.classList.remove('pinned-consolidated-summary');
  this._internalVars.node.classList.remove('pinned');
  if (this._internalVars.toTop !== null) {
    this._internalVars.toTop.classList.remove('consolidated-summary-enabled');
    this._internalVars.toTop.classList.remove('consolidated-summary-pinned');
  }
}

/**
 * Method to set local variables.
 */
function setLocalVars() {
  if (this._internalVars.contentType === 'string') {
    this._internalVars.node = document.querySelector(this._options.content);
  } else if (this._internalVars.contentType === 'domNode') {
    this._internalVars.node = this._options.content;
  }
  this._internalVars.csWrapper = this._internalVars.node.querySelector(".consolidated-summary-wrapper");
  this._internalVars.verticalTabs = this._internalVars.node.querySelector('.consolidated-summary-content-tabs');
  this._internalVars.tabs = this._internalVars.verticalTabs.querySelectorAll('.tab');
  this._internalVars.panels = this._internalVars.verticalTabs.querySelectorAll('.panel');
  // Check if Policy Log exists, then create local vars;
  this._internalVars.plogComponent = this._internalVars.node.querySelector(".plog") || null;
  this._internalVars.plogBtn = this._internalVars.node.querySelector(".plog-btn") || null;
  this._internalVars.plogForm = this._internalVars.node.querySelector(".policy-note-input-form") || null;
  this._internalVars.plogSendBtn = this._internalVars.node.querySelector(".plog-input-bar .send-btn") || null;
  // External DOM Elements
  this._internalVars.toTop = document.querySelector('#to-top') || null;
  this._internalVars.pageWrapper = document.querySelector('#wrapper') || null;
  this._internalVars.drtMessage = document.querySelector('.docked-real-time-message-wrapper') || null;
  this._internalVars.delete_switch = document.querySelector('.admin-delete-switch') || null;

}

export default ConsolidatedSummary;
