import baseComponent from '../baseComponent';

const validateSettings = [
  {
    setting: "content",
    isRequired: true,
    validate: "type",
    possibleValues: ["string", "object"],
    errorMessage: ["GDK ToTopArrow : Content must be defined and set to a DOM selector or Node"]
  },
  {
    setting: "toTopArrowClicked",
    isRequired: false,
    validate: "type",
    possibleValues: ["function"],
    errorMessage: ["GDK ToTopArrow : toTopArrowClicked must be defined and set function"]
  }
];

class ToTopArrow {
  /**
   * Refer to the design kit section of this component for JS examples and setting details.
   * @param {string, Object} content
   *  A reference to the component node to toggle
   *
   * @param {function} [toTopArrowClicked]
   * A callback function that gets fired when the To Top arrow is clicked
   *
   */
  constructor(options) {

    console.log('ToTopArrow has been initialized');

    this._internalVars = {
      node: null //used for current node
    };

    //options with defaults set
    this._defaults = {
      "getDuration": function () {
        return (pageYOffset <= 2000) ? 300 : 1200;
      }
    };

    // Create options by extending defaults with the passed in arguments
    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }


    //if the required options are valid set up the environment
    if (baseComponent.validateSettings(this._options, validateSettings)) {
      this._internalVars.contentType = baseComponent.getContentType(this);
      setLocalVars.call(this);
      setEvents.call(this);
    }
  }

  //Public Methods

  /**
   * removes the node from the dom and any events attached
   */
  destroy() {
    removeEvents.call(this);
    this._internalVars.node.parentNode.removeChild(this._internalVars.node);

    //a little garbage collection
    for (let variableKey in this) {
      if (this.hasOwnProperty(variableKey)) {
        delete this[variableKey];
      }
    }
  }
}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
  this._internalVars.button.addEventListener("click", this._internalVars.handler);
  window.addEventListener("scroll", toggleScrollBtnView.bind(this));
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
  this._internalVars.button.removeEventListener("click", this._internalVars.handler);
}

/**
 * toggleScrollBtnView()
 * @param e
 */
function toggleScrollBtnView(e) {
  if (((window.scrollY || screen.scrollY) > 200 && !this._internalVars.isDisplayed) || ((window.pageYOffset || screen.pageYOffset) > 200 && !this._internalVars.isDisplayed)) {
    this._internalVars.button.classList.add('displayed');
    this._internalVars.isDisplayed = true;
  } else if ((window.scrollY <= 100 && this._internalVars.isDisplayed) || (window.pageYOffset <= 100 && this._internalVars.isDisplayed)) {
    this._internalVars.button.classList.remove('displayed');
    this._internalVars.isDisplayed = false;
  }
}

/**
 * toggleView()
 * instantiate To Top Arrow
 */

function toggleScroll(e) {
  //TODO: set correct Duration speed!
  let duration = this._defaults.getDuration();
  e.preventDefault();
  let scrollStep = -window.scrollY / (duration / 15) || -window.pageYOffset / (duration / 15),
    scrollInterval = setInterval(function () {
      if ((window.scrollY !== 0 && window.scrollY !== undefined) || (window.pageYOffset !== 0 && window.pageYOffset !== undefined)) {
        window.scrollBy(0, scrollStep);
      }
      else clearInterval(scrollInterval);
    }, 15);

  if (this._options.toTopArrowClicked) {
    this._options.toTopArrowClicked();
  }
}

/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
  if (this._internalVars.contentType === 'string') {
    this._internalVars.node = document.querySelector(this._options.content);
  } else if (this._internalVars.contentType === 'domNode') {
    this._internalVars.node = this._options.content;
  }

  //add icon dom
  this._internalVars.node.insertAdjacentHTML('beforeend', `<div class="icon-to-top-arrow"></div>`);


  this._internalVars.button = this._internalVars.node;
  this._internalVars.isDisplayed = false;

  this._internalVars.handler = toggleScroll.bind(this);
}

export default ToTopArrow;