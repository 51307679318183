import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';
import '../../libs/jquery.maskedinput.geico';


const validateSettings = [
  {
    setting: "content",
    isRequired: true,
    validate: "type",
    possibleValues: ["string", "object"],
    errorMessage: ["GDK ZipCode : Content must be defined and set to a DOM selector or Node"]
  },
  {
    setting: "geolocation",
    isRequired: false,
    validate: "type",
    possibleValues: ["boolean"],
    errorMessage: ["GDK ZipCode : geolocation must be set to a boolean"]
  },
  {
    setting: "zipPlus4",
    isRequired: false,
    validate: "type",
    possibleValues: ["boolean"],
    errorMessage: ["GDK ZipCode : geolocation must be set to a boolean"]
  },
  {
    setting: "inputPlaceHolder",
    isRequired: false,
    validate: "type",
    possibleValues: ["string"],
    errorMessage: ["GDK ZipCode : inputPlaceHolder must be set to a string"]
  },
  {
    setting: "disabled",
    isRequired: false,
    validate: "type",
    possibleValues: ["boolean"],
    errorMessage: ["GDK ZipCod : disabled must be a boolean"]
  }
];


const ieVersion = UserAgentService._detectIE();

let selector;
let event;

if (ieVersion) {
  //set event for IE
  console.log('This is the Windows IE Setup: ' + ieVersion);
  event = document.createEvent('HTMLEvents');
  event.initEvent('gdk-zip-code-updated', true, true);
} else {
  //set event for non IE
  event = new Event('gdk-zip-code-updated');
}


/** Class that functionally instantiates a zip code input field. */
class ZipCode {
  /**
   * Refer to the design kit section of this component for JS examples and options details.
   *
   * @constructor
   * @param options {Object} - instantiation settings
   * @param options.content {string} - node identifier
   * @param options.geolocation {boolean} - activates geolocation
   * @param options.zipPlus4 {boolean} - field set to zip code + 4 format
   * @param options.inputPlaceHolder {string} - replace default placeholder
   * @param options.disable {boolean} - disables the input field
   */
  constructor(options) {
    this._internalVars = {
      node: null,//used for content item
      zipCodeFormContainerClass: '.zip-code-form',
      zipCodeInputClass: '.zip-code-input',
      zipGeoIconClass: '.zip-code-geo-icon',
      zipCodeMaxLength: 5,
      zipPlus4CodeMaxLength: 10,
      zipPlus4CodeMask: "99999-9999",
      zipCodeMask: "99999",
    };

    this._defaults = {
      zipCodePlaceHolderValue: "E.g., 12345",
      zipPlus4PlaceHolderValue: "E.g., 12345-6789"
    };

    //Create _options by extending defaults with the passed in option arguments
    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }

    //if _options are valid, set up the environment
    if (baseComponent.validateSettings(this._options, validateSettings)) {
      setLocalVars.call(this);
      init.call(this);
      setEventListeners.call(this);
    }
  }


  //Public Methods

  /**
   * @description geolocate() function that sets the zip code input field value to the value supplied by the
   * browser's "navigator.geolocation" object, if geolocation is available.
   */
  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition( (position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        const point = new google.maps.LatLng(lat, long);
        new google.maps.Geocoder().geocode(
          {'latLng': point},
          (res, status) => {
            const zip = res[0].formatted_address.match(/,\s\w{2}\s(\d{5})/);
            if(zip) {
              this._internalVars.geolocationZipCode = zip[1];
              this._internalVars.zipCodeField.value =  this._internalVars.geolocationZipCode;
              setGeolocationIconColor.call(this);
            } else {
              console.log('Geolocation error');
            }
          }
        );
      },
      (err) => console.log(`GEO ERROR ${err.message}`),
      {  enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
     );
    }
  }

  /**
   * @description destroy() function that removes the node from the dom and any events attached
   */
  destroy() {
    this._internalVars.node.parentNode.removeChild(this._internalVars.node);

    //a little garbage collection
    for (let variableKey in this) {
      if (this.hasOwnProperty(variableKey)){
        delete this[variableKey];
      }
    }
  }

}


//Private Methods

/**
 * @description init() function that initialises the input field to the option settings
 */
function init() {
  this._internalVars.zipCodeField.size = 15;

  //if not zipPlus4 set placeholder, mask and max length
  if (!this._options.zipPlus4) {
    this._internalVars.zipCodeField.maxLength = this._internalVars.zipCodeMaxLength;
    this._internalVars.zipCodeField.placeholder =
      this._options.inputPlaceHolder || this._defaults.zipCodePlaceHolderValue;
    $(this._internalVars.zipCodeField).mask(this._internalVars.zipCodeMask ,{"placeholder": ""});
  }

  //if geolocation option is true activate geolocation and display geolocate icon
  if (this._options.geolocation && !this._options.zipPlus4) {
    displayGeoIcon.call(this);
  }

  //if zipPlus4 option is true set max length, input placeholder and mask
  if (this._options.zipPlus4 && !this._options.geolocation) {
    this._internalVars.zipCodeField.maxLength = this._internalVars.zipPlus4CodeMaxLength;
    this._internalVars.zipCodeField.placeholder =
      this._options.inputPlaceHolder || this._defaults.zipPlus4PlaceHolderValue;
    $(this._internalVars.zipCodeField).mask(this._internalVars.zipPlus4CodeMask , {});
  }

  //set disabled option
  if (this._options.disabled) {
    this._internalVars.zipCodeField.setAttribute('disabled', 'disabled');
  }

}

/**
 * @description setLocalVars() function that sets local variables to option settings
 */
function setLocalVars() {
  if (document.querySelector(this._options.content)) {
    selector = this._options.content;
    this._internalVars.contentType = baseComponent.getContentType(this);

    if (this._internalVars.contentType === 'string') {
      this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
      this._internalVars.node = this._options.content;
    }

    this._internalVars.zipCodeForm =
      this._internalVars.node.querySelector(this._internalVars.zipCodeFormContainerClass);

    this._internalVars.zipCodeField =
      this._internalVars.node.querySelector(this._internalVars.zipCodeInputClass);
  }
}

/**
 * @description setEventListeners() function that adds all even listeners according to option settings
 */
function setEventListeners() {
  //if option geolocation value is true add a input change and icon click event listener
  if (this._options.geolocation) {

    //when input changes set icon color
    $(this._internalVars.zipCodeField).change(function() {
      setGeolocationIconColor.call(this);
    }.bind(this));

    //geolocation icon click changes the input value to geolocation zip code
    this._internalVars.node.querySelector(this._internalVars.zipGeoIconClass).addEventListener('click',
      function() {
        if (this._internalVars.geolocationZipCode) {
          this._internalVars.zipCodeField.value =  this._internalVars.geolocationZipCode;
          setGeolocationIconColor.call(this);
        } else {
          this.geolocate();
        }
        this._internalVars.zipCodeField.focus();
        this._internalVars.zipCodeField.blur();
      }.bind(this)
    );
  }
}

/**
 * @description displayGeoIcon() function that displays a geolocate icon on the input field
 */
function displayGeoIcon() {
  const spanNode = document.createElement("span");
  spanNode.classList.add(this._internalVars.zipGeoIconClass.slice(1));
  spanNode.classList.add("geico-icon");
  spanNode.classList.add("icon-geolocation");
  this._internalVars.zipCodeForm.appendChild(spanNode);
}

/**
 *  @description setGeolocationIconColor() function that changes the geolocate icon color to blue when
 *  the geolocation zip code and input value are the same or else color will be gray.
 */
function setGeolocationIconColor() {
  if (this._internalVars.geolocationZipCode === this._internalVars.zipCodeField.value) {
    this._internalVars.node.querySelector(this._internalVars.zipGeoIconClass).classList.add('active');
  } else {
    this._internalVars.node.querySelector(this._internalVars.zipGeoIconClass).classList.remove('active');
  }
}


export default ZipCode;
