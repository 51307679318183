import * as utils from '../utils';
import appState from '../appState';

//Global nav vars
let body,
    header,
    headerAnchors,
    nav,
    navBackground,
    actionableIcons,
    submenuWrapper,
    panelTriggers,
    headerLinks,
    currentlyOpenPanel,
    currentlyOpenSubPanel,
    panels,
    panelUL,
    panelIsOpen = false,
    hover_timer,
    panel_timer,
    open_timer,
    svgAnim_timer,
    currentNavState,
    mainListItems,
    mainListItemsAnchor,
    mainListItemsAnchorMobile,
    mainListItemsAnchorRightMobile,
    mobilenavaccordionHeadlines,
    secondaryListItemsAnchorMobile,
    svgToAnim,
    subpanelName,
    hamburgerIcons,
    dashOffsetEnd = 180,//svg animation settings
    dashArrayComplete = 183,//svg animation settings
    tickSpeed = 3.5,//svg animation settings
    tickSpeedMobile = 5,//svg animation settings
    tick = 0;//svg animation settings
    var isHovering = false;
    var isTouch = false;


function init(config) {

    if(!config || config.generateMobileNavHtml) {
        generateMobileMenuElements(config);
        /*
         * At this point the mobile nav has been generated so we
         * can initialize the view-more-less components for the first menu tier.
         * */
        var mobileNav = new GDK.Accordion({
            "content" : "#mobile-nav",
            "forceOpenSingleAccordionElement" : true
        });
    }


    console.log('Initialized Navigation');

    findDomElements();

    if (appState.mode === "mobile") {
        body.style.overflowX = "hidden";
    }

    //add the navigation background
    nav.insertAdjacentHTML('afterBegin', '<div id="nav-background"></div>');
    navBackground = document.getElementById('nav-background');

    addSVGs();
    addHamburgerMarkup();

    if (appState.mode === "desktop") {
        currentNavState = "desktop";

        if (appState.isTouchDevice) {
            initDesktopTouchEvents();
        } else {
            initDesktopEvents();
        }

    } else {
        currentNavState = "mobile";

        if (appState.isTouchDevice) {
            initMobileTouchEvents();
        } else {
            initMobileEvents();
        }

    }

    if(document.querySelector('.skip-to-content')&&(!document.body.classList.contains('with-unsupported-browser')||!document.querySelector('.unsupported-browser'))){
        document.querySelector('.skip-to-content').addEventListener('blur', function(){document.querySelector('#primary-header').style.top = '0';});
        document.querySelector('.skip-to-content').addEventListener('focus', function(){document.querySelector('#primary-header').style.top = '2rem';});
    }

    Array.prototype.forEach.call(headerAnchors, function (el, i) {
        el.setAttribute('aria-expanded', 'false');
    });

    window.addEventListener('resize', handleResize);
    handleResize();


    //for IE
    Array.prototype.forEach.call(mainListItemsAnchor, function (el, i) {
        el.addEventListener('pointerdown', function(e){
            if(e.pointerType=='touch' && el.parentNode.querySelector('.geico-nav-menu-secondary-wrapper')){
                e.preventDefault();
            }
        });
    });

    //for IE, Chrome, FF - affects all browsers
        document.querySelector('#primary-header').addEventListener('mouseover', function(){
            if(isHovering === false){
            isHovering = true;
            isTouch = false;

            appState.isTouchDevice = false;

            removeNavEvents();
            if (appState.mode === "desktop") {
                currentNavState = "desktop";

                if (appState.isTouchDevice) {
                    initDesktopTouchEvents();
                } else {
                    initDesktopEvents();
                }

            } else {
                currentNavState = "mobile";

                if (appState.isTouchDevice) {
                    initMobileTouchEvents();
                } else {
                    initMobileEvents();
                }

            }
            }
        });


}

function closeAccordionsMobileNav() {
    let openAccordians = document.querySelector('#mobile-nav').querySelectorAll(".accordion > li.open");
    Array.prototype.forEach.call(openAccordians, (el, i)=>{
        el.classList.remove("open");
        let contentContainer = el.querySelector(".accordion-content-container");
        contentContainer.style.display="none";
    });

}

/**
 * findDomElements()
 * Finds all Dom elements needed to run the navigation functionality
 */
function findDomElements() {
    body = document.getElementsByTagName('body')[0];
    header = document.getElementById('primary-header');
    nav = document.getElementById('primary-navigation');

    //find all header anchors
    headerAnchors = header.querySelectorAll('a');

    //finds any dom elements that have a data attribute of side-panel.
    //Any header element that opens a panel should have this data attribute, so we know which panel to open
    panelTriggers = header.querySelectorAll('[data-side-panel-trigger]');
    headerLinks = header.querySelectorAll('a');
    panels = nav.querySelectorAll('.panel-wrapper');

    //find all the submenus
    submenuWrapper = nav.querySelectorAll('.geico-nav-menu-secondary-wrapper');

    //find all hamburger icons
    hamburgerIcons = header.querySelectorAll('.hamburger-menu');

    //find all the actionable-icons
    actionableIcons = nav.querySelectorAll('a .geico-icon--actionable');

    Array.prototype.forEach.call(actionableIcons, function (el, i) {
        el.style.width = "60px";
        el.style.height = "60px";
        el.style.lineHeight = "60px";
        el.style.fontSize = "30px";
    });


    //find all first level li's for mobile
    mainListItems = nav.querySelectorAll('.panel-wrapper > ul > li');
    mainListItemsAnchor = nav.querySelectorAll('.panel-wrapper > ul > li > a');
    mainListItemsAnchorMobile = nav.querySelectorAll('.panel-wrapper > #mobile-nav > li > .accordion-content-container > div > ul > li > a');
    mainListItemsAnchorRightMobile = nav.querySelectorAll('.panel-wrapper.right > ul > li > a');
    mobilenavaccordionHeadlines = nav.querySelectorAll('.panel-wrapper > #mobile-nav > li > .accordion-headline');
    secondaryListItemsAnchorMobile = nav.querySelectorAll('.panel-wrapper > #mobile-nav .geico-nav-menu-secondary-wrapper > ul > li > a');


    panelUL = nav.querySelectorAll('.panel-wrapper > ul');


}

/**
 * Svetoslav Kostadinov
 * 07.Jan.2106
 *
 * Generate mobile navigation markup.
 */
function generateMobileMenuElements(config) {

    let navContainer = document.querySelectorAll('#primary-navigation')[0];
    /* Delete any existing Mobile Nav */
    if(navContainer.querySelector('[data-side-panel="mobile-menu"]') !== null) {
        navContainer.removeChild(navContainer.querySelector('[data-side-panel="mobile-menu"]'));
    }
    let mobileNavDiv = navContainer.cloneNode(true);

    // Create the mobile menu element
    let navMobilePanelWrapper = document.createElement('div');
    navMobilePanelWrapper.className = 'panel-wrapper';
    navMobilePanelWrapper.setAttribute('data-side-panel', 'mobile-menu');

    // Generate the mobile nav shell
    let mobileNavigation = document.createElement('ul');
    mobileNavigation.setAttribute('id', 'mobile-nav');
    mobileNavigation.setAttribute('class', 'accordion');

    // generate the search form section
    if(config && config.mobileSearchForm) {
        let searchForm = document.createElement('form');
        searchForm.setAttribute('method', config.mobileSearchForm.method);
        searchForm.setAttribute('action', config.mobileSearchForm.action);
        searchForm.setAttribute('class', 'search-box');

        let searchInputField = document.createElement('input');
        searchInputField.setAttribute('type', 'text');
        searchInputField.setAttribute('class', 'search-input');
        searchInputField.setAttribute('placeholder', config.mobileSearchForm.placeholder);
        searchInputField.setAttribute('name', config.mobileSearchForm.name);
        searchInputField.setAttribute('aria-label', config.mobileSearchForm.ariaLabel);

        let searchSubmitButton = document.createElement('button');
        searchSubmitButton.setAttribute('type', 'submit');
        searchSubmitButton.setAttribute('class', 'icon-search small');

        // assemble the search form
        searchForm.appendChild(searchInputField);
        searchForm.appendChild(searchSubmitButton);

        let searchResultField = document.createElement('ul');
        searchResultField.setAttribute('class', 'dropdown-list');
        searchResultField.setAttribute('style', 'display: none');

        // Add the search form to the Mobile Nav before the Tier One Elements Loop
        navMobilePanelWrapper.appendChild(searchForm);
        navMobilePanelWrapper.appendChild(searchResultField);
    }

    let mobileHeader = document.querySelector('#primary-header').cloneNode(true);
    let primaryHeaderLeftAnchors = mobileHeader.querySelectorAll('#header-left-links .desktop-links a');
    let nonMenuAnchorUL = document.createElement('UL');
    Array.prototype.forEach.call(primaryHeaderLeftAnchors, function(el, i){
       if(!el.getAttribute('data-side-panel-trigger')){
           let justAnchorLI = document.createElement('LI');
           let anchorTextNode = document.createTextNode(el.innerHTML);
           el.innerHTML = "";
           let justAnchorSpan = document.createElement('SPAN');
           justAnchorSpan.classList.add('geico-nav-menu-item');
           justAnchorSpan.appendChild(anchorTextNode);
           el.appendChild(justAnchorSpan);
           justAnchorLI.appendChild(el);
           nonMenuAnchorUL.appendChild(justAnchorLI);
       }
    });



    // generate nav tier 1 elements
    // pull nav elements from the desktop main menu.
    let navElementsArray = mobileNavDiv.querySelectorAll('.panel-wrapper');
    // Loop through and generate the mobile menu structure.
    Array.prototype.forEach.call(navElementsArray, function(element, i){
        if(element.className.indexOf("no-mobile") === -1) {
            let tierOneElement = document.createElement('li');

            // Element Headline and Label
            let tierOneHeadline = document.createElement('div');
            tierOneHeadline.setAttribute('class', 'accordion-headline');
            tierOneHeadline.setAttribute('tabindex', '0');
            let tierOneHeadlineH3 = document.createElement('h3');
            tierOneHeadlineH3.innerHTML = element.getAttribute('data-side-panel').capitalizeFirstLetter();
            tierOneHeadline.appendChild(tierOneHeadlineH3);

            //element content container
            let tierOneContentContainer = document.createElement('div');
            tierOneContentContainer.setAttribute('class', 'accordion-content-container');

            let tierOneContent = document.createElement('div');
            tierOneContent.setAttribute('class', 'accordion-content');
            tierOneContent.setAttribute('data-side-panel', 'mobile-menu');

            let tierOneContentUl = document.createElement('ul');
            // fill the ul with tier 2 and 3 elements.
            Array.prototype.forEach.call(element.querySelectorAll('ul>li[data-name]'), function(el, i){

                let classNames = el.className;
                let classArray = classNames.split(' ');

                el.className = classArray.join(' ');
                tierOneContentUl.appendChild(el);
            });

            // assemble the navigation
            tierOneContent.appendChild(tierOneContentUl);
            tierOneContentContainer.appendChild(tierOneContent);
            tierOneElement.appendChild(tierOneHeadline);
            tierOneElement.appendChild(tierOneContentContainer);
            mobileNavigation.appendChild(tierOneElement);
        }
    });

    navMobilePanelWrapper.appendChild(mobileNavigation);
    navMobilePanelWrapper.appendChild(nonMenuAnchorUL);

    navContainer.appendChild(navMobilePanelWrapper);

}

/**
 * Svetoslav Kostadinov
 * 07.Jan.2016
 *
 * @returns {string}
 */
String.prototype.capitalizeFirstLetter = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
};


/**
 * initDesktopEvents()
 * Initialize all desktop  events
 */
function initDesktopEvents() {
    console.log("initDesktopEvents");

    document.addEventListener('keyup', function(e){
       if(document.getElementById('wrapper').contains(e.target)){
           if(e.which == '9' || e.keyCode == '9'){
               if (panelIsOpen) {
                   closePanel(currentlyOpenPanel);
               }
           }
       }
    });

    Array.prototype.forEach.call(panelTriggers, function (el, i) {
        el.addEventListener('click', menuEnter);
        el.addEventListener('keydown', menuEnter);
    });

    Array.prototype.forEach.call(headerLinks, function (el, i) {
        el.addEventListener('mouseenter', displayHoverStyle);
        el.addEventListener('mouseleave', removeHoverStyle);
    });

    //keyboard navigation events on header anchors
    Array.prototype.forEach.call(headerAnchors, function (el, i) {
        addKeyboardFocus(el);
        el.addEventListener('focus', focusPanel);
    });

    Array.prototype.forEach.call(mainListItems, function (el, i) {
        el.addEventListener('click', closeAllPanels);
        el.addEventListener('mouseenter', openSubMenu);
        el.addEventListener('focus', openSubMenu, true);
    });

    //for each submenu wrapper listen for its mouse over
    //so we know when to close the menu when you roll off the submenu
    Array.prototype.forEach.call(submenuWrapper, function (el, i) {
        el.addEventListener('mouseover', submenuWrapperHover);
    });

    //for each panel listen for its mouse leave so we know when to close the menu
    Array.prototype.forEach.call(panels, function (el, i) {
        el.addEventListener('mouseenter', function(){let dataside = el.getAttribute('data-side-panel');
            Array.prototype.forEach.call(headerAnchors, (el, i) => {
                if (el.getAttribute('data-side-panel-trigger') != dataside) {
                    el.classList.add("not-open");
                }
            });
        });
        let firstFocusableInPanel = nav.querySelectorAll('.first-focusable');
        Array.prototype.forEach.call(firstFocusableInPanel, function(el, i){
            el.addEventListener('keydown', keyPressSearchBackward);
        });
        if(document.querySelector('.search input')){
            document.querySelector('.search input').addEventListener('keydown', keyPressSearchBackward);
        }

        if(!el.classList.contains('search')){
            if (el.querySelector('ul') && el.firstElementChild.nodeName == 'UL' && el.querySelector('ul').firstElementChild && el.querySelector('ul').lastElementChild) {
                el.querySelector('ul').firstElementChild.querySelector('a').addEventListener('keydown', keyPressFirstBackwards);
                if (el.querySelector('ul').lastElementChild.querySelector('.geico-nav-menu-secondary-wrapper')) {
                    el.querySelector('ul').lastElementChild.querySelector('.geico-nav-menu-secondary-wrapper').querySelector('ul').lastElementChild.querySelector('a').addEventListener('keydown', keyPressNextTab);
                } else {
                    el.querySelector('ul').lastElementChild.querySelector('a').addEventListener('keydown', keyPressNextTab);
                }
            } else {
                if (el.lastElementChild.querySelector('ul') && el.lastElementChild.querySelector('ul').lastElementChild) {
                    el.lastElementChild.querySelector('ul').lastElementChild.querySelector('a').addEventListener('keydown', keyPressNextTab);
                }
            }
        }

        navBackground.addEventListener('click', closeAllPanels);
    });

}

function removeHoverStyle(){
    Array.prototype.forEach.call(headerAnchors, (el, i) => {
        if (el != this) {
            el.classList.remove("not-open");
        }
    });
}

function displayHoverStyle(){
    Array.prototype.forEach.call(headerAnchors, (el, i) => {
        if (el != this) {
            el.classList.add("not-open");
        }
    });
}


function keyPressSearchBackward(e){
    if(e.shiftKey && e.keyCode == 9){
        e.preventDefault();

        let parentPanel = e.target.parentNode.parentNode;
        //to be refined for an easier way to implement this logic
        if(!parentPanel.getAttribute('data-side-panel')){
            parentPanel = parentPanel.parentNode;
            if(!parentPanel.getAttribute('data-side-panel')){
                parentPanel = parentPanel.parentNode;
                if(!parentPanel.getAttribute('data-side-panel')){
                    parentPanel = parentPanel.parentNode;
                }
            }
        }
        header.querySelector('[data-side-panel-trigger="' + parentPanel.getAttribute('data-side-panel') + '"]').focus();
        if (appState.mode === "desktop") {
            Array.prototype.forEach.call(headerAnchors, function (el, i) {
                el.removeAttribute('tabindex');
            });
        }
    }
}

function keyPressNextTab(e){
    if(e.shiftKey){
        return;
    }
    else if(e.which == 9 || e.keyCode == 9){
        console.log('tab only');
        console.log(e.target);
        let parentPanel = findUpParent(e.target, 'data-side-panel');
        //let parentPanel = e.target.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode;
        //console.log(header.querySelector('[data-side-panel-trigger="' + parentPanel.getAttribute('data-side-panel') + '"]').parentNode);
        header.querySelector('[data-side-panel-trigger="' + parentPanel.getAttribute('data-side-panel') + '"]').parentNode.querySelector('a').focus();
    }
}

function keyPressFirstBackwards(e){
    //header.querySelector('[data-side-panel-trigger="versions"]').focus();
    if(e.shiftKey && e.keyCode == 9){
        e.preventDefault();
        let parentPanel = e.target.parentNode.parentNode.parentNode;
        if(appState.mode == 'desktop'){
            header.querySelector('[data-side-panel-trigger="' + parentPanel.getAttribute('data-side-panel') + '"]').focus();
        }
        else if(appState.mode == 'mobile'){
            header.querySelector('.mobile-links [data-side-panel-trigger="' + parentPanel.getAttribute('data-side-panel') + '"]').focus();
        }
        console.log(parentPanel);
        console.log(header.querySelector('[data-side-panel-trigger="' + parentPanel.getAttribute('data-side-panel') + '"]'));
    }
}

function findUpParent(el, attribute) {
    while (el.parentNode) {
        el = el.parentNode;
        if (el.getAttribute(attribute))
            return el;
    }
    return null;
}

/**
 * initDesktopTouchEvents()
 * Initialize all desktop touch events
 */
function initDesktopTouchEvents() {
    console.log("initDesktopTouchEvents");

    //All panel triggers should have a click event that open or closes a panel
    Array.prototype.forEach.call(panelTriggers, function (el, i) {
        el.addEventListener('touchend', menuEnter);
        el.addEventListener('click', function(e){
            if(el.getAttribute('data-side-panel-trigger')!==''){
                e.preventDefault();
            }
        });
    });


    Array.prototype.forEach.call(mainListItemsAnchor, function (el, i) {
        el.addEventListener('click', openSubMenu);
    });

    //for each submenu wrapper listen for its mouse over
    //so we know when to close the menu when you roll off the submenu
    Array.prototype.forEach.call(submenuWrapper, function (el, i) {
        el.addEventListener('touchend', submenuWrapperHover);
    });

    navBackground.addEventListener('touchend', closeAllPanels);

}


/**
 * initMobileEvents()
 * Initialize all mobile events
 */
function initMobileEvents() {
    let isClick = false;


    document.addEventListener('keyup', function(e){
        if(document.getElementById('wrapper').contains(e.target)){
            if(e.which == '9' || e.keyCode == '9'){
                if (panelIsOpen) {
                    closePanel(currentlyOpenPanel);
                    header.querySelector('.mobile-links [data-side-panel-trigger="' + currentlyOpenPanel.getAttribute('data-side-panel') + '"]').focus();
                }
            }
        }
    });

    //All panel triggers should have a click event that open or closes a panel
    Array.prototype.forEach.call(panelTriggers, function (el, i) {
        el.addEventListener('click', menuEnter);
        el.addEventListener('keypress', menuEnter);
    });

    Array.prototype.forEach.call(headerAnchors, function (el, i) {
        addKeyboardFocus(el);
    });

    //close mobile panel when focusing on geico logo
    document.querySelector('#header-middle-links a').addEventListener('focus', function () {
        Array.prototype.forEach.call(panels, function (panel, i) {
            if (panel.classList.contains('open')) {
                closePanel(panel);
            }
        });
    });

    Array.prototype.forEach.call(mainListItemsAnchorMobile, function (el, i) {
        addKeyboardFocus(el);

        el.addEventListener('mousedown', function(){
            isClick = true;
        });

        el.addEventListener('click', openMobileSubMenu);

        el.addEventListener('focus', function (e) {
            let submenu = el.parentNode.getElementsByClassName('geico-nav-menu-secondary-wrapper');

            if (!isClick && submenu.length > 0) {
                openMobileSubMenu.bind(this)(e);
            } else {
                if (currentlyOpenSubPanel) {
                    //closeMobileSubMenu(currentlyOpenSubPanel); do not need, duplicate
                }
            }

            //reset flag
            isClick = false;

            let parentAccordion = findClosestParent(el, 'accordion-content-container');
            let accordionHeadline = parentAccordion.parentNode.querySelector('.accordion-headline');

            if(!parentAccordion.parentNode.classList.contains('open')){
                accordionHeadline.click();
            }
        });

        Array.prototype.forEach.call(secondaryListItemsAnchorMobile, function (el, i) {
            addKeyboardFocus(el);

            el.addEventListener('focus', function (e) {
                //var parentLI = el.closest('.accordion-content > ul > li');
                var parentLI = findUpParent(el, 'data-name');
                var submenu = parentLI.querySelector('.geico-nav-menu-secondary-wrapper');

                if(!parentLI.classList.contains('open')){
                    openMobileSubMenu.bind(submenu)(e);
                }

                var parentAccordion = findClosestParent(el, 'accordion-content-container');
                var accordionHeadline = parentAccordion.parentNode.querySelector('.accordion-headline');

                if(!parentAccordion.parentNode.classList.contains('open')){
                    accordionHeadline.click();
                }
            });

            el.addEventListener('click', closeAllPanels);

        });
    });

    navBackground.addEventListener('click', closeAllPanels);

    Array.prototype.forEach.call(mobilenavaccordionHeadlines, function (el, i) {
        addKeyboardFocus(el);
        el.addEventListener('click', closeSubMenufromHeadline);
    });

    Array.prototype.forEach.call(headerAnchors, function(el, i){
        el.setAttribute('tabindex', '-1');
        if(el.parentNode.parentNode.classList.contains('mobile-links')||el.parentNode.parentNode.classList.contains('right-mobile-links')||el.classList.contains('icon-geico')){
            el.removeAttribute('tabindex');
        }
    });

    Array.prototype.forEach.call(mainListItemsAnchorRightMobile, function(el, i){
        el.addEventListener('focus', function(){
            addKeyboardFocus(el);
        });
    });

}

/**
 * initMobileTouchEvents()
 * Initialize mobile touch events
 */
function initMobileTouchEvents() {

    //All panel triggers should have a click event that open or closes a panel
    Array.prototype.forEach.call(panelTriggers, function (el, i) {
        el.addEventListener('touchend', menuEnter);
    });

    Array.prototype.forEach.call(mainListItemsAnchorMobile, function (el, i) {
        el.addEventListener('click', openMobileSubMenu);
    });

    navBackground.addEventListener('touchend', closeAllPanels);

    Array.prototype.forEach.call(mobilenavaccordionHeadlines, function (el, i) {
        el.addEventListener('touchend', closeSubMenufromHeadline);
    });

}

function closeSubMenufromHeadline(){
    if (currentlyOpenSubPanel) {
        closeMobileSubMenu(currentlyOpenSubPanel);
        //console.log(subWrapper.getBoundingClientRect().top);

    }
}


/**
 * removeNavEvents()
 * Remove all navigation events
 */

function removeNavEvents() {

    Array.prototype.forEach.call(panelTriggers, function (el, i) {
        el.removeEventListener('touchstart', menuEnter);
        el.removeEventListener('click', menuEnter);
        el.removeEventListener('mouseenter', menuEnter);
        el.removeEventListener('mouseleave', menuLeave);
        el.removeEventListener('keypress', menuEnter);
        el.removeEventListener('touchend', menuEnter);
    });

    Array.prototype.forEach.call(headerAnchors, function (el, i) {
        el.removeEventListener('keypress', menuEnter);
        el.removeEventListener('focus', focusPanel);
    });

    Array.prototype.forEach.call(mainListItems, function (el, i) {
        el.removeEventListener('click', closeAllPanels);
        el.removeEventListener('mouseenter', openSubMenu);
        el.removeEventListener('focus', openSubMenu, true);
    });

    Array.prototype.forEach.call(mainListItemsAnchor, function (el, i) {
        el.removeEventListener('click', openMobileSubMenu);
        el.removeEventListener('touchstart', openSubMenu);
        el.removeEventListener('mouseenter', openSubMenu);
    });

    Array.prototype.forEach.call(submenuWrapper, function (el, i) {
        el.removeEventListener('touchstart', submenuWrapperHover);
        el.removeEventListener('mouseover', submenuWrapperHover);
    });

    Array.prototype.forEach.call(panels, function (el, i) {
        el.removeEventListener('mouseleave', panelLeave);
    });

    navBackground.removeEventListener('touchstart', closeAllPanels);
    navBackground.removeEventListener('click', closeAllPanels);

    removeTabIndexes();
}

function focusPanel(){
        Array.prototype.forEach.call(panels, function (panel, i) {
            if(panel.classList.contains('open')){
                closePanel(panel);
            }
        });
}

/**
 * removeTabIndexes()
 * remove all tabindexes from nav elements
 */
function removeTabIndexes() {
    let headerLinks = header.querySelectorAll('a');

    Array.prototype.forEach.call(headerLinks, function (headerLink) {
        headerLink.removeAttribute('tabindex');
    });

    let panelElements = nav.querySelectorAll('*');

    Array.prototype.forEach.call(panelElements, function (panelElement) {
        if(panelElement.classList&&!panelElement.classList.contains('accordion-headline'))
        panelElement.removeAttribute('tabindex');
    });
}

/**
 * addKeyboardFocus()
 * add/remove focus class to keyboard focused and blurred elements
 */
function addKeyboardFocus(element){
    let isClick = false;

    element.addEventListener('mousedown', function () {
        isClick = true;
    });

    element.addEventListener('focus', function (e) {
        if(!isClick){
            element.classList.add('keyboard-focus');
        }

        //reset flag
        isClick = false;
    });

    element.addEventListener('blur', function () {
        element.classList.remove('keyboard-focus');
    });
}

/**
 * findClosestParent()
 * finds closest parent element by class
 */
function findClosestParent(element, parentClass) {
    while ((element = element.parentElement) && !element.classList.contains(parentClass));
    return element;
}

/**
 * menuEnter()
 * Gets called whenever your mouse enters a trigger link
 */

function menuEnter(e) {
    //for keyboard navigation: if anything other than "enter" key is pressed do not continue
    if((e.type == 'keydown' || e.type == 'keypress') && ((e.keyCode || e.which) != 13)){
        return;
    }else if(e.type == 'keydown' || e.type == 'keypress' || e.type == 'click'){
        if(appState.mode === "desktop") {
            Array.prototype.forEach.call(headerAnchors, function (el, i) {
                el.setAttribute('tabindex', '-1');
            });
        }
        Array.prototype.forEach.call(submenuWrapper, function (el, i) {
            el.removeEventListener('mouseover', submenuWrapperHover);
        });
    }else if(e.type == 'mouseenter'){
        removeNavEvents();
        if (appState.isTouchDevice) {
            initDesktopTouchEvents();
        } else {
            initDesktopEvents();
        }
        if(appState.mode == "desktop"){
            if (panelIsOpen&&e.target.classList.contains('open')) {
                e.preventDefault();
                return;
            }
        }
    }else if(e.type == 'click'){
        if(appState.mode == "desktop"){
            if (panelIsOpen) {
                e.preventDefault();
                return;
            }
        }
    }

    e.preventDefault();
    let panelToFind;

    //get the name of the panel to find which is stored in the data attribute of the trigger
    if (e.target.nodeName === "A") {
        panelToFind = e.target.getAttribute('data-side-panel-trigger');
    } else {
        panelToFind = utils.getParentByTagName(e.target, "A").getAttribute('data-side-panel-trigger');
    }

    if (appState.mode === "mobile" && utils.hasClass(this, 'open')) {

        //if our mode is mobile and the panel is already open just close it
        Array.prototype.forEach.call(panels, function (el, i) {

            if (el.getAttribute('data-side-panel') === panelToFind) {
                closePanel(el);
            }


        });


    } else {
        if(this.classList.contains('open')){
            closePanel(currentlyOpenPanel);
            return;
        }

        //if there is a current panel open then close it before opening the new one
        if (panelIsOpen) {
            closePanel(currentlyOpenPanel);
        }

        //add and open class the the a tag
        this.classList.add('open');
        this.setAttribute('aria-expanded', 'true');

        //for each panel find the one that was triggered
        Array.prototype.forEach.call(panels, function (el, i) {

            if (el.getAttribute('data-side-panel') === panelToFind) {

                openPanel(el);
                if(appState.mode == 'desktop' && !appState.isTouchDevice)
                    el.style.display = "block";
                let allAinPanel = el.querySelectorAll('a');
                Array.prototype.forEach.call(allAinPanel, function(el, i){
                    el.removeAttribute('tabindex');
                });
                let allULinPanel = el.querySelectorAll('ul');
                if(el.querySelector('.geico-nav-menu-secondary-wrapper')){
                    let allSecondaryUL = el.querySelectorAll('.geico-nav-menu-secondary-wrapper > ul');
                    Array.prototype.forEach.call(allSecondaryUL, function(el, i){
                       el.removeAttribute('tabindex');
                    });
                    Array.prototype.forEach.call(allULinPanel, function(el, i){
                        el.setAttribute('tabindex', '-1');
                        let allSVGinUL = el.querySelectorAll('svg');
                        Array.prototype.forEach.call(allSVGinUL, function(el, i){
                           el.setAttribute('focusable', 'false');
                        });
                    });
                }


            }else{
                if(appState.mode == 'desktop' && !appState.isTouchDevice)
                    el.style.display = "none";
                let allAinPanel = el.querySelectorAll('a');
                Array.prototype.forEach.call(allAinPanel, function(el, i){
                   el.setAttribute('tabindex', '-1');
                });
                let allULinPanel = el.querySelectorAll('ul');

                if(el.querySelector('.geico-nav-menu-secondary-wrapper')){
                    let allSecondaryUL = el.querySelectorAll('.geico-nav-menu-secondary-wrapper > ul');
                    Array.prototype.forEach.call(allSecondaryUL, function(el, i){
                        el.setAttribute('tabindex', '-1');
                    });
                }
                Array.prototype.forEach.call(allULinPanel, function(el, i){
                    el.setAttribute('tabindex', '-1');
                });
            }
        });

        //add a class to every panel trigger you did not roll over
        Array.prototype.forEach.call(panelTriggers, (el, i) => {

            if (el != this) {
                el.classList.add("not-open");
                el.setAttribute('aria-expanded', 'false');
            }
        });

    }

}

/**
 * menuEnter()
 * Gets called whenever your mouse leaves a trigger link
 */
function menuLeave(e) {
    //When your mouse leaves the trigger detect if its over the open panel
    //If its not over the open panel then close the panel
    if (currentlyOpenPanel) {
        if (!currentlyOpenPanel.contains(e.relatedTarget)) {
            closePanel(currentlyOpenPanel);
        }
    }

}

function scrollTo(element, to, duration) {
    if (duration <= 0) return;
    var difference = to - element.scrollTop;
    var perTick = difference / duration * 10;

    setTimeout(function() {
        element.scrollTop = element.scrollTop + perTick;
        if (element.scrollTop === to) return;
        scrollTo(element, to, duration - 10);
    }, 10);
}

/**
 * panelLeave()
 * Gets fired when your mouse leaves a panel
 */
function panelLeave() {
    //only close the panel if there is not a sub panel open
    if (currentlyOpenSubPanel || currentlyOpenPanel) {
        if(!currentlyOpenPanel.contains(document.activeElement)){
            closePanel(currentlyOpenPanel);
        }
    }
}


/**
 * openPanel()
 * Opens a panel by adding the necessary open a classes
 */
function openPanel(el) {

    //if there is a panel close timer than cancel it because we just opened up another panel
    if (panel_timer) {
        clearTimeout(panel_timer);
        nav.style.display = "block";
    }

    nav.style.display = "block";


    if (appState.mode === "mobile") {
        document.querySelector('body').style.overflowY = "hidden";
        document.querySelector('body').style.position = "fixed";

        header.querySelector('#header-middle-links a').setAttribute('tabindex', '-1');

        if(el.getAttribute('data-side-panel')=='mobile-menu'){
            let allHeaderRight = header.querySelectorAll('#header-right-links .mobile-links a');
            Array.prototype.forEach.call(allHeaderRight, function(el, i){
               el.setAttribute('tabindex', '-1');
            });
        }else if(el.classList.contains('right')){
            header.querySelector('#header-middle-links a').removeAttribute('tabindex');
        }

    }

    clearTimeout(open_timer);

    open_timer = setTimeout(()=> {

        nav.classList.add('open');
        if(el.getAttribute('data-side-panel')=='mobile-menu'){
            header.classList.add('open');
        }
        el.classList.add('open');
        if(appState.mode === 'mobile'&&!appState.isTouchDevice){
            Array.prototype.forEach.call(panels, function(el, i){
                if(!el.classList.contains('open')){
                    el.style.display = "none";
                }
            });
        }

        //store the open panel
        currentlyOpenPanel = el;
        panelIsOpen = true;

    }, 50);

}

/**
 * closePanel()
 * Closes a panel by removing the necessary open a classes
 */
function closePanel(el, e) {

    if (appState.mode === "mobile") {
        document.querySelector('body').style.overflowY = "visible";
        document.querySelector('body').style.position = "relative";

        closeAccordionsMobileNav();

        header.querySelector('#header-middle-links a').removeAttribute('tabindex');

    }
    if(appState.mode === 'mobile'){
        Array.prototype.forEach.call(panels, function(el, i){

                el.removeAttribute('style');

        });
    }
    nav.classList.remove('open');
    header.classList.remove('open');
    el.classList.remove('open');
    panelIsOpen = false;
    subpanelName = null;

    Array.prototype.forEach.call(panelTriggers, function (el, i) {
        el.classList.remove('open');
        el.classList.remove('not-open');
        el.setAttribute('aria-expanded', 'false');
    });


    //if a sub menu is open then close that
    if (currentlyOpenSubPanel) {
        currentlyOpenSubPanel.classList.remove("open");
        currentlyOpenSubPanel = null;
    }


    panel_timer = setTimeout(()=> {


        Array.prototype.forEach.call(mainListItems, function (el, i) {
            el.classList.remove("open");
        });

        if (appState.mode === "mobile") {
            Array.prototype.forEach.call(submenuWrapper, function (el, i) {
                el.style.maxHeight = "0px";
            });
        }

        nav.style.display = "none";
        panel_timer = null;


    }, 500);


    removeTabIndexes();
    //no animation for kit purpose because too jagged
}

/**
 * submenuWrapperHover()
 * Detects if you have rolled over the submenuWrapper
 * so we know when to close the menu when you roll off the submenu
 */
function submenuWrapperHover(e) {
    if (currentlyOpenPanel) {
        //NodeList of items that the mouse is currently over in document order.
        //The last element in the NodeList is the most specific, each preceding
        //one should be a parent, grandparent, and so on.
        let nodes = document.querySelectorAll(":hover");

        //if the target node is a div an the last hovered node is the wrapper
        //we know we are off the submenu so close menues
        if (e.target.nodeName == "DIV" && e.target == nodes[nodes.length - 1]) {
            closePanel(currentlyOpenPanel);
        }
    }
}

/**
 * openSubMenu()
 * Opens the submenu when you click on its parent li
 * This is used when on a touch device
 */
function openSubMenu(e) {
    Array.prototype.forEach.call(headerAnchors, function (el, i) {
        el.removeAttribute('tabindex');
    });
    //dont open a subpanel if the parent is not open
    if (!panelIsOpen) {
        return;
    }

    clearTimeout(hover_timer);
    let p;
    let hoverDelay;
    if (appState.isTouchDevice) {
        p = this.parentNode;
        hoverDelay = 0;
    } else {
        p = this;
        hoverDelay = 150;
    }

    let subWrapper = p.querySelector(".geico-nav-menu-secondary-wrapper");


    if (subWrapper) {
        e.preventDefault();
    }
    hover_timer = setTimeout(()=> {

        if (!utils.hasClass(p, 'open')) {
            //animate the svg
            if(p.querySelector("svg")) {
                svgToAnim = p.querySelector("svg");
                svgAnim();
            }
        }

        //if a sub menu is open then close that
        if (currentlyOpenSubPanel) {
            currentlyOpenSubPanel.classList.remove("open");
            currentlyOpenSubPanel = null;
        }

        currentlyOpenSubPanel = p;

        if (!appState.isTouchDevice) {
            p.classList.toggle('open'); //this FF
        }

        if (subWrapper) {

            if (appState.isTouchDevice) {
                p.classList.toggle('open');
            }
        }


    }, hoverDelay);

}


/**
 * closeMobileSubMenu()
 * Opens the submenu when you click on its parent li
 */
function closeMobileSubMenu(el) {
    el.classList.remove('open');
    let subWrapper = el.querySelector(".geico-nav-menu-secondary-wrapper");
    subWrapper.style.maxHeight = "0px";
    currentlyOpenSubPanel = null;
    subpanelName = null;

}


/**
 * openMobileSubMenu()
 * Opens the submenu when you click on its parent li
 */
function openMobileSubMenu(e) {

    //console.log("openMobileSubMenu");

    //this is the list item that was clicked
    let p = this.parentNode;
    let subWrapper = p.querySelector(".geico-nav-menu-secondary-wrapper");
    let clickedSubpanelName = p.getAttribute('data-name');
    let shouldOpenSubPanel = false;
    let subMenuOpened = false;
    //console.log(subWrapper.parentNode.querySelector('a').querySelector('span').getBoundingClientRect().top);


    if (subWrapper) {
        //if there is a submenu then prevent default
        e.preventDefault();

        if (subpanelName !== clickedSubpanelName) {
            shouldOpenSubPanel = true;
        }

        ///close any open submenus
        if (currentlyOpenSubPanel) {
            subMenuOpened = true;
            closeMobileSubMenu(currentlyOpenSubPanel);
        }

        //if the submenu you are trying to open up is not already open then open the submenu
        if (shouldOpenSubPanel) {

            //store the open panel and its name
            currentlyOpenSubPanel = p;
            subpanelName = this.parentNode.getAttribute('data-name');


            if (!utils.hasClass(p, 'open')) {
                //animate the svg
                svgToAnim = p.querySelector("svg");
                svgAnim();
            }

            p.classList.toggle('open');
            let accordionContainer = searchParentByClassName(this, "accordion-content-container");

            let subWrapperUl = subWrapper.querySelector("ul");
            if (window.getComputedStyle(subWrapper).maxHeight == "0px") {

                subWrapper.style.maxHeight = outerHeight(subWrapperUl) + "px";
                accordionContainer.style.maxHeight = accordionContainer.offsetHeight + outerHeight(subWrapperUl) + "px";

                //if there are no other submenus opened, scroll the current submenu being opened to the top of the page
                if (subMenuOpened === false) {
                    let aboveNavOffset = 0;
                    let aboveNavElements = document.getElementsByClassName('above-nav');

                    Array.prototype.forEach.call(aboveNavElements, function (el, i) {
                        aboveNavOffset += el.offsetHeight;
                    });

                    let mobileMenu = document.querySelector('[data-side-panel="mobile-menu"]');
                    let offset = p.getBoundingClientRect().top + mobileMenu.scrollTop - aboveNavOffset;

                    setTimeout(()=>{
                        scrollTo(mobileMenu, offset, 200);
                    }, 296);
                }

            } else {
                accordionContainer.style.maxHeight = accordionContainer.offsetHeight - outerHeight(subWrapperUl) + "px";
                subWrapper.style.maxHeight = "0px";
            }
        }
    } else {
        closePanel(currentlyOpenPanel);
    }
}


/**
 * Svetoslav Kostadinov
 * 06.Jan.2016
 * Recursive Search of a Parent element by a search Criteria
 *
 * @param sPoint // starting point
 * @param criteria // search criteria
 * @returns {*}
 */
function searchParentByClassName(sPoint, criteria){
    if(typeof sPoint === "undefined") {
        return null;
    }
    else if(sPoint.className.indexOf(criteria) === -1) {
        let element = searchParentByClassName(sPoint.parentNode, criteria);
        if (element !== null)
            return element;
    }
    else {
        return sPoint;
    }
}

/**
 * addSVGs()
 * Adds the border SVGs to all first level nav items
 */
function addSVGs() {

    //All panel triggers should have a click event that open or closes a panel
    Array.prototype.forEach.call(actionableIcons, function (el, i) {
        el.insertAdjacentHTML('beforebegin', `<svg width="60" height="60" version="1.1" xmlns="http://www.w3.org/2000/svg" style="width: 60px; height: 60px;"><circle cx="30" cy='30' r="29" /></svg>`);
    });

    Array.prototype.forEach.call(actionableIcons, function (el, i) {
        el.style.width = "60px";
        el.style.height = "60px";
        el.style.lineHeight = "60px";
        el.style.fontSize = "30px";
    });

}


/**
 * addSVGs()
 * Adds additional markup to every hamburger item
 */
function addHamburgerMarkup() {

    //All panel triggers should have a click event that open or closes a panel
    Array.prototype.forEach.call(hamburgerIcons, function (el, i) {
        el.insertAdjacentHTML('beforeend', `<div class="hamburger"><span></span><span></span><span></span><span></span></div>`);
    });

}


/**
 * handleResize()
 * listen for window resize so we can detect the mode
 */
function handleResize() {
    if (appState.mode === "desktop" && currentNavState === "mobile") {
        //clear some styling
        Array.prototype.forEach.call(submenuWrapper, function (el, i) {
            el.removeAttribute('style');
        });
        Array.prototype.forEach.call(panels, function(el, i){
            if(!el.classList.contains('open')){
                el.removeAttribute('style');
            }
        });
        removeNavEvents();
        currentNavState = "desktop";
        if (currentlyOpenPanel) {
            closePanel(currentlyOpenPanel);
        }
        if (appState.isTouchDevice) {
            initDesktopTouchEvents();
        } else {
            initDesktopEvents();
        }
        //document.querySelector('body').style.overflowY = "visible";
        //document.querySelector('body').style.position = "relative";
        //body.style.overflowX = "visible";
        body.removeAttribute('style');

    } else if (appState.mode === "mobile" && currentNavState === "desktop") {
        removeNavEvents();
        currentNavState = "mobile";
        if (currentlyOpenPanel) {
            closePanel(currentlyOpenPanel);
        }
        if (appState.isTouchDevice) {
            initMobileTouchEvents();
        } else {
            initMobileEvents();
        }
    }
}


/**
 * closeAllPanels()
 * this force closes all menus, good for single page apps thats need to close the menu
 */
function closeAllPanels() {

    if (currentlyOpenPanel) {
        closePanel(currentlyOpenPanel);
        removeHoverStyle();
    }
}


/**
 * outerHeight()
 * used to get the height of an element that is the same across all browsers
 *
 * @param  {Object} node    DOM node
 * @return {number}
 */
function outerHeight(el) {
    let height = el.offsetHeight;
    let style = getComputedStyle(el);

    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
}


/**
 * svgAnim()
 * animates the svg around the icon
 */
function svgAnim() {

    if (appState.mode === "desktop") {
        tick += tickSpeed;
    } else {
        tick += tickSpeedMobile;
    }


    let t = tick / 100;
    let offsetStrokeDasharray = easeInQuad(t, 0, dashArrayComplete, 1);
    svgToAnim.style.strokeDasharray = `${offsetStrokeDasharray} , ${dashArrayComplete}`;

    if (tick < 100) {
        requestAnimationFrame(svgAnim.bind(this));
    } else {
        tick = 0;
    }

}


/**
 * easeInQuad()
 * Quadratic easing
 */
function easeInQuad(t, b, c, d) {
    var ts = (t /= d) * t;
    return b + c * (ts);
}
/**
 * easeInCubic()
 * Cubic easing
 */
function easeInCubic(t, b, c, d) {
    var ts = (t /= d) * t * t;
    return b + c * (ts);
}

export { init, closeAllPanels };