import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
  {
    setting                 :   "content",
    isRequired              :   true,
    validate                :   "type",
    possibleValues          :   ["string","object"],
    errorMessage            :   ["GDK DotNavigation : Content must be defined and set to a DOM selector or Node"]
  },
  {
    setting                 :   "onClicked",
    isRequired              :   false,
    validate                :   "type",
    possibleValues          :   ["function"],
    errorMessage            :   ["GDK DotNavigation : onClicked must be a function"]
  },
  {
    setting                 :   "startingPos",
    isRequired              :   false,
    validate                :   "type",
    possibleValues          :   ["number"],
    errorMessage            :   ["GDK DotNavigation : startingPos must be set to a number"]
  }
];

class DotNavigation{

  /**
   * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
   * @param {string|Object} content
   * A reference to the html Dot Navigation node
   *
   * @param {function} [onClicked]
   * Callback function fired once a item is clicked
   *
   * @param {number} [startingPos=1]
   * The number of the navigation item to display on initialization
   */
  constructor(options) {

    console.log("Initialized Dot Navigation");

    this._internalVars = {
      node: null,//used for content item
      navItem: null,
      svg: `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="14px" height="14px" viewBox="0 0 16 16"preserveAspectRatio="none"><circle cx="8" cy="8" r="6.215"/></svg>`
    };

    //options with defaults set
    this._defaults = {
      startingPos: 1
    };

    // Create options by extending defaults with the passed in arugments
    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }

    //if the required options are valid set up the environment
    if( baseComponent.validateSettings(this._options, validateSettings) ){
      this._internalVars.contentType = baseComponent.getContentType(this);
      setLocalVars.call(this);
      setEvents.call(this);
      setStartPosition.call(this);
    }
  }

  //Public Methods


  /**
   * @param {number} newIndex
   * newIndex is the number of the navigation item to display first on initialization
   */
  goTo(newIndex){

    let index = newIndex - 1;

    Array.prototype.map.call(this._internalVars.navItem, (e, rank)=> {
      if(rank===index){
        e.parentNode.classList.add("dot-navigation-active");
      } else {
        e.parentNode.classList.remove("dot-navigation-active");
      }
    });
  }

  /**
   * destroy()
   * removes the node from the dom and any events attached
   */
  destroy(){
    removeEvents.call(this);
    this._internalVars.node.parentNode.removeChild(this._internalVars.node);

    //a little garbage collection
    for (var variableKey in this){
      if (this.hasOwnProperty(variableKey)){
        delete this[variableKey];
      }
    }
  }

}

// Private Methods

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
  //determine the type of content passed in
  if(this._internalVars.contentType === 'string'){
    this._internalVars.node = document.querySelector(this._options.content);
  }else if(this._internalVars.contentType === 'domNode'){
    this._internalVars.node = this._options.content;
  }

  this._internalVars.navItem = this._internalVars.node.querySelectorAll("li a");

  //set click events
  Array.prototype.forEach.call(this._internalVars.navItem, (el, i)=>{
    el.insertAdjacentHTML('beforeend', this._internalVars.svg);
  });

  this._internalVars.handler = dotNavigationClickHandler.bind(this);
}


/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {

  const eventName = UserAgentService._clickEventName();

  //set click events
  Array.prototype.forEach.call(this._internalVars.navItem, (el, i)=>{
    el.addEventListener(eventName,this._internalVars.handler);
  });
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {

  const eventName = UserAgentService._clickEventName();

  //remove click events
  Array.prototype.forEach.call(this._internalVars.navItem, (el, i)=>{
    el.removeEventListener(eventName,this._internalVars.handler);
  });
}


/**
 * dotNavigationClickHandler()
 * Gets fired when you click on a navigation item
 */
function dotNavigationClickHandler(el){
  el.preventDefault();
  let currentNode = el.currentTarget;
  let currentIndex;
  // add selected class
  Array.prototype.map.call(this._internalVars.navItem, (e, rank)=> {
    if(e===currentNode){
      e.parentNode.classList.add("dot-navigation-active");
      currentIndex = rank;
    } else {
      e.parentNode.classList.remove("dot-navigation-active");
    }
  });

  // fire onClicked Callback if there is one
  setTimeout( ()=> {
    if(this._options.onClicked)
      this._options.onClicked(currentIndex + 1);
  }, 10);

}

/**
 * setStartPosition()
 * Set starting link
 */
function setStartPosition() {

  let linkIndex = this._options.startingPos - 1;

  Array.prototype.map.call(this._internalVars.navItem, (e, rank)=> {
    if(rank===linkIndex){
      e.parentNode.classList.add("dot-navigation-active");
    }
  });

}


export default DotNavigation;