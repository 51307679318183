import * as utils from '../utils';
import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK MoreInfoButton : Content must be defined and set to a DOM selector or Node"]
    }
];

class MoreInfoButton {
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the html More Info Button node
     */
    constructor(options) {

        console.log('MoreInfoButton initialized');

        this._internalVars = {
            node: null, //used for current node
            button : null,
            ul : null,
            wrapperElementId : "wrapper",
            wrapper : null
        };

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);

            this._internalVars.button.setAttribute('aria-expanded', 'false');
            this._internalVars.button.setAttribute('role', 'button');
        }
    }


    /**
    * destroy()
    * removes the node from the dom and any events attached
    */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
    
}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    const eventName = UserAgentService._clickEventName();

    this._internalVars.node.addEventListener('mouseleave', this._internalVars.menuHandler);
    this._internalVars.button.addEventListener("pointerdown", this._internalVars.checkTouchHandler);
    this._internalVars.button.addEventListener(eventName, this._internalVars.toggleHandler);
    this._internalVars.button.addEventListener("keydown", this._internalVars.keyHandler);
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    const eventName = UserAgentService._clickEventName();

    this._internalVars.node.removeEventListener('mouseleave', this._internalVars.menuHandler);
    this._internalVars.button.removeEventListener("pointerdown", this._internalVars.checkTouchHandler);
    this._internalVars.button.removeEventListener(eventName, this._internalVars.toggleHandler);
    this._internalVars.button.removeEventListener("keydown", this._internalVars.keyHandler);
}

function closeMenu(){
        this._internalVars.ul.classList.remove("options-in");
        this._internalVars.ul.classList.add("options-out");
        this._internalVars.button.setAttribute('aria-expanded', 'false');
        setTimeout( ()=> {
            this._internalVars.ul.style.display = "none";
        }, 300);

}

function checkForTouch(el){
    console.log(el.pointerType);
    if(el.pointerType=='touch'){
        this._internalVars.node.removeEventListener('mouseleave', this._internalVars.menuHandler);
    }else{
        this._internalVars.node.addEventListener('mouseleave', this._internalVars.menuHandler);
    }
}

/**
 * toggleOptions()
 * toggles the options view
 */
function toggleOptions() {

    if(utils.hasClass(this._internalVars.ul, "options-in")){
        this._internalVars.ul.classList.remove("options-in");
        this._internalVars.ul.classList.add("options-out");
        this._internalVars.button.setAttribute('aria-expanded', 'false');
        setTimeout( ()=> {
            this._internalVars.ul.style.display = "none";
        }, 300);
    }else{
        this._internalVars.ul.style.display = "block";
        this._internalVars.ul.classList.remove("options-out");
        this._internalVars.ul.classList.add("options-in");
        this._internalVars.button.setAttribute('aria-expanded', 'true');

        checkUlPosistion.call(this);
    }

    
}

function keyOptions(e) {
    if((e.type == 'keydown' || e.type == 'keypress') && ((e.keyCode || e.which) != 13))
    {
        return;
    }else{
        if(utils.hasClass(this._internalVars.ul, "options-out")){
            this._internalVars.ul.style.display = "block";
            this._internalVars.ul.classList.remove("options-out");
            this._internalVars.ul.classList.add("options-in");
            this._internalVars.button.setAttribute('aria-expanded', 'true');
            let allA = this._internalVars.ul.querySelectorAll('a');
            allA[0].focus();

            checkUlPosistion.call(this);
        }
    }

}

/**
 * toggleOptions()
 * detect if there is enough space to see the whole menu when opened
 * if not we need to scroll the body so there is enough space
 */
function checkUlPosistion() {

    if((this._internalVars.ul.getBoundingClientRect().top + this._internalVars.ul.offsetHeight) > window.innerHeight){

        let windowHeight = window.innerHeight;
        let moreInfoHeight = (this._internalVars.ul.getBoundingClientRect().top + this._internalVars.ul.offsetHeight);
        let neededSpace = moreInfoHeight - windowHeight;
        
        let currentScrollOffset = window.scrollY || window.pageYOffset;
        let newScrollOffset = Math.round(currentScrollOffset + neededSpace);

        scrollTo(newScrollOffset, 200); 
    }
}


/**
 * scrollTo()
 * Scrolls to a specific point
 * @param to
 * @param duration
 */
function scrollTo(to, duration) {
    var start = document.documentElement.scrollTop || document.body.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;
        
    var animateScroll = function(){        
        currentTime += increment;
        var val = easeInOutQuad(currentTime, start, change, duration);
        document.documentElement.scrollTop = val;
        document.body.scrollTop = val;
                                           
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

/**
* easeInQuad()
* Quadratic easing
*/
function easeInOutQuad(t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
}



/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.button = this._internalVars.node.querySelector(".more-info-button");
    this._internalVars.ul = this._internalVars.node.querySelector("ul");
    this._internalVars.wrapper = document.querySelector("#" + this._internalVars.wrapperElementId);

    this._internalVars.menuHandler = closeMenu.bind(this);
    this._internalVars.toggleHandler = toggleOptions.bind(this);
    this._internalVars.keyHandler = keyOptions.bind(this);
    this._internalVars.checkTouchHandler = checkForTouch.bind(this);
}

export default MoreInfoButton;
