window.$ = window.jQuery = jQuery;

let Chosen = require('chosen-js');

require('../../sass-kit/geico-design-kit.scss');

import appState from './appState';

import * as nav from './global/nav';
import * as footer from './global/footer';
import * as components from './global/components';

import jQuery from 'jquery';

import Modal from './components/Modal';
import Tooltip from './components/Tooltip';
import Loader from './components/Loader';
import ButtonSwitch from './components/ButtonSwitch';
import TextareaCountdown from './components/TextareaCountdown';
import AddressAutoComplete from './components/AddressAutoComplete';
import DatePicker from './components/DatePicker';
import Tabs from './components/Tabs';
import SortableTable from './components/SortableTable';
// import QuoteComparisonTable from './components/QuoteComparisonTable';
import ViewMoreLess from './components/ViewMoreLess';
import ToTopArrow from './components/ToTopArrow';
import ValidateForm from './components/ValidateForm';
import InPageNavigation from './components/InPageNavigation';
import TimelineFilter from './components/TimelineFilter';
import Timeline from './components/Timeline';
import Accordion from './components/Accordion';
import Alert from './components/Alert';
import MoreInfoButton from './components/MoreInfoButton';
import BackgroundPattern from './components/BackgroundPattern';
import BackgroundPatternPortfolioPage from './components/BackgroundPatternPortfolioPage';
import DotNavigation from './components/DotNavigation';
import EditableTable from './components/EditableTable';
import CoverageGraph from './components/CoverageGraph';
import CommonQuestionsSquares from './components/CommonQuestionsSquares';
import UpsellCarousel from './components/Upsell';
import Portfolio from './components/portfolio';
import PayPlans from './components/PayPlans';
import ConsolidatedSummary from './components/ConsolidatedSummary';
import EditComponent from './components/EditComponent';
import DockedMessage from './components/DockedMessage';
import SegmentedControl from './components/SegmentedControl';
import MultipleSelectBox from './components/MultipleSelectBox';
import CardSelections from './components/CardSelections';
import TimeInput from './components/TimeInput';
import NavigationalBox from './components/NavigationalBox';
import PasswordMeter from './components/PasswordMeter';
import CreditCard from './components/CreditCard';
import CurrencyInput from './components/CurrencyInput';
import MakePayment from './components/MakePayment';
import Navigator from './components/Navigator';
import VIN from './components/VIN';
import ZipCode from "./components/ZipCode";
import Confirmation from "./components/Confirmation";

let GDK = GDK || {};

function init() {
    let pjson = require("../../../package");
    let version = pjson.version;

    let about = {
        _comment: "This is a generated version.json file. Do not attempt to modify. Modify Version from package.json file.",
        version: version
    };

	let ProtoGDK = {
		about: about,
		navigation : nav,
		Modal : Modal,
		Tooltip : Tooltip,
		Loader : Loader,
		ButtonSwitch : ButtonSwitch,
		TextareaCountdown : TextareaCountdown,
		DatePicker : DatePicker,
		AddressAutoComplete	:	AddressAutoComplete,
		Tabs : Tabs,
		SortableTable : SortableTable,
		// QuoteComparisonTable : QuoteComparisonTable,
		ViewMoreLess : ViewMoreLess,
		ValidateForm : ValidateForm,
		InPageNavigation : InPageNavigation,
		TimelineFilter : TimelineFilter,
		Timeline : Timeline,
		Accordion : Accordion,
		ToTopArrow : ToTopArrow,
		Alert: Alert,
		MoreInfoButton: MoreInfoButton,
		BackgroundPattern: BackgroundPattern,
		BackgroundPatternPortfolioPage: BackgroundPatternPortfolioPage,
		DotNavigation: DotNavigation,
		EditableTable: EditableTable,
		CoverageGraph: CoverageGraph,
		CommonQuestionsSquares: CommonQuestionsSquares,
		ConsolidatedSummary: ConsolidatedSummary,
		UpsellCarousel: UpsellCarousel,
		footer: footer,
		PayPlans: PayPlans,
		Chosen: Chosen,
		MultipleSelectBox: MultipleSelectBox,
		Portfolio: Portfolio, 
		EditComponent: EditComponent,
		DockedMessage: DockedMessage,
		SegmentedControl: SegmentedControl,
		CardSelections: CardSelections,
		TimeInput: TimeInput,
		NavigationalBox: NavigationalBox,
		components: components,
		PasswordMeter: PasswordMeter,
		CurrencyInput: CurrencyInput,
		CreditCard: CreditCard,
		MakePayment: MakePayment,
		Navigator: Navigator,
    	VIN: VIN,
		ZipCode: ZipCode,
		Confirmation: Confirmation
	};

	for(var property in ProtoGDK) {
		if(!GDK.hasOwnProperty(property)) {
			GDK[property] = ProtoGDK[property];
		}
	}

	window.addEventListener('resize', () => {
		handleResize();
	});

	handleResize();

}


function handleResize() {
	appState.windowSize = {width : window.innerWidth || document.documentElement.clientWidth, height : window.innerHeight || document.documentElement.clientHeight};
}

init();
window.GDK = GDK;
export { GDK };
