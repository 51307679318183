import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK ButtonSwitch : Content must be defined and set to a DOM selector or Node"]
    },
     {
        setting                 :   "initialActiveSide",
        isRequired              :   false,
        validate                :   "value",
        possibleValues          :   ["left","right"],
        errorMessage            :   ["GDK ButtonSwitch : initialActiveSide must be set to left or right"]
    },
    {
        setting                 :   "leftSideClicked",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK ButtonSwitch : leftSideClicked must be a function"]
    },
     {
        setting                 :   "rightSideClicked",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK ButtonSwitch : rightSideClicked must be a function"]
    }
];

class ButtonSwitch{
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the button node to switch
     *
     * @param {string} [initialActiveSide="left"]
     * Used to designate which side of the button is initially active. Options: "left" || "right"
     *
     * @param {function} [leftSideClicked]
     * A callback function that gets fired when the left side of the button is clicked
     *
     * @param {function} [rightSideClicked]
     * A callback function that gets fired when the left side of the button is clicked
     */

    constructor(options) {

       this._internalVars = {
            node: null,//used for content item
            left: null,//clickable node on the left side of the button
            right: null,//clickable node on the right side of the button
            sideOptions: ["left","right"],
            side: null
        };

        //options with defaults set
        this._defaults = {
            initialActiveSide: this._internalVars.sideOptions[0]
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            if(!this._internalVars.node.getAttribute('tabindex'))
            this._internalVars.node.setAttribute('tabindex', '0');

            this._internalVars.node.setAttribute('role', 'checkbox');
        }
    }

    //Public Methods
    /**
     * currentSide()
     * returns the side the switch button is currently on
     * @returns {string} "left" | "right"
     */
    currentSide() {
        return this._internalVars.side;
    }

    /**
     * slideLeft()
     * invokes a click on the left side of the switch button
     */
    slideLeft(){
        if( this._internalVars.node.classList.contains("button-switch--slide-right")){
            this._internalVars.node.classList.remove("button-switch--slide-right");
            switchLeft.call(this);
        }
        else if(this._internalVars.node.classList.contains("button-switch--start-right")&&!this._internalVars.node.classList.contains("button-switch--slide-right")&&!this._internalVars.node.classList.contains("button-switch--slide-left")){
            switchLeft.call(this);
        }
    }

    /**
     * slideRight()
     * invokes a click on the right side of the switch button
     */
    slideRight(){
        if(this._internalVars.node.classList.contains("button-switch--slide-left")){
            this._internalVars.node.classList.remove("button-switch--slide-left");
            switchRight.call(this);
        }
        else if(this._internalVars.node.classList.contains("button-switch--start-left")&&!this._internalVars.node.classList.contains("button-switch--slide-right")&&!this._internalVars.node.classList.contains("button-switch--slide-left")){
            switchRight.call(this);
        }
    }

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
		this._internalVars.node.parentNode.removeChild(this._internalVars.node);

		//a little garbage collection
		for (var variableKey in this){
			if (this.hasOwnProperty(variableKey)){
				delete this[variableKey];
			}
		}
    }

}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    const eventName = UserAgentService._clickEventName();

    this._internalVars.node.addEventListener(eventName, this._internalVars.handler);
    this._internalVars.node.addEventListener("keyup", this._internalVars.handler);
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    const eventName = UserAgentService._clickEventName();

    this._internalVars.node.removeEventListener(eventName, this._internalVars.handler);
}

/**
 * switchClick()
 * if you click on button it will switch
 */
function switchClick(e){
    if(e.type == 'keypress' || e.type == 'keyup' && ((e.keyCode || e.which) != 13)){
        return;
    }

    if(this._internalVars.node.classList.contains('disabled')){
        return;
    }
    if( this._internalVars.node.classList.contains("button-switch--slide-right")){
        this._internalVars.node.classList.remove("button-switch--slide-right");
        switchLeft.call(this);
    }
    else if(this._internalVars.node.classList.contains("button-switch--slide-left")){
        this._internalVars.node.classList.remove("button-switch--slide-left");
        switchRight.call(this);
    }

    if(this._internalVars.node.classList.contains("button-switch--start-left")&&!this._internalVars.node.classList.contains("button-switch--slide-right")&&!this._internalVars.node.classList.contains("button-switch--slide-left")){
        switchRight.call(this);
    }
    else if(this._internalVars.node.classList.contains("button-switch--start-right")&&!this._internalVars.node.classList.contains("button-switch--slide-right")&&!this._internalVars.node.classList.contains("button-switch--slide-left")){
        switchLeft.call(this);
    }
}

function switchLeft(){
    this._internalVars.node.classList.add("button-switch--slide-left");
    this._internalVars.side = this._internalVars.sideOptions[0];
    this._internalVars.node.setAttribute('aria-checked', 'false');
    if(this._options.leftSideClicked)
        this._options.leftSideClicked();
}

function switchRight(){
    this._internalVars.node.classList.add("button-switch--slide-right");
    this._internalVars.side = this._internalVars.sideOptions[1];
    this._internalVars.node.setAttribute('aria-checked', 'true');
    if(this._options.rightSideClicked)
        this._options.rightSideClicked();
}


/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    //get the child spans to add click events too
    let spans = this._internalVars.node.querySelectorAll("span");
    this._internalVars.left = spans[0];
    this._internalVars.right = spans[1];

    //init the current side and add the class
    this._internalVars.side = this._options.initialActiveSide.toLowerCase();
    //check for right else use the default left
    if(this._internalVars.side === this._internalVars.sideOptions[1]) {
        this._internalVars.node.classList.add("button-switch--start-right");
        this._internalVars.node.setAttribute('aria-checked', 'true');
    }
    else {
        this._internalVars.node.classList.add("button-switch--start-left");
        this._internalVars.node.setAttribute('aria-checked', 'false');
    }

    this._internalVars.handler = switchClick.bind(this);
}


export default ButtonSwitch;
