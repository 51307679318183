import * as utils from '../utils';
import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK Portfolio : Content must be defined and set to a DOM selector or Node"]
    }
];

class Portfolio {
    constructor(options) {

        console.log('Portfolio initialized');

        this._internalVars = {
            node: null
        };

        //options with defaults set
        this._defaults = {

        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            setContainerHeight.call(this);
        }
    }
}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    window.onresize = setContainerHeight.bind(this);
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
}


/**
 * setContainerHeight()
 * set container height
 */
function setContainerHeight() {
    if (window.innerWidth > 550) {
        this._internalVars.portfolioLeft.removeAttribute('style');
        this._internalVars.portfolioRight.removeAttribute('style');

        let arrHeight = [];

        let leftHeight = this._internalVars.portfolioLeft.clientHeight;
        let rightHeight = this._internalVars.portfolioRight.clientHeight;

        arrHeight.push(leftHeight, rightHeight);

        let containerHeight = Math.max(...arrHeight);

        this._internalVars.portfolioLeft.style.height = containerHeight + 'px';
        this._internalVars.portfolioRight.style.height = containerHeight + 'px';
    } else {
        this._internalVars.portfolioLeft.removeAttribute('style');
        this._internalVars.portfolioRight.removeAttribute('style');
    }
}

/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.portfolioLeftClass = 'left-portfolio-container';
    this._internalVars.portfolioRightClass = 'right-portfolio-container';
    this._internalVars.portfolioObject = this._internalVars.node;
    this._internalVars.portfolioLeft = this._internalVars.portfolioObject.querySelector('.' + this._internalVars.portfolioLeftClass);
    this._internalVars.portfolioRight = this._internalVars.portfolioObject.querySelector('.' + this._internalVars.portfolioRightClass);
}

export default Portfolio;
