import appState from '../appState';
import * as utils from '../utils';
import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK TimelineFilter : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "onFilterSubmit",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK TimelineFilter : onFilterSubmit must be a function"]
    },
    {
        setting                 :   "onClearFilter",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK TimelineFilter : onClearFilter must be a function"]
    },
    {
        setting                 :   "setInitialFilters",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["object"],
        errorMessage            :   ["GDK TimelineFilter : setInitialFilters must be an object"]
    }
];

class TimelineFilter{
    /**
     * Refer to the design kit section of this component for JS examples and setting details.
     * @param {string, Object} content
     *  A reference to the html timeline filter node
     *
     * @param {function} [onFilterSubmit]
     * Callback function fired once a 'filter-submit' is clicked
     *
     * @param {function} [onClearFilter]
     * Callback function fired once 'Clear Filters' is clicked
     *
     * @param {object} [setDefaultFilters]
     * Accepts an array of objects containing checkbox IDs and an indicator to select or deselect them, selects or deselects them, and updates the count bubble
     *
     */
    constructor(options) {

        this._internalVars = {
            node: null,//used for content item
            dropdownTrigger: null,
            checkbox: null,
            dropdown: null,
            mobileOpen: null,
            //mobileClose: null,
            mobileBar: null,
            mobileText: null,
            clear: null,
            search: null,
            submit: null,
            dropdownContainer: null
        };

        //options with defaults set
        this._defaults = {};

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);

            if(appState.mode == "desktop")
            Array.prototype.forEach.call(this._internalVars.dropdownTrigger, (el, i)=>{
                el.setAttribute('aria-expanded', 'false');
            });

            if(this._options.setInitialFilters) {
                this.setDefaultFilters.call(this, this._options.setInitialFilters);
            }
        }
    }

    //Public Methods

    /**
     * setDefaultFilters(obj)
     * @param {object} [obj]
     * Accepts an array of objects containing checkbox IDs and an indicator to select or deselect them, selects or deselects them, and updates the count bubble
     */
    setDefaultFilters(obj) {
        Array.prototype.forEach.call(obj, (el)=> {
            if(el.id && el.action) {
                let checkbox = document.querySelector('#' + el.id);
                if(checkbox) {
                    if(el.action === 'select') {
                        checkbox.checked = true;
                        updateAllNotificationBubbles.call(this, checkbox);
                    } else if (el.action === 'deselect') {
                        checkbox.checked = false;
                        updateAllNotificationBubbles.call(this, checkbox);
                    }
                }
            }
        });
    }

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

// Private Methods

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.dropdownTrigger = this._internalVars.node.querySelectorAll(".filter-dropdown-trigger");
    this._internalVars.checkbox = this._internalVars.node.querySelectorAll(".filter-checkbox");
    this._internalVars.dropdown = this._internalVars.node.querySelectorAll(".filter-dropdown");
    this._internalVars.clear = this._internalVars.node.querySelector(".filter-clear");
    this._internalVars.search = this._internalVars.node.querySelector(".filter-search");
    this._internalVars.submit = this._internalVars.node.querySelector(".filter-submit");
    this._internalVars.dropdownContainer = this._internalVars.node.querySelectorAll(".filter-container");

    this._internalVars.mobileOpen = this._internalVars.node.querySelector(".filter-mobile-open");
    //this._internalVars.mobileClose = this._internalVars.node.querySelector(".filter-mobile-close");
    this._internalVars.mobileBar = this._internalVars.node.querySelector(".filter-mobile-bar");
    this._internalVars.mobileText = this._internalVars.node.querySelector(".filter-mobile-bar-text");

    this._internalVars.outsideHandler = outsideFieldClickHandler.bind(this);
    this._internalVars.dropdownHandler = dropdownClickHandler.bind(this);
    this._internalVars.checkTouchHandler = checkForTouch.bind(this);
    this._internalVars.checkboxHandler = checkboxChangeHandler.bind(this);
    this._internalVars.clearFilterHandler = clearFilter.bind(this);
    this._internalVars.submitFilterHandler = submitFilter.bind(this);
    this._internalVars.openDrawerHandler = openDrawer.bind(this);
    this._internalVars.searchFocusHandler = searchFocus.bind(this);
}


/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {

    const eventName = UserAgentService._clickEventName();

    //set click events
    Array.prototype.forEach.call(this._internalVars.dropdownTrigger, (el, i)=>{
        el.addEventListener(eventName,this._internalVars.dropdownHandler);
        el.addEventListener("pointerdown", this._internalVars.checkTouchHandler );
    });
    Array.prototype.forEach.call(this._internalVars.dropdownContainer, (el, i)=>{
        el.addEventListener("mouseleave",this._internalVars.outsideHandler);
    });
    Array.prototype.forEach.call(this._internalVars.checkbox, (el, i)=>{
        el.addEventListener('change', this._internalVars.checkboxHandler);
    });

    this._internalVars.clear.addEventListener(eventName, this._internalVars.clearFilterHandler);
    if(this._internalVars.search){
        this._internalVars.search.addEventListener('focus', this._internalVars.searchFocusHandler );
    }
    this._internalVars.submit.addEventListener(eventName, this._internalVars.submitFilterHandler );

    // mobile
    this._internalVars.mobileOpen.addEventListener(eventName, this._internalVars.openDrawerHandler);
    //this._internalVars.mobileClose.addEventListener(eventName, closeDrawer.bind(this) );
    
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {

    const eventName = UserAgentService._clickEventName();

    //remove click events
    Array.prototype.forEach.call(this._internalVars.dropdownTrigger, (el, i)=>{
        el.removeEventListener(eventName,this._internalVars.dropdownHandler);
        el.removeEventListener("pointerdown", this._internalVars.checkTouchHandler);
    });
    Array.prototype.forEach.call(this._internalVars.dropdownContainer, (el, i)=>{
        el.removeEventListener("mouseleave",this._internalVars.outsideHandler);
    });
    Array.prototype.forEach.call(this._internalVars.checkbox, (el, i)=>{
        el.removeEventListener('change',this._internalVars.checkboxHandler);
    });

    this._internalVars.clear.removeEventListener(eventName, this._internalVars.clearFilterHandler );
    if(this._internalVars.search){
        this._internalVars.search.removeEventListener('focus', this._internalVars.searchFocusHandler );
    }
    this._internalVars.submit.removeEventListener(eventName, this._internalVars.submitFilterHandler );

    // mobile
    this._internalVars.mobileOpen.removeEventListener(eventName, this._internalVars.openDrawerHandler);
    //this._internalVars.mobileClose.removeEventListener(eventName, closeDrawer.bind(this) );
}


function checkForTouch(el){
    if(el.pointerType=='touch'){
        Array.prototype.forEach.call(this._internalVars.dropdownContainer, (el, i)=>{
            el.removeEventListener("mouseleave",this._internalVars.outsideHandler);
        });
    }else{
        Array.prototype.forEach.call(this._internalVars.dropdownContainer, (el, i)=>{
            el.addEventListener("mouseleave",this._internalVars.outsideHandler);
        });
    }
}

/**
 * openDrawer()
 * opens the mobile filter drawer
 */
function openDrawer(el){
    if(utils.hasClass(this._internalVars.node, "-drawer-open")){
        closeDrawer.call(this);
    }else{
        this._internalVars.node.classList.add("-drawer-open");
    }
    
    
}

/**
 * closeDrawer()
 * closes the mobile filter drawer
 */
function closeDrawer(el){
    this._internalVars.node.classList.remove("-drawer-open");
}

/**
 * submitFilter()
 * submits Filter form
 */
function submitFilter(el){
    
    let data = serializeForm(this._internalVars.node);

    // if mobile, close the drawer and update the filter text
    if(appState.mode === "mobile"){
        let fields = Object.keys(data).length;
    
        if ((data.keywords !== '') || (fields > 1)) {
            this._internalVars.mobileText.innerHTML = 'Showing filtered activities & policies';
        } else {
            this._internalVars.mobileText.innerHTML = 'Showing all activities & policies';
        }
        this._internalVars.node.classList.remove("-drawer-open");
    }

    // fire onFilterSubmit Callback if there is one
    setTimeout( ()=> {
        if(this._options.onFilterSubmit)
            this._options.onFilterSubmit(data);
    }, 10);

    return false;
}

/**
 * serializeForm()
 * serialize form into object
 */
function serializeForm(form){
    if (!form || form.nodeName !== "FORM") {
        return;
    }
    var i, j,
        obj = {};
    for (i = form.elements.length - 1; i >= 0; i = i - 1) {
        if (form.elements[i].name === "") {
            continue;
        }
        switch (form.elements[i].nodeName) {
        case 'INPUT':
            switch (form.elements[i].type) {
            case 'text':
            case 'hidden':
            case 'password':
            case 'button':
            case 'reset':
            case 'submit':
                obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
                break;
            case 'checkbox':
            case 'radio':
                if (form.elements[i].checked) {
                    obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
                }
                break;
            case 'file':
                break;
            }
            break;
        case 'TEXTAREA':
            obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
            break;
        case 'SELECT':
            switch (form.elements[i].type) {
            case 'select-one':
                obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
                break;
            case 'select-multiple':
                for (j = form.elements[i].options.length - 1; j >= 0; j = j - 1) {
                    if (form.elements[i].options[j].selected) {
                        obj[form.elements[i].name] = encodeURIComponent(form.elements[i].options[j].value);
                    }
                }
                break;
            }
            break;
        case 'BUTTON':
            switch (form.elements[i].type) {
            case 'reset':
            case 'submit':
            case 'button':
                obj[form.elements[i].name] = encodeURIComponent(form.elements[i].value);
                break;
            }
            break;
        }
    }
    return obj;

}

/**
 * clearFilter()
 * clears the timeline filters and unchecks all checkboxes
 */
function clearFilter(e){
    e.preventDefault();
    // unchecks all checkboxes
    Array.prototype.forEach.call(this._internalVars.checkbox, (el, i)=>{

        el.checked = false;

    });

    // update notification bubble to 0
    Array.prototype.forEach.call(this._internalVars.dropdownTrigger, (el, i)=>{

        updateNotificationBubble(el, 0);

    });

    // clears search field
    if(this._internalVars.search){
        this._internalVars.search.value = '';
    }


    // fire onClearFilter Callback if there is one
    setTimeout( ()=> {
        if(this._options.onClearFilter)
            this._options.onClearFilter();
    }, 10);

}

/**
 * checkboxChangeHandler()
 * updates the notification of how many checkboxes are selected per dropdown
 */
function checkboxChangeHandler(el){
    
    let currentNode = el.currentTarget;

    let dropdownParent = findAncestor(currentNode, 'filter-dropdown');
    let dropdownTrigger = dropdownParent.parentElement;
    
    let total = dropdownParent.querySelectorAll('input[type="checkbox"]:checked').length;

    updateNotificationBubble(dropdownTrigger, total);

}

/**
 * findAncestor()
 * Finds nearest ancestor with specified class
 */
function findAncestor (el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
}

/**
 * updateNotificationBubble()
 * updates the notification of how many checkboxes are selected per dropdown
 */
function updateNotificationBubble(el, value){
    
    let bubble = el.getElementsByClassName('dropdown-notify')[0];
    bubble.innerHTML = value;

    if (value > 0) {
        bubble.classList.add("-visible");
    } else {
        bubble.classList.remove("-visible");
    }
}

function updateAllNotificationBubbles(el) {

    let dropdownParent = findAncestor(el, 'filter-dropdown');
    let dropdownTrigger = dropdownParent.parentElement;

    let total = dropdownParent.querySelectorAll('input[type="checkbox"]:checked').length;

    updateNotificationBubble(dropdownTrigger, total);
}

/**
 * dropdownClickHandler()
 * Opens tab content for clicked tab and hides the rest
 */
function dropdownClickHandler(el){
    
    let currentNode = el.currentTarget;
    currentNode.focus();
    // add -active class 
    Array.prototype.map.call(this._internalVars.dropdownTrigger, (e, rank)=> {
        if(e===currentNode&&e===document.activeElement){
            // if already has active class, remove it
            if (utils.hasClass(e,'filter-dropdown-trigger--active')) {
                e.classList.remove("filter-dropdown-trigger--active");
                if(appState.mode == 'desktop')
                e.setAttribute('aria-expanded', 'false');

            } else {
               e.classList.add("filter-dropdown-trigger--active");
                if(appState.mode == 'desktop'){
                    e.setAttribute('aria-expanded', 'true');
                }

            }
            
        } else {
            e.classList.remove("filter-dropdown-trigger--active");
            if(appState.mode == 'desktop')
            e.setAttribute('aria-expanded', 'false');
        }
    });

}

/**
 * dropdownClickHandler()
 * Opens tab content for clicked tab and hides the rest
 */
function outsideFieldClickHandler(el){

    let currentNode = el.currentTarget;
    // add -active class
    Array.prototype.map.call(this._internalVars.dropdownTrigger, (e, rank)=> {
        if(e===currentNode){
            // if already has active class, remove it
            if (utils.hasClass(e,'filter-dropdown-trigger--active')) {
                e.classList.remove("filter-dropdown-trigger--active");
                if(appState.mode == 'desktop')
                e.setAttribute('aria-expanded', 'false');
            }

        } else {
            e.classList.remove("filter-dropdown-trigger--active");
            if(appState.mode == 'desktop')
            e.setAttribute('aria-expanded', 'false');
        }
    });

}

/**
 * searchFocus()
 * Closes all dropdowns on search focus
 */
function searchFocus(el){
    // add -active class 
    Array.prototype.map.call(this._internalVars.dropdownTrigger, (e, rank)=> {
        e.classList.remove("filter-dropdown-trigger--active");
        if(appState.mode == 'desktop')
        e.setAttribute('aria-expanded', 'false');
    });

}




export default TimelineFilter;
