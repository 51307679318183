import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK VINCountdown : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "countdownMax",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["number"],
        errorMessage            :   ["GDK VINCountdown : countdownMax must be a number"]
    },
    {
        setting                 :   "onCharacterType",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK VINCountdown : Content must be defined and set to a function"]
    }
];

class VINCountdown{
    /**
     * Refer to the design kit section of this component for JS examples and setting details.
     * @param {string, Object} content
     * A reference to the html textarea container node
     *
     * @param {number} [countdownMax=1000]
     * The number of characters allowed in the textarea
     *
     */
    constructor(options) {

        this._internalVars = {
            node: null,//used for content item
            geicoVINCountdownContainerClass : "vin-countdown-container",
            geicoVINCountdownClass : "vin-area-countdown",
            geicoTextAreaGoodClass : "vin-countdown--good",
            geicoTextAreaCloseClass : "vin-countdown--close",
            geicoTextAreaOverClass : "vin-countdown--over",
            geicoVINCountdownInfoClass : "vin-countdown-info",
            geicoVINCountdownInfoTextClass : "vin-countdown-info-text",
            geicoVINCountdownInfoCounterClass : "vin-countdown-info-counter",
            geicoTextGoodCopy : "Characters Remaining:  ",
            geicoTextCloseCopy : "Characters Remaining:  ",
            geicoTextOverCopy : "Over by:  ",
            closeNum : 10,
            geicoVINCountdownData : null,
            count : null
        };

        //options with defaults set
        this._defaults = {
            countdownMax: 17
        };

        // Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
        }
    }

    //Public Methods

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

    /**
     * setVINLength(newLength)
     * @param newLength
     * sets the length of the VIN and updates the corresponding countdown number
     */
    setVINLength(newLength) {
        if(typeof newLength === "number") {
            updateVINLength.call(this, newLength);
        }
    }

}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    this._internalVars.textArea.addEventListener("change", this._internalVars.handler);
    this._internalVars.textArea.addEventListener("keyup", this._internalVars.handler);
    this._internalVars.textArea.addEventListener("keydown", this._internalVars.handler);
    this._internalVars.textArea.addEventListener("blur", this._internalVars.handler);
    this._internalVars.textArea.addEventListener("paste", pasteHandler);
    this._internalVars.textArea.addEventListener("keypress", this._internalVars.checkInputHandler);
    this._internalVars.textArea.addEventListener("input", this._internalVars.replaceCharactersHandler);
}

/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    this._internalVars.textArea.removeEventListener("change", this._internalVars.handler);
    this._internalVars.textArea.removeEventListener("keyup", this._internalVars.handler);
    this._internalVars.textArea.removeEventListener("keydown", this._internalVars.handler);
    this._internalVars.textArea.removeEventListener("blur", this._internalVars.handler);
    this._internalVars.textArea.removeEventListener("paste", pasteHandler);
    this._internalVars.textArea.removeEventListener("keypress", this._internalVars.checkInputHandler);
    this._internalVars.textArea.removeEventListener("input", this._internalVars.replaceCharactersHandler);
}

function checkInputValue(evt) {
    if(this._options.onCharacterType){
        this._options.onCharacterType();
    }
}

function updateVINLength(newLength) {
    this._options.countdownMax = newLength;
    this._internalVars.countdownInfoCounter.innerHTML = newLength - this._internalVars.textArea.value.length;
    this._internalVars.textArea.setAttribute("maxlength", newLength);
}

/**
 * pasteHandler()
 * triggers a keydown event on paste
 */
function pasteHandler(evt){
    var _this = evt.currentTarget;
    setTimeout(function() {
        let event = document.createEvent('HTMLEvents');
        event.initEvent('keydown', true, false);
        _this.dispatchEvent(event);
    }, 10);
}

/**
 * updateCountdown()
 * updates the countdown text and counter
 */
function updateCountdown(evt){
    var _this = evt.currentTarget;
    var container = _this.parentNode;
    var text = _this.value;
    var remaining = this._options.countdownMax - text.length;

    if(remaining < 0){

        this._internalVars.countdownInfoText.textContent = this._internalVars.geicoTextOverCopy;
        this._internalVars.countdownInfoTextCurrent = this._internalVars.geicoTextOverCopy;
        container.classList.remove(this._internalVars.geicoTextAreaCloseClass);
        container.classList.add(this._internalVars.geicoTextAreaOverClass);

    }else if(remaining < 10){

        this._internalVars.countdownInfoText.textContent = this._internalVars.geicoTextCloseCopy;
        this._internalVars.countdownInfoTextCurrent = this._internalVars.geicoTextCloseCopy;
        container.classList.remove(this._internalVars.geicoTextAreaGoodClass);
        container.classList.remove(this._internalVars.geicoTextAreaOverClass);
        container.classList.add(this._internalVars.geicoTextAreaCloseClass);

    }else if( this._internalVars.countdownInfoTextCurrent === this._internalVars.geicoTextCloseCopy || this._internalVars.countdownInfoTextCurrent === this._internalVars.geicoTextOverCopy ){

        this._internalVars.countdownInfoText.textContent = this._internalVars.geicoTextGoodCopy;
        this._internalVars.countdownInfoTextCurrent = this._internalVars.geicoTextGoodCopy;
        container.classList.remove(this._internalVars.geicoTextAreaCloseClass);
        container.classList.remove(this._internalVars.geicoTextAreaOverClass);
        container.classList.add(this._internalVars.geicoTextAreaGoodClass);

    }


    updateRemaining.call(this,remaining, text.length, this._options.countdownMax, this._internalVars.countdownInfoCounter);

}

/**
 * updateRemaining()
 * updates the remaining characters left
 */
function updateRemaining(remaining, tl, countdown, countdownInfoCounter) {
    var val = remaining;

    if(remaining === 0)
        val = 0;
    else if(remaining < 0)
        val = tl - countdown;

    countdownInfoCounter.textContent = val;
}

/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    //node to hold status text
    let infoText = document.createElement("SPAN");
    infoText.classList.add(this._internalVars.geicoVINCountdownInfoTextClass);

    //node to hold count of characters left
    let infoCounter = document.createElement("SPAN");
    infoCounter.classList.add(this._internalVars.geicoVINCountdownInfoCounterClass);

    //node to hold count of characters left
    let countdownInfo = document.createElement("DIV");
    countdownInfo.classList.add(this._internalVars.geicoVINCountdownInfoClass);
    countdownInfo.setAttribute('aria-live', 'polite');
    countdownInfo.setAttribute('aria-atomic', 'true');

    //add the text and counter to the container
    countdownInfo.appendChild(infoText);
    countdownInfo.appendChild(infoCounter);

    //append the container to the node
    this._internalVars.node.appendChild(countdownInfo);
    //set initial class for container
    this._internalVars.node.classList.add(this._internalVars.geicoTextAreaGoodClass);

    //get access to the textarea, counter and counter info for future reference
    this._internalVars.textArea = this._internalVars.node.querySelector("." + this._internalVars.geicoVINCountdownClass);
    this._internalVars.countdownInfoText = this._internalVars.node.querySelector("." + this._internalVars.geicoVINCountdownInfoTextClass);
    this._internalVars.countdownInfoCounter = this._internalVars.node.querySelector("." + this._internalVars.geicoVINCountdownInfoCounterClass);
    this._internalVars.countdownInfoTextCurrent = this._internalVars.geicoTextGoodCopy;


    this._internalVars.countdownInfoCounter.textContent = this._options.countdownMax;
    this._internalVars.countdownInfoText.textContent = this._internalVars.countdownInfoTextCurrent;

    this._internalVars.handler = updateCountdown.bind(this);
    this._internalVars.replaceCharactersHandler = replaceCharacters.bind(this);
    this._internalVars.checkInputHandler = checkInputValue.bind(this);



}

function replaceCharacters() {
    let entryArray = this._internalVars.textArea.value.split('');
    let convertedArray = [];

    Array.prototype.forEach.call(entryArray, (el)=> {
            if (el === 'i' || el === 'I') {
                convertedArray.push('1');
            } else if (el === 'o' || el === 'O') {
                convertedArray.push('0');
            } else if (el === 'q' || el === 'Q') {
                convertedArray.push('0');
            } else {
                convertedArray.push(el);
            }
    });

    let entry = convertedArray.join('');
    this._internalVars.textArea.value = entry;

}

export default VINCountdown;
