import * as utils from '../utils';
import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK ViewMoreLess : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting: "viewMoreLinkClicked",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK ViewMoreLess : viewMoreLinkClicked must be defined and set function"]
    },
    {
        setting: "collapsedTextValue",
        isRequired: false,
        validate: "type",
        possibleValues: ["string"],
        errorMessage: ["GDK ViewMoreLess : collapsedTextValue must be defined as a text string"]
    },
    {
        setting: "expandedTextValue",
        isRequired: false,
        validate: "type",
        possibleValues: ["string"],
        errorMessage: ["GDK ViewMoreLess : expandedTextValue must be defined as a text string"]
    }
];

/**
 * View More/Less Class
 */
class ViewMoreLess {
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the component node to toggle
     *
     * @param {function} [viewMoreLinkClicked]
     * A callback function that gets fired when the View link is clicked
     *
     * @param {string} [collapsedTextValue="View More"]
     *  A string representing the link text when collapsed
     *
     * @param {string} [expandedTextValue="View Less"]
     * A string representing the link text when expanded
     *
     */
    constructor(options) {

        console.log('ViewMoreLess initialized');

        this._internalVars = {
            node: null //used for current node
        };

        //options with defaults set
        this._defaults = {
            collapsedTextValue : 'View More',
            expandedTextValue : 'View Less'
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            init.call(this);
            setEvents.call(this);
        }
    }

    //Public Methods

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    this._internalVars.vmlLinkObject.addEventListener("click", this._internalVars.handler);
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    this._internalVars.vmlLinkObject.removeEventListener("click",this._internalVars.handler);
}

function init() {
    this._internalVars.vmlTitleObject.innerHTML = this._internalVars.collapsedTextValue;
}


/**
 * toggleView()
 * instantiate View More/Less
 */
function toggleView(e) {
    e.preventDefault();
    this._internalVars.vmlIconObject.classList.toggle('view-less-link-icon');
    this._internalVars.vmlObject.querySelector('.' + this._internalVars.vmlContentClass).classList.toggle('expanded');

    if( this._internalVars.vmlContentObject.classList.contains('expanded') ) {
       $(this._internalVars.vmlContentObject).slideDown();
    } else {
        $(this._internalVars.vmlContentObject).slideUp();
    }

     if (utils.hasClass(this._internalVars.vmlIconObject,'view-less-link-icon')) {
        this._internalVars.vmlTitleObject.innerHTML = this._internalVars.expandedTextValue;
         this._internalVars.vmlLinkObject.setAttribute('aria-expanded', 'true');
         this._internalVars.vmlContentObject.setAttribute('aria-hidden', 'false');
         this._internalVars.vmlState = true;
     }else{
        this._internalVars.vmlTitleObject.innerHTML = this._internalVars.collapsedTextValue;
         this._internalVars.vmlLinkObject.setAttribute('aria-expanded', 'false');
         this._internalVars.vmlContentObject.setAttribute('aria-hidden', 'true');
         this._internalVars.vmlState = false;
     }

    if(this._options.viewMoreLinkClicked) {
        this._options.viewMoreLinkClicked(this._internalVars.vmlState);
    }
}

/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }
    this._internalVars.collapsedTextValue = this._options.collapsedTextValue;
    this._internalVars.expandedTextValue = this._options.expandedTextValue;

    this._internalVars.vmlLinkClass = 'view-more-less-link';
    this._internalVars.vmlLinkTitle = 'view-more-link-title';
    this._internalVars.vmlIconClass = 'view-more-link-icon';
    this._internalVars.vmlContentClass = 'view-more-less-content';
    this._internalVars.vmlObject = this._internalVars.node;
    this._internalVars.vmlLinkObject = this._internalVars.node.querySelector('a.' + this._internalVars.vmlLinkClass);
    this._internalVars.vmlTitleObject = this._internalVars.node.querySelector('span.' + this._internalVars.vmlLinkTitle);
    this._internalVars.vmlIconObject = this._internalVars.node.querySelector('span.' + this._internalVars.vmlIconClass);
    this._internalVars.vmlContentObject = this._internalVars.node.querySelector('div.' + this._internalVars.vmlContentClass);
    this._internalVars.vmlState = false;

    if(this._internalVars.vmlContentObject.classList.contains('expanded')){
        this._internalVars.vmlLinkObject.setAttribute('aria-expanded', 'true');
        this._internalVars.vmlContentObject.setAttribute('aria-hidden', 'false');
        this._internalVars.vmlState = true;
    }else{
        this._internalVars.vmlLinkObject.setAttribute('aria-expanded', 'false');
        this._internalVars.vmlContentObject.setAttribute('aria-hidden', 'true');
        this._internalVars.vmlState = false;
    }

    this._internalVars.handler = toggleView.bind(this);
}

export default ViewMoreLess;