import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Tooltip : Content must be defined and set to a DOM selector or Node"]
    },
     {
        setting                 :   "toolTipText",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["string"],
        errorMessage            :   ["GDK Tooltip : toolTipText must be set to a string"]
    },
    {
        setting: "toolTipOpen",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK Tooltip : toolTipOpen must be defined and set function"]
    },
    {
        setting: "toolTipClose",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK Tooltip : toolTipClose must be defined and set function"]
    }
];

class Tooltip{
    /**
     * Refer to the design kit section of this component for JS examples and setting details.
     * @param {string, Object} content
     *  A reference to the html Tooltip trigger's node
     *
     * @param {string} [toolTipText]
     * Callback function fired once a 'filter-submit' is clicked
     *
     * @param {function} [toolTipOpen]
     * A callback function triggered when a tooltip is displayed
     *
     * @param {function} [toolTipClose]
     * A callback function triggered when a tooltip is closed manually
     *
     */
    constructor(options) {

        this._internalVars = {
            node: null,//used for content item
            toolTip : null,

            triggerOffset : null,
    		timeout : null,
    		delay : 300, // delay 0.3 seconds
    		breakpoint : 768,
            margin : 12,
    		wrapperElementId : "wrapper",
    		toolTiplElementClass : "tooltip",
    		toolTipTriggerElementClass : "tooltip-trigger",
    		toolTipCloseElementClass : "icon-close-20",
    		toolTipActiveClass : "tooltip--active",
            wrapper : null,
    		closeBtn : null
        };

        //options with defaults set
        this._defaults = {
            toolTipText: null
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
        }
    }

    //Public Methods

    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
		this._internalVars.node.parentNode.removeChild(this._internalVars.node);
        this._internalVars.toolTip.parentNode.removeChild(this._internalVars.toolTip);
		//a little garbage collection
		for (let variableKey in this){
			if (this.hasOwnProperty(variableKey)){
				delete this[variableKey];
			}
		}
    }

}

// Private Methods
/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    if(this._options.toolTipText === null) {
        this._internalVars.toolTip = document.querySelector("#" + this._internalVars.node.getAttribute('data-tooltip-view'));
        this._internalVars.node.parentNode.insertBefore(this._internalVars.toolTip, this._internalVars.node.nextSibling);
    }else{
        buildNewTooltip.call(this);
    }

    this._internalVars.wrapper = document.querySelector("#" + this._internalVars.wrapperElementId);
    this._internalVars.closeBtn = this._internalVars.toolTip.querySelector("." + this._internalVars.toolTipCloseElementClass);
    this._internalVars.toolTipClass = 'tooltip';
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    const eventName = UserAgentService._clickEventName();
    if ("ontouchstart" in document.documentElement)
    {
        this._internalVars.node.addEventListener(eventName, showToolTip.bind(this));
        this._internalVars.node.addEventListener("mouseover", showToolTip.bind(this));
        this._internalVars.closeBtn.addEventListener(eventName, closeBtnClickHandler.bind(this));
    } else {
        this._internalVars.node.addEventListener("mouseenter", showToolTip.bind(this));
        this._internalVars.node.addEventListener(eventName, function(e){e.preventDefault();});
        this._internalVars.node.addEventListener("focus", showToolTip.bind(this));
        this._internalVars.node.addEventListener("keydown", shiftTab.bind(this));
        this._internalVars.closeBtn.addEventListener(eventName, closeBtnClickHandler.bind(this));
        this._internalVars.closeBtn.addEventListener("blur", closeBtnClickHandler.bind(this));
    }
}

function shiftTab(e){
    if(e.keyCode == '9' && e.shiftKey){
        hideToolTip.call(this);
    }
}

/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    const eventName = UserAgentService._clickEventName();
    if ("ontouchstart" in document.documentElement)
    {
        this._internalVars.node.removeEventListener(eventName, showToolTip.bind(this));
        this._internalVars.node.removeEventListener("mouseover", showToolTip.bind(this));
        this._internalVars.closeBtn.removeEventListener(eventName, closeBtnClickHandler.bind(this));
    } else {
        this._internalVars.node.removeEventListener("mouseenter", showToolTip.bind(this));
        this._internalVars.closeBtn.removeEventListener(eventName, closeBtnClickHandler.bind(this));
        this._internalVars.node.removeEventListener(eventName, function(e){e.preventDefault();});
        this._internalVars.node.removeEventListener("focus", showToolTip.bind(this));
        this._internalVars.node.removeEventListener("keydown", shiftTab.bind(this));
        this._internalVars.closeBtn.removeEventListener("blur", closeBtnClickHandler.bind(this));
    }
}

/**
 * closeBtnClickHandler()
 * catches click on close button
 */
function closeBtnClickHandler(e){
    console.log("closeBtnClickHandler");
    e.preventDefault();
    hideToolTip.call(this);

    if(this._options.toolTipClose) {
        let tooltip = e.currentTarget.parentNode;
        this._options.toolTipClose(tooltip);
    }
}

/**
 * buildNewTooltip()
 * use to dynamically create the tooltip
 */
function buildNewTooltip() {
    let toolTipId = this._internalVars.node.getAttribute('data-tooltip-view');

    let dynamicToolTip = document.createElement('div');
    dynamicToolTip.setAttribute('id', toolTipId);
    dynamicToolTip.classList.add("tooltip");
    dynamicToolTip.innerHTML= '<div tabindex="0">' + this._options.toolTipText + '</div><button class="icon-close-20" aria-label="Close tooltip"></button>';

    this._internalVars.node.parentNode.insertBefore(dynamicToolTip, this._internalVars.node.nextSibling);
    this._internalVars.toolTip = document.querySelector("#" + toolTipId);
}

/**
 * showToolTip()
 * show tooltip on hover
 */
function showToolTip(e){
    e.preventDefault();

    // prep tooltip

    let toolTipId = this._internalVars.node.getAttribute('data-tooltip-view');
    let toolTip = document.querySelector("#"+toolTipId);
    this._internalVars.node.parentNode.insertBefore(toolTip, this._internalVars.node.nextSibling);
    this._internalVars.toolTip = toolTip;

    try {
        hideToolTip.call(this);
    } catch(err) {
        // clear existing tt
    }

    clearTimeout(this._internalVars.timeout);
    let self = e.currentTarget;

    self.classList.add(this._internalVars.toolTipActiveClass);

    this._internalVars.activationArea = self;

    self.addEventListener("mouseleave", settimeout.bind(this));

    this._internalVars.toolTip.addEventListener("mouseleave", settimeout.bind(this));

    this._internalVars.toolTip.addEventListener("mouseover", cleartimeout.bind(this));

    this._internalVars.triggerOffset = self.getBoundingClientRect();

    setTimeout(()=>{
        if(self.classList.contains("tooltip--active")) {
            this._internalVars.toolTip.style.display = "block";
            //position the tooltip after setting to block
            positionToolTip.call(this);
            //set opacity and let css transition fade it in
            this._internalVars.toolTip.style.opacity = 1;
        }
    },this._internalVars.delay);

    if(this._options.toolTipOpen) {
        let triggerElement = e.currentTarget.parentNode,
            tooltip = triggerElement.querySelector('.' + this._internalVars.toolTipClass);
        this._options.toolTipOpen(tooltip);
    }
}

/**
 * cleartimeout()
 * clears set timeout
 */
function cleartimeout(e){
    clearTimeout(this._internalVars.timeout);
}

/**
 * settimeout()
 * sets a timeout to hide the tooltip
 */
function settimeout(e){
    if(document.activeElement != e.currentTarget){
        clearTimeout(this._internalVars.timeout);
        this._internalVars.node.classList.remove(this._internalVars.toolTipActiveClass);
        this._internalVars.timeout = setTimeout(hideToolTip.bind(this), 200);
        // this._internalVars.timeout = hideToolTip.call(this);
    }
}

/**
 * hideToolTip()
 * hides the tooltip
 */
function hideToolTip(){
    this._internalVars.toolTip.style.opacity = 0;

    setTimeout(()=>{
        this._internalVars.toolTip.style.display = "none";
    },this._internalVars.delay);

}

/**
 * positionToolTip()
 * sets the position for the tooltip depending on trigger position
 */
function positionToolTip(){
    this._internalVars.toolTip.classList.remove("arrow-right");
    this._internalVars.toolTip.classList.remove("arrow-bottom");

    let winWidth = document.body.clientWidth;
    let winHeight = window.innerHeight || document.documentElement.clientHeight;
    let containerOffset = this._internalVars.wrapper.getBoundingClientRect();
    let containerOffsetLeft = containerOffset.left;
    let containerWidth = this._internalVars.wrapper.offsetWidth;
    let tooltipWidth = this._internalVars.toolTip.offsetWidth;
    let tooltipHeight = this._internalVars.toolTip.offsetHeight;
    let xpos = null;
    let ypos = null;

    if( winWidth < this._internalVars.breakpoint){
        ypos = 25;
        if( this._internalVars.triggerOffset.left - containerOffsetLeft + tooltipWidth< containerWidth) {
            xpos = 0;
        }else{
            xpos = -(this._internalVars.triggerOffset.left - containerOffsetLeft + tooltipWidth - containerWidth + 10);
        }

    }else{
        xpos = 40;
        ypos = -15;
        if( this._internalVars.triggerOffset.left - containerOffsetLeft + tooltipWidth + 50 < containerWidth){
            xpos = 40;
            ypos = -15;
        }else{
            xpos = -(tooltipWidth + 5);
            this._internalVars.toolTip.classList.add("arrow-right");
        }
    }

    this._internalVars.toolTip.style.left = xpos + "px";
    this._internalVars.toolTip.style.top = ypos  + "px";

}



export default Tooltip;
