//Require static assets

/*
 Problems:
 1)      Requiring a specific ID – The code as-is requires a specific ID for the search field (code: let input = document.querySelector('#address-search-field');).  This requires that we only use 1 instance of this address autocomplete on the screen at one time and forces us to use specific IDs. I’ve complained about this pattern repeatedly over the last couple years and it still seems to be the go to solution for the design team implementations of things. (NOTE: it also requires specific IDs for each and every field of the address that directly match data coming back from google’s apis.)
 2)      The implementation doesn’t seem to keep in mind anything related to single-page application design (regardless of technology):
 a.       The implementation requires a global javascript function to be present at page load time.
 b.      It presumes to know what the IDs and types of all the input fields are and auto-populates them with no ability to notify client code when this has occurred.  This screws up any kind of data binding technology (angular, knockout, etc)
 3)      It does not seem to include Zip +4 (which it looks like the Google APIs don’t return)
 4)      It hard codes English text (code:  message.innerHTML = 'No Results Found';)
 5)      It watches the entire document body for changes to see if a DOM node is inserted. (code:  document.body.addEventListener('DOMNodeInserted', function (event)…).  That can’t possibly be necessary.

 Questions:
 1)      Is this supposed to be the new way all addresses are entered in the system, or just an optional component for teams to use? (did you talk to multiple teams to gather requirements or was this just implemented at the request of one team?)
 */
import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';


const validateSettings = [
  {
    setting: "content",
    isRequired: true,
    validate: "type",
    possibleValues: ["string", "object"],
    errorMessage: ["GDK AddressAutoComplete : Content must be defined and set to a DOM selector or Node"]
  }
];

const ieVersion = UserAgentService._detectIE();

let ValueSelected = false;
let selector;
let event;

if(ieVersion) {
  //set event for IE
  console.log('This is the Windows IE Setup: ' + ieVersion);
  event = document.createEvent('HTMLEvents');
  event.initEvent('gdk-address-updated', true, true);
} else {
  //set event for non IE
  event = new Event('gdk-address-updated');
}



class AddressAutoComplete {


  constructor(options) {

    this._internalVars = {
      node: null//used for content item
    };

    //options with defaults set
    this._defaults = {};

    // Create options by extending defaults with the passed in arugments
    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }

    //if the required options are valid set up the environment
    if (baseComponent.validateSettings(this._options, validateSettings)) {
      if (document.querySelector(this._options.content)) {
        selector = this._options.content;
        this._internalVars.contentType = baseComponent.getContentType(this);
        setLocalVars.call(this);
        setEvents.call(this);
        init.call(this);
      }
    }


  }

  //Public Methods

  // Bias the autocomplete object to the user's geographical location,
  // as supplied by the browser's 'navigator.geolocation' object.
  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let geolocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        let circle = new google.maps.Circle({
          center: geolocation,
          radius: position.coords.accuracy
        });
        this.autoComplete.setBounds(circle.getBounds());
      });
    }
  }

  getAddressObject() {
    let formattedAddress = {
      formatted_address: this._internalVars.placesObject.formatted_address,
      address_components: this._internalVars.placesObject.address_components,
      geolocation: this._internalVars.placesObject.geometry.location
    };
    return formattedAddress;
  }
}


/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
  //determine the type of content passed in
  if (this._internalVars.contentType === 'string') {
    this._internalVars.node = document.querySelector(this._options.content);
  } else if (this._internalVars.contentType === 'domNode') {
    this._internalVars.node = this._options.content;
  }
  this._internalVars.addressSearchField = this._internalVars.node.querySelector('.address-search-field');
  this._internalVars.searchClearBtn = this._internalVars.node.querySelector('.btn--search-clear');

  // Create the autocomplete object, restricting the search to geographical
  // location types.
  this.autoComplete = new google.maps.places.Autocomplete(
    /** @type {!HTMLInputElement} */(this._internalVars.addressSearchField), {types: ['geocode']});
  this._internalVars.componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };
  this._internalVars.placesObject = {};
}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
  this._internalVars.searchClearBtn.addEventListener('click', clearSearch.bind(this));
  this._internalVars.addressSearchField.addEventListener('input', toggleClearButton.bind(this));
}

function clearSearch() {
  this._internalVars.addressSearchField.value = '';
  toggleClearButton.call(this);
}

function toggleClearButton() {

  if(this._internalVars.addressSearchField.value !== '') {
    this._internalVars.searchClearBtn.classList.remove('hidden');
  } else {
    this._internalVars.searchClearBtn.classList.add('hidden');
  }

}

function init() {
  console.log('stop here');
  // Hide the search close button
  if(this._internalVars.addressSearchField.value === '' && !this._internalVars.searchClearBtn.classList.contains('hidden')) {
    this._internalVars.searchClearBtn.classList.add('hidden');
  }

  // When the user selects an address from the dropdown, populate the address
  // fields in the form.
  this.autoComplete.addListener('place_changed', fillInAddress.bind(this));

  let styleInitialized = false;

  if (document.querySelector(selector).querySelector('.address-search-field') !== null) {
    document.querySelector(selector).querySelector('.address-search-field').addEventListener('blur', function (event) {
      if (document.querySelector('.pac-container') && document.querySelector('.pac-container').children.length === 0 && event.target.value.length !== 0 && document.querySelector('.address_1').value.length === 0) {
        document.querySelector('.address_1').value = event.target.value;
        event.target.value = '';
      }
    });
  }

}


function fillInAddress() {

  console.log('new results :) ');

  ValueSelected = true;

  // Get the place details from the autocomplete object.
  let place = this.autoComplete.getPlace();
  this._internalVars.placesObject = place;

  document.dispatchEvent(event);

  // Reset All Fields
  let address1Element = this._internalVars.node.querySelector('.address_1');
  address1Element.value = '';
  address1Element.disabled = false;
  for (let component in this._internalVars.componentForm) {
    if (component !== 'street_number' && component !== 'route' && this._internalVars.node.querySelector('.' + component) !== null) {
      let element = this._internalVars.node.querySelector('.' + component);
      element.value = '';
      element.disabled = false;
    }
  }

  // Get each component of the address from the place details
  // and fill the corresponding field on the form.
  for (let i = 0; i < place.address_components.length; i++) {
    let addressType = place.address_components[i].types[0];
    if (this._internalVars.componentForm[addressType]) {
      let val = place.address_components[i][this._internalVars.componentForm[addressType]];
      if (addressType === 'street_number') {
        address1Element.value = val + ' ';
      } else if (addressType === 'route') {
        address1Element.value = address1Element.value + val;
      } else {
        if (this._internalVars.node.querySelector('.' + addressType)) {
          this._internalVars.node.querySelector('.' + addressType).value = val;
        }
      }
    }
  }

  clearSearch.call(this);

  // document.querySelector(selector).value = '';
}

export default AddressAutoComplete;
