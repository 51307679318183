import * as utils from '../utils';
import baseComponent from '../baseComponent';

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK Navigator : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting: "contentMobile",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK Navigator : Content Mobile must be defined and set to the DOM selector or Node of the mobile Navigator container HTML"]
    }
];

/**
 * View More/Less Class
 */
class Navigator {
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the component node
     *
     * @param {string|Object} contentMobile
     * A reference to the component mobile node to toggle
     *
     */
    constructor(options) {

        console.log('Navigator initialized');

        this._internalVars = {
            node: null //used for current node
        };

        //options with defaults set
        this._defaults = {

        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            init.call(this);
            assignEvents.call(this);
            setEvents.call(this);
        }
    }

    //Public Methods

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.nextElementSibling.classList.remove('navigator-enabled');
        if(document.querySelector('footer')){
            document.querySelector('footer').classList.remove('navigator-page-footer');
        }
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);
        this._internalVars.nodeMobile.parentNode.removeChild(this._internalVars.nodeMobile);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
}

// Private Methods
/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    window.addEventListener('resize', this._internalVars.assignEventsHandler);
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    window.removeEventListener('resize', this._internalVars.assignEventsHandler);

    this._internalVars.node.removeEventListener('mouseover', this._internalVars.openNavHandler);
    this._internalVars.node.removeEventListener('mouseout', this._internalVars.closeNavHandler);

    this._internalVars.navigatorMobileBarExpand.removeEventListener('click', this._internalVars.toggleDisplayHandler);
    this._internalVars.navigatorMobileCollapse.removeEventListener('click', this._internalVars.toggleDisplayHandler);

    this._internalVars.navigatorMobileList.lastElementChild.removeEventListener('mouseover', this._internalVars.addHoverColorHandler);

    this._internalVars.navigatorMobileList.lastElementChild.removeEventListener('mouseout', this._internalVars.removeHoverColorHandler);

    window.removeEventListener('scroll', this._internalVars.scrollHandler);
}

function assignEvents(){
    var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    if(width > 480 && width <= 767){
        closeNav.call(this);
        this._internalVars.node.addEventListener('mouseover', this._internalVars.openNavHandler);
        this._internalVars.node.addEventListener('mouseout', this._internalVars.closeNavHandler);
        window.removeEventListener('scroll', this._internalVars.scrollHandler);
        window.addEventListener('scroll', this._internalVars.scrollHandler);
    }
    else if(width <= 480){
        this._internalVars.node.removeEventListener('mouseover', this._internalVars.openNavHandler);
        this._internalVars.node.removeEventListener('mouseout', this._internalVars.closeNavHandler);

        this._internalVars.navigatorMobileBarExpand.addEventListener('click', this._internalVars.toggleDisplayHandler);
        this._internalVars.navigatorMobileCollapse.addEventListener('click', this._internalVars.toggleDisplayHandler);

        this._internalVars.navigatorMobileList.lastElementChild.addEventListener('mouseover', this._internalVars.addHoverColorHandler);

        this._internalVars.navigatorMobileList.lastElementChild.addEventListener('mouseout', this._internalVars.removeHoverColorHandler);

        window.removeEventListener('scroll', this._internalVars.scrollHandler);

    }
    else{
        openNav.call(this);
        this._internalVars.node.removeEventListener('mouseover', this._internalVars.openNavHandler);
        this._internalVars.node.removeEventListener('mouseout', this._internalVars.closeNavHandler);

        this._internalVars.navigatorMobileBarExpand.removeEventListener('click', this._internalVars.toggleDisplayHandler);
        this._internalVars.navigatorMobileCollapse.removeEventListener('click', this._internalVars.toggleDisplayHandler);

        this._internalVars.navigatorMobileList.lastElementChild.removeEventListener('mouseover', this._internalVars.addHoverColorHandler);

        this._internalVars.navigatorMobileList.lastElementChild.removeEventListener('mouseout', this._internalVars.removeHoverColorHandler);
        window.removeEventListener('scroll', this._internalVars.scrollHandler);
        window.addEventListener('scroll', this._internalVars.scrollHandler);
    }
}

function addHoverColor(){
    this._internalVars.navigatorMobileCollapse.querySelector('.geico-icon').classList.add('last-item-hover');
}

function removeHoverColor(){
    this._internalVars.navigatorMobileCollapse.querySelector('.geico-icon').classList.remove('last-item-hover');
}

function toggleFixedScroll(){
    if (document.querySelector('.navigator-enabled').getBoundingClientRect().top - 65 < 0) {
        if(this._internalVars.node.classList.contains('static-scroll')&& this._internalVars.navigatorOrderedList.classList.contains('static-scroll')){
            this._internalVars.node.classList.remove('static-scroll');
            this._internalVars.navigatorOrderedList.classList.remove('static-scroll');
        }
        this._internalVars.node.classList.add('fixed-scroll');
        this._internalVars.navigatorOrderedList.classList.add('fixed-scroll');
    }else{
        if(this._internalVars.node.classList.contains('fixed-scroll')&& this._internalVars.navigatorOrderedList.classList.contains('fixed-scroll')){
            this._internalVars.node.classList.remove('fixed-scroll');
            this._internalVars.navigatorOrderedList.classList.remove('fixed-scroll');
        }
        this._internalVars.node.classList.add('static-scroll');
        this._internalVars.navigatorOrderedList.classList.add('static-scroll');
    }
}

function init(){
    $('.navigator-menu').slideUp();
    this._internalVars.node.nextElementSibling.classList.add('navigator-enabled');

    buildMobileContent.call(this);

    if(this._internalVars.navigatorMobileList.querySelector('.active') == this._internalVars.navigatorMobileList.lastElementChild){
        this._internalVars.navigatorMobileCollapse.querySelector('.geico-icon').classList.add('color-change');
    }

    if(document.querySelector('footer')){
        document.querySelector('footer').classList.add('navigator-page-footer');
    }
}

function buildMobileContent(){
    var current = this._internalVars.navigatorActive.querySelector('a span:last-child').innerHTML;
    var currentIcon = this._internalVars.navigatorActive.querySelector('.geico-icon').cloneNode(true);

    if(this._internalVars.navigatorActive.classList.contains('alert')){
        currentIcon.setAttribute('class', 'geico-icon icon-alert-exclamation');
    }

    this._internalVars.navigatorMobileBarExpand.querySelector('h3').innerHTML = current;
    this._internalVars.navigatorMobileBarExpand.querySelector('.active-icon').appendChild(currentIcon);

    var currentSubtext = document.createElement('P');
    if(this._internalVars.navigatorActive.querySelector('p')){
        var subtext = document.querySelector('.active p').innerHTML;
        currentSubtext.innerHTML = subtext;
        this._internalVars.navigatorMobileBarExpand.querySelector('aside').appendChild(currentSubtext);
    }else if(!this._internalVars.navigatorActive.querySelector(('p'))){
        this._internalVars.navigatorMobileBarExpand.querySelector('aside').classList.add('no-current-subtext');
    }

    for(var h=0; h < this._internalVars.navigatorOrderedList.children.length; h++){
        var li = this._internalVars.navigatorOrderedList.children[h];

        var div = document.createElement('div');
        var a = document.createElement('a');
        var span1 = document.createElement('span');
        var span2 = document.createElement('span');
        var span3 = document.createElement('span');

        span1.setAttribute('class', li.querySelector('span:first-child').getAttribute('class'));

        if(li.classList.contains('alert')){
            span1.setAttribute('class', 'icon-alert-exclamation');
            if(li.classList.contains('active')){
                span1.classList.add('geico-icon');
            }
        }

        if(!li.classList.contains('active')){
            span1.classList.remove('geico-icon');
            span1.classList.remove('geico-icon--actionable');
        }
        span1.classList.remove('geico-icon--dotted');



        if(li.classList.contains('disabled')){
            div.classList.add('disabled');
        }

        if(li.classList.contains('active')){
            div.classList.add('active');
        }

        span2.appendChild(document.createTextNode(li.querySelector('a').innerText));
        if(li.querySelector('p')){
            var br = document.createElement('br');
            var aside = document.createElement('aside');
            aside.appendChild(document.createTextNode(li.querySelector('p').innerText));
            span2.appendChild(br);
            span2.appendChild(aside);
        }else{
            div.classList.add("no-subtext");
        }
        span3.setAttribute('class', 'icon-chevron-right');

        for (var i = li.querySelector('a').attributes.length - 1; i > -1; -- i) {

            var attribute = li.querySelector('a').attributes[i];
            a.setAttribute(attribute.name, attribute.value);

        }

        a.appendChild(span1);
        a.appendChild(span3);
        a.appendChild(span2);


        div.appendChild(a);

        this._internalVars.navigatorMobileList.appendChild(div);
    }
}

/* Set the width of the side navigation to 250px */
function openNav() {
    if(this._internalVars.node.classList.contains('close')&& this._internalVars.navigatorOrderedList.classList.contains('close')){
        this._internalVars.node.classList.remove('close');
        this._internalVars.navigatorOrderedList.classList.remove('close');
    }
    this._internalVars.node.classList.add('open');
    this._internalVars.navigatorOrderedList.classList.add('open');
}

/* Set the width of the side navigation to 0 */
function closeNav() {
    if(this._internalVars.node.classList.contains('open')&& this._internalVars.navigatorOrderedList.classList.contains('open')){
        this._internalVars.node.classList.remove('open');
        this._internalVars.navigatorOrderedList.classList.remove('open');
    }
    this._internalVars.node.classList.add('close');
    this._internalVars.navigatorOrderedList.classList.add('close');
}

function toggleDisplay(){
    document.querySelector('.navigator-menu').classList.toggle('expanded');
    $('.navigator-menu').slideToggle();
    this._internalVars.navigatorMobileBarExpand.classList.toggle('hidden-sm');
}

/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
        this._internalVars.nodeMobile = document.querySelector(this._options.contentMobile);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
        this._internalVars.nodeMobile = this._options.contentMobile;
    }

    this._internalVars.navigatorOrderedList = this._internalVars.node.querySelector('ol');
    this._internalVars.navigatorActive = this._internalVars.node.querySelector('.active');

    this._internalVars.navigatorMobileBarExpand = this._internalVars.nodeMobile.querySelector('.navigator-bar-expand');

    this._internalVars.navigatorMobileMenuContainer = this._internalVars.nodeMobile.querySelector('.navigator-menu');
    this._internalVars.navigatorMobileList = this._internalVars.nodeMobile.querySelector('.navigator-list');
    this._internalVars.navigatorMobileCollapse = this._internalVars.nodeMobile.querySelector('.navigator-collapse');

    this._internalVars.assignEventsHandler = assignEvents.bind(this);
    this._internalVars.toggleDisplayHandler = toggleDisplay.bind(this);
    this._internalVars.openNavHandler = openNav.bind(this);
    this._internalVars.closeNavHandler = closeNav.bind(this);
    this._internalVars.scrollHandler = toggleFixedScroll.bind(this);
    this._internalVars.addHoverColorHandler = addHoverColor.bind(this);
    this._internalVars.removeHoverColorHandler = removeHoverColor.bind(this);
}

export default Navigator;