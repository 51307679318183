import appState from '../appState';

import baseComponent from '../baseComponent';

const validateSettings = [
  {
    setting                 :   "content",
    isRequired              :   true,
    validate                :   "type",
    possibleValues          :   ["string","object"],
    errorMessage            :   ["GDK Tabs : Content must be defined and set to a DOM selector or Node"]
  }
];

class EditComponent {
  /**
   * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
   * @param {string|Object} content
   *  A reference to the edit component node
   */
  constructor(options) {

    this._internalVars = {};

    //options with defaults set
    this._defaults = {

    };

    // Create options by extending defaults with the passed in arugments
    if (options && typeof options === "object") {
      this._options = baseComponent.extendDefaults(this._defaults, options);
    }

    //if the required options are valid set up the environment
    if( baseComponent.validateSettings(this._options, validateSettings) ){
      this._internalVars.contentType = baseComponent.getContentType(this);
      setLocalVars.call(this);

      setEvents.call(this);

    }
  }

  //Public Methods

  /**
   * removes the node from the dom and any events attached
   */
  destroy(){
    removeEvents.call(this);
    this._internalVars.node.parentNode.removeChild(this._internalVars.node);

    //a little garbage collection
    for (var variableKey in this){
      if (this.hasOwnProperty(variableKey)){
        delete this[variableKey];
      }
    }
  }
}

function setLocalVars() {
  //determine the type of content passed in
  if(this._internalVars.contentType === 'string'){
    this._internalVars.node = document.querySelector(this._options.content);
  }else if(this._internalVars.contentType === 'domNode'){
    this._internalVars.node = this._options.content;
  }
  this._internalVars.editFormPlaceholder = this._internalVars.node.querySelector('.edit-form-placeholder');
  this._internalVars.editForm = this._internalVars.editFormPlaceholder.querySelector('form');
  this._internalVars.editBtn = this._internalVars.node.querySelector('.btn--edit-list');
  this._internalVars.cancelBtn = this._internalVars.editForm.querySelector('.btn-cancel');
  this._internalVars.submitBtn = this._internalVars.editForm.querySelector('.btn-submit');

  this._internalVars.editHandler = expandEditForm.bind(this);
  this._internalVars.cancelHandler = collapseEditForm.bind(this);
  this._internalVars.submitHandler = handleFormSubmission.bind(this);
}

function init() {
  this._internalVars.editFormPlaceholder.style.maxHeight = 0 + "px";
}

function setEvents() {
  let eventName = 'click';
  this._internalVars.editBtn.addEventListener(eventName, this._internalVars.editHandler);
//    Form Controls
  this._internalVars.cancelBtn.addEventListener(eventName, this._internalVars.cancelHandler);
  this._internalVars.submitBtn.addEventListener(eventName, this._internalVars.submitHandler);
}

function removeEvents(){
  let eventName = 'click';
  this._internalVars.editBtn.addEventListener(eventName, this._internalVars.editHandler);
//    Form Controls
  this._internalVars.cancelBtn.addEventListener(eventName, this._internalVars.cancelHandler);
  this._internalVars.submitBtn.addEventListener(eventName, this._internalVars.submitHandler);
}

function expandEditForm(event) {

  let editContent = this._internalVars.editFormPlaceholder.querySelector(".edit-content");
  let editPlaceholder = this._internalVars.editFormPlaceholder;

  $(editPlaceholder).slideDown();

  let editButton = event.currentTarget;
  if(!editButton.classList.contains('hidden')) {
    editButton.classList.add("hidden");
  }
}
function collapseEditForm(event) {
  event.preventDefault();
  let editPlaceholder = this._internalVars.editFormPlaceholder;
  $(editPlaceholder).slideUp();
  let editButton = this._internalVars.editBtn;
  if(editButton.classList.contains('hidden')) {
    editButton.classList.remove("hidden");
  }
}
function handleFormSubmission(event) {
  event.preventDefault();
  collapseEditForm.bind(this, event);
  setTimeout(()=>{
    this._internalVars.editForm.submit();
  }, 700);
}

export default EditComponent;