import baseComponent from '../baseComponent';
import appState from "../appState";

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Confirmation : Content must be defined and set to a DOM selector or Node"]
    },
    ];


class Confirmation{

    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the html accordion node
     *
     */
    constructor(options) {

        this._internalVars = {
            node: null,//used for content item
        };

        //options with defaults set
        this._defaults = {
            animateCheckmarkFunction: animateCheckmark.bind(this)
        };

        // Create options by extending defaults with the passed in arguments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
        }
    }

    //Public Methods

    /**
     * animateCheckmarkConfirmation()
     * animates the check mark icon
     */
    animateCheckmarkConfirmation(){
        animateCheckmark.call(this)
    }


    /**
     * destroy()
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    window.addEventListener("load", this._defaults.animateCheckmarkFunction)
}


/**
 * removeEvents()
 * removes all events from the component
 */
function removeEvents() {
    window.removeEventListener("load", this._defaults.animateCheckmarkFunction)

}


function animateCheckmark() {
    if(this._internalVars.animationComplete !== true) {
        setTimeout(svgAnim.bind(this), 2500);
        this._internalVars.animationComplete = true;
    }
}

let tick = 0;

function svgAnim() {
    let svgToAnim = this._internalVars.node.querySelector("svg"),
        circleWrapper = this._internalVars.node.querySelector(".confirmation-icon-wrapper"),
        dashArrayComplete = 183,//svg animation settings
        tickSpeed = 3.5,//svg animation settings
        tickSpeedMobile = 5;//svg animation settings

        if (appState.mode === "desktop") {
            tick += tickSpeed;
        } else {
            tick += tickSpeedMobile;
        }

        if(!circleWrapper.classList.contains("circleConfirmation")){
            circleWrapper.classList.add("circleConfirmation")
        }

        let t = tick / 100;
        let offsetStrokeDasharray = easeInQuad(t, 0, dashArrayComplete, 1);
        svgToAnim.style.strokeDasharray = `${offsetStrokeDasharray} , ${dashArrayComplete}`;

        if (tick < 100) {
            requestAnimationFrame(svgAnim.bind(this));
        } else {
            tick = 0;
        }
}

/**
 * easeInQuad()
 * Quadratic easing
 */
function easeInQuad(t, b, c, d) {
    var ts = (t /= d) * t;
    return b + c * (ts);
}


/**
 * setLocalVars()
 * set all the local vars to passed in options
 */
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.animationComplete = false


}


export default Confirmation;
