import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

const validateSettings = [
    {
        setting: "content",
        isRequired: true,
        validate: "type",
        possibleValues: ["string", "object"],
        errorMessage: ["GDK Payment Plans : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting: "initialActivePayPlan",
        isRequired: false,
        validate: "type",
        possibleValues: ["number"],
        errorMessage: ["GDK Payment Plans : Content must be defined and set to a number"]
    },
    {
        setting: "onPayPlanSelection",
        isRequired: false,
        validate: "type",
        possibleValues: ["function"],
        errorMessage: ["GDK Payment Plans : Content must be defined and set to a function"]
    }
];

class PayPlans {
    /**
     * PayPlans Constructor
     * @param {Object} options sets _internalVars, _defaults, creates options, validates settings, sets up the environment
     */
    constructor(options) {

        console.log('Payment Plans initialized');

        this._internalVars = {
            node: null //used for current node
        };

        //options with defaults set
        this._defaults = {

        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }


        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            setEvents.call(this);
            checkPayPlans.call(this);

            Array.prototype.forEach.call(this._internalVars.payPlanCardTopObject, (element)=> {
                if(!element.getAttribute('tabindex'))
                    element.setAttribute('tabindex', '0');
                element.setAttribute('role', 'radio');
                if(!element.getElementsByTagName('input')[0].getAttribute('tabindex'))
                    element.getElementsByTagName('input')[0].setAttribute('tabindex', '-1');
                if(!element.getAttribute('aria-checked'))
                    element.setAttribute('aria-checked', 'false');
            });

            Array.prototype.forEach.call(this._internalVars.payPlanInnerWrapperObjects, (el)=> {
                el.querySelectorAll('.payment-plan-row')[0].setAttribute('role', 'radiogroup');
            });

            Array.prototype.forEach.call(this._internalVars.payPlanCardTopObject, (element)=> {
                element.getElementsByTagName('input')[0].setAttribute('tabindex', '-1');
            });

            if (this._options.initialActivePayPlan && this._options.initialActivePayPlan <= this._internalVars.payPlanCardTopObject.length) {
                this.setPayPlan.call(this, this._options.initialActivePayPlan);
            }
        }
    }

    /**
     * setPayPlan(payPlanNumber)
     * @param payPlanNumber
     * Sets the selected pay plan.
     */
    setPayPlan(payPlanNumber) {
        setPayPlan.call(this, payPlanNumber);
    }

    /**
     * getPayPlan()
     * @returns {NodeListOf<Element>}
     * Returns a node list of the selected payment plan (top card & bottom card)
     */
    getPayPlan() {
        let selectedPlan = document.querySelectorAll('.' + this._internalVars.payPlanSelectedClass);
        return selectedPlan;
    }

    /**
     * setCarouselArrows()
     * Removes the carousel arrows if all pay plans can be viewed on the page.
     */
    setCarouselArrows() {
        checkPayPlans.call(this);
    }

    /**
     * removes the node from the dom and any events attached
     */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }
}

// Private Methods

/**
 * setEvents()
 * Sets all the events needed for the component
 */
function setEvents() {
    let eventName = UserAgentService._clickEventName();

    Array.prototype.forEach.call(this._internalVars.payPlanCards, (el)=> {
        el.addEventListener(eventName, setPayPlan.bind(this));
        el.addEventListener('keyup', setPayPlan.bind(this));
        el.addEventListener('mouseover', setState.bind(this));
        el.addEventListener('mouseout', resetState.bind(this));
        el.addEventListener('focus', setState.bind(this));
        el.addEventListener('blur', resetState.bind(this));
    });

    Array.prototype.forEach.call(this._internalVars.carouselBtnObjects, (el)=> {
        el.addEventListener(eventName, carouselMovement.bind(this));
        el.addEventListener('keyup', carouselMovement.bind(this));
    });

    Array.prototype.forEach.call(this._internalVars.expandLink, (el)=> {
        el.addEventListener(eventName, expand.bind(this));
    });

    window.addEventListener('resize', resetCarouselDefaults.bind(this));
}

/**
 * Assigns a class to an internal variable based on the event type
 * @param {Event} event Event type
 */
function setEventType(event) {
    if (event === 'mouseover' || event === 'mouseout') {
        this._internalVars.eventType = 'hover';
    } else {
        this._internalVars.eventType = 'focus';
    }
}

/**
 * Evaluates which row (top or bottom of cards) and card in that row that should be targeted when setting states
 * @param {Event} e Event type
 */
function setRowIndex(e) {
    let currentNode = e.currentTarget,
        payPlanWrapper = currentNode.parentNode.parentNode,
        rowWrapper = currentNode.parentNode,
        payPlanCard = rowWrapper.querySelectorAll(this._internalVars.payPlanCardClass),
        index = [].indexOf.call(payPlanCard, currentNode),
        rowIndex = [].indexOf.call(payPlanWrapper.querySelectorAll('.payment-plan-row'), rowWrapper);

    if (currentNode.classList.contains(this._internalVars.payPlanCardTopClass)) {
        rowIndex = rowIndex + 1;
    } else {
        rowIndex = rowIndex - 1;
    }

    this._internalVars.rowIndex = rowIndex;
    this._internalVars.cardIndex = index;
}

/**
 * Sets state of card classes (hover, focus) based on the interaction with the card counterpart (top/bottom)
 * @param {Event} e Event type
 */
function setOtherCardState(e) {
    setRowIndex.call(this, e);

    e.currentTarget.parentNode.parentNode.children[this._internalVars.rowIndex].children[this._internalVars.cardIndex].classList.add(this._internalVars.eventType);
}

/**
 * Sets the state of card classes (hover, focus) of the card interacted with (top/bottom)
 * @param {Event} e Event type
 */
function setState(e) {
    let event = e.type;
    setEventType.call(this, event);

    e.currentTarget.classList.add(this._internalVars.eventType);

    setOtherCardState.call(this, e);
}

/**
 * Removes the state of card classes (hover, focus) of the card (top and bottom) containing the state
 * @param {Event} e Event type
 */
function resetState(e) {
    let event = e.type;
    setEventType.call(this, event);

    Array.prototype.forEach.call(this._internalVars.node.querySelectorAll('.' + this._internalVars.eventType), (el)=> {
        el.classList.remove(this._internalVars.eventType);
    });
}

function setPayPlan(eventOrNumber) {
    if (typeof eventOrNumber === 'number') {
        let cardNumber = eventOrNumber;
        let cardTop = this._internalVars.payPlanCardTopObject[cardNumber-1];

        if(cardTop) {
            resetSelected.call(this);
            cardTop.classList.add(this._internalVars.payPlanSelectedClass);
            cardTop.classList.add(this._internalVars.focusClass);
            cardTop.children[0].checked = true;
            cardTop.setAttribute('aria-checked', 'true');
            cardTop.parentNode.parentNode.children[1].children[cardNumber-1].classList.add(this._internalVars.payPlanSelectedClass);
            cardTop.parentNode.parentNode.children[1].children[cardNumber-1].classList.add(this._internalVars.focusClass);
            payPlanInView.call(this);
        }
    } else {
        let event = eventOrNumber;
        if(event.type === 'keypress' || event.type === 'keyup' && ((event.keyCode || event.which) !== 13)){} else {
            resetSelected.call(this);

            event.currentTarget.classList.add(this._internalVars.payPlanSelectedClass);
            event.currentTarget.classList.add(this._internalVars.focusClass);

            setRowIndex.call(this, event);

            if (event.currentTarget.classList.contains('card-top')) {
                event.currentTarget.children[0].checked = true;
                event.currentTarget.setAttribute('aria-checked', 'true');
            } else {
                event.currentTarget.parentNode.parentNode.children[this._internalVars.rowIndex].children[0].checked = true;
                event.currentTarget.parentNode.parentNode.children[this._internalVars.rowIndex].setAttribute('aria-checked', 'true');
            }

            event.currentTarget.parentNode.parentNode.children[this._internalVars.rowIndex].children[this._internalVars.cardIndex].classList.add(this._internalVars.payPlanSelectedClass);
            event.currentTarget.parentNode.parentNode.children[this._internalVars.rowIndex].children[this._internalVars.cardIndex].classList.add(this._internalVars.focusClass);

            payPlanInView.call(this);
        }
    }

    if (this._options.onPayPlanSelection) {
        this._options.onPayPlanSelection(this.getPayPlan.call(this));
    }
}

function resetSelected() {
    let selected = this._internalVars.payPlanObject.querySelectorAll('.' + this._internalVars.payPlanSelectedClass);

    selected[0].children[0].checked = false;
    selected[0].setAttribute('aria-checked', 'false');

    Array.prototype.forEach.call(selected, (el) => {
        el.classList.remove(this._internalVars.payPlanSelectedClass);
        el.classList.remove(this._internalVars.focusClass);
    });
}

function carouselMovement(event) {
    event.preventDefault();
    if(event.type == 'keypress' || event.type == 'keyup' && ((event.keyCode || event.which) != 13)){} else {
        if(!event.target.hasAttribute('disabled')) {
            if(!this._internalVars.defaultsSet) {
                setCarouselDefault.call(this);
            }
            if(this._internalVars.carouselInMotion === false) {
                this._internalVars.carouselInMotion = true;
                carouselSlide.call(this, event);
            }
        }
    }
}

function setCarouselDefault() {
    if(this._internalVars.payPlanWrapperObjects[1]) {
        this._internalVars.carouselCardObejcts = this._internalVars.payPlanWrapperObjects[1].querySelectorAll(this._internalVars.payPlanCardClass);
    } else {
        this._internalVars.carouselCardObejcts = this._internalVars.payPlanWrapperObjects[0].querySelectorAll(this._internalVars.payPlanCardClass);
    }
    this._internalVars.corouselCardLength = this._internalVars.carouselCardObejcts.length / 2;
    this._internalVars.carouselInitialWidth = this._internalVars.carouselMoveObject.clientWidth;
    this._internalVars.carouselCardWidth = Math.ceil(this._internalVars.carouselCardObejcts[0].offsetWidth) + 10;
    this._internalVars.carouselAcutalWidth = this._internalVars.carouselCardWidth * this._internalVars.corouselCardLength;
    this._internalVars.cardsOnLeft = 0;
    this._internalVars.cardsOnRight = this._internalVars.corouselCardLength - this._internalVars.cardsOnLeft - 3;

    this._internalVars.defaultsSet = true;
}

function carouselSlide(event) {
    let operand = null,
        currentMarginValue = this._internalVars.carouselMoveObject.style.marginLeft,
        currentMargin = currentMarginValue.slice(0, -2),
        movementValue = this._internalVars.carouselCardWidth,
        newMargin = null,
        disableRightArrow = false,
        disableLeftArrow = false;

    let cardsRight = 0;
    let cardsLeft = 0;

    let currentNode = document.querySelectorAll('.' + this._internalVars.payPlanSelectedClass)[0],
        payPlanWrapper = currentNode.parentNode.parentNode,
        arrowWdith = document.querySelector('.carousel').offsetWidth,
        payPlanWrapperLeft = payPlanWrapper.offsetLeft - arrowWdith,
        outerWrapper = currentNode.parentNode.parentNode.parentNode;

    Array.prototype.forEach.call(this._internalVars.payPlanCardTopObject, (card) => {
        if (card.offsetLeft + (card.clientWidth + 5) + payPlanWrapperLeft > outerWrapper.clientWidth) {
            cardsRight++;
        }
        if (card.offsetLeft + payPlanWrapper.offsetLeft < 0) {
            cardsLeft--;
        }
    });

    if (event.target.classList.contains('icon-chevron-right')) {
        operand = '-';
        cardsLeft = cardsLeft - 1;
        cardsRight = cardsRight - 1;
    } else {
        operand = '+';
        cardsLeft = cardsLeft + 1;
        cardsRight = cardsRight + 1;
    }

    if (operand === '-') {
        if (cardsRight === 0) {
            newMargin = -(this._internalVars.corouselCardLength * this._internalVars.carouselCardWidth) +  this._internalVars.carouselMoveObject.parentNode.clientWidth;
            disableRightArrow = true;
        } else {
            newMargin = currentMargin - movementValue;
            disableRightArrow = false;
        }
    } else {
        if ( cardsLeft === 0 || cardsLeft === -0 ) {
            newMargin = 0;
            disableLeftArrow = true;
        } else {
            newMargin = Number(currentMargin) + movementValue;
            disableLeftArrow = false;
        }
    }

    this._internalVars.carouselMoveObject.style.marginLeft = newMargin + 'px';

    this._internalVars.cardsOnLeft = cardsLeft;
    this._internalVars.cardsOnRight = cardsRight;

    setTimeout(enableArrows.bind(this), 360);

    function enableArrows() {
        this._internalVars.carouselInMotion = false;
    }

    setArrowState.call(this, disableRightArrow, disableLeftArrow);
}

function setArrowState(disableRightArrow, disableLeftArrow) {
    let rightArrow = document.querySelector('.icon-chevron-right'),
        leftArrow = document.querySelector('.icon-chevron-left');

    if (disableRightArrow === true) {
        rightArrow.setAttribute('disabled', 'disabled');
        rightArrow.setAttribute('aria-label', 'Right button disabled');
        rightArrow.parentNode.classList.add(this._internalVars.carouselDisabledClass);
    } else {
        rightArrow.removeAttribute('disabled');
        rightArrow.parentNode.classList.remove(this._internalVars.carouselDisabledClass);
        rightArrow.setAttribute('aria-label', 'Right button');
    }

    if (disableLeftArrow === true) {
        leftArrow.setAttribute('disabled', 'disabled');
        leftArrow.parentNode.classList.add(this._internalVars.carouselDisabledClass);
        leftArrow.setAttribute('aria-label', 'Left button disabled');
    } else {
        leftArrow.removeAttribute('disabled');
        leftArrow.parentNode.classList.remove(this._internalVars.carouselDisabledClass);
        leftArrow.setAttribute('aria-label', 'Left button');
    }
}

function resetCarouselDefaults() {
    this._internalVars.defaultsSet = false;

    if (window.innerWidth > 911) {

        if(this._internalVars.viewPortCheck === true) {
            let delay = setTimeout(resetCarouselPos.bind(this), 30);
            this._internalVars.carouselMoveObject.style.position = 'absolute';
            this._internalVars.carouselMoveObject.style.left = 0 + 'px';
            this._internalVars.viewPortCheck = false;

            let disableLeftArrow = true;
            let disableRightArrow = false;
            setArrowState.call(this, disableRightArrow, disableLeftArrow);
        }

        this._internalVars.cardsOnLeft = 0;
        this._internalVars.cardsOnRight = this._internalVars.corouselCardLength - this._internalVars.cardsOnLeft - 2;

    } else {
        this._internalVars.carouselMoveObject.removeAttribute('style');
        this._internalVars.viewPortCheck = true;
    }
}

function resetCarouselPos() {
    this._internalVars.carouselMoveObject.style.position = 'relative';
    this._internalVars.carouselMoveObject.style.marginLeft = 0 + 'px';
    this._internalVars.carouselMoveObject.style.removeProperty('left');
}

function payPlanInView() {
    let currentNode = document.querySelectorAll('.' + this._internalVars.payPlanSelectedClass)[0],
        payPlanWrapper = currentNode.parentNode.parentNode,
        arrowWdith = document.querySelector('.carousel').offsetWidth,
        payPlanWrapperLeft = payPlanWrapper.offsetLeft - arrowWdith,
        outerWrapper = currentNode.parentNode.parentNode.parentNode,
        left = null,
        disableRightArrow = false,
        disableLeftArrow = false;

    if (window.innerWidth > 911) {
        if(!this._internalVars.defaultsSet) {
            setCarouselDefault.call(this);
        }

        let cards = this._internalVars.payPlanCardTopObject.length;
        let length = (this._internalVars.payPlanCardTopObject[0].offsetWidth + 5) * cards;

        if (currentNode.offsetLeft + (currentNode.clientWidth + 5) + payPlanWrapperLeft > outerWrapper.clientWidth) {
            left = currentNode.offsetLeft - (outerWrapper.clientWidth - currentNode.offsetWidth);
        }

        if (currentNode.offsetLeft + payPlanWrapper.offsetLeft < 0) {
            left = currentNode.offsetLeft
        }

        this._internalVars.carouselMoveObject.style.marginLeft = '-' + left + 'px';

        let cardsRight = 0;
        let cardsLeft = 0;

        Array.prototype.forEach.call(this._internalVars.payPlanCardTopObject, (card) => {
            if (card.offsetLeft + (card.clientWidth + 5) + payPlanWrapperLeft > outerWrapper.clientWidth) {
                cardsRight++;
            }
            if (card.offsetLeft + payPlanWrapper.offsetLeft < 0) {
                cardsLeft--;
            }
        });

        if (left !== null) {
            if (left < 10) {
                disableLeftArrow = true;
            }

            if (outerWrapper.clientWidth + left > length) {
                disableRightArrow = true;
            }

            setArrowState.call(this, disableRightArrow, disableLeftArrow);
        }

        this._internalVars.cardsOnLeft = cardsLeft;
        this._internalVars.cardsOnRight = cardsRight;
    } else {

        if (currentNode.offsetLeft + currentNode.clientWidth - outerWrapper.scrollLeft > outerWrapper.clientWidth) {
            left = currentNode.offsetLeft - (outerWrapper.clientWidth - currentNode.clientWidth - 15);
            $(outerWrapper).animate({
                scrollLeft: left
            }, 350);
        }

        if (outerWrapper.scrollLeft > currentNode.offsetLeft) {
            left = currentNode.offsetLeft;
            $(outerWrapper).animate({
                scrollLeft: left
            }, 350);
        }
    }
}

/**
 * hides carousel arrows if 3 or less additional pay plans exist
 */
function checkPayPlans() {
    let viewMorePayPlans = this._internalVars.carouselMoveObject.querySelectorAll('.' + this._internalVars.payPlanCardTopClass);
    if (viewMorePayPlans.length < 4) {
        Array.prototype.forEach.call(this._internalVars.carouselObjects, (array)=> {
            array.style.display = 'none';
        });
    } else {
        this._internalVars.carouselMoveObject.parentElement.classList.add('pay-plan-carousel');
    }
}

function expand(event) {
    event.preventDefault();
    let wrapper = event.currentTarget.parentElement;
    let icon = wrapper.querySelectorAll('span')[1];
    if (wrapper.classList.contains('collapsed')) {
        wrapper.classList.remove('collapsed');
        $(this._internalVars.premiumClass).slideDown();
        wrapper.querySelector('a').innerHTML = "Hide Payment Schedule";
        wrapper.setAttribute('aria-expanded', 'true');
        icon.classList.remove('icon-chevron-down');
        icon.classList.add('icon-chevron-up');

    } else {
        wrapper.classList.add('collapsed');
        $(this._internalVars.premiumClass).slideUp();
        wrapper.querySelector('a').innerHTML = "Show Payment Schedule";
        wrapper.setAttribute('aria-expanded', 'false');
        icon.classList.remove('icon-chevron-up');
        icon.classList.add('icon-chevron-down');
    }
}

/**
 * setLocalVars()
 * set local vars to the ones passed in options
 */
function setLocalVars() {
    if(this._internalVars.contentType === 'string') {
        this._internalVars.node = document.querySelector(this._options.content);
    } else if (this._internalVars.contentType === 'domNode') {
        this._internalVars.node = this._options.content;
    }

    this._internalVars.payPlanObject = this._internalVars.node;

    this._internalVars.payPlanCardClass = '.payment-plan-card';
    this._internalVars.payPlanCards = this._internalVars.payPlanObject.querySelectorAll(this._internalVars.payPlanCardClass);

    this._internalVars.payPlanCardTopClass = 'card-top';
    this._internalVars.payPlanCardTopObject = this._internalVars.node.querySelectorAll('.' + this._internalVars.payPlanCardTopClass);

    this._internalVars.hoverClass = 'hover';
    this._internalVars.focusClass = 'focus';

    this._internalVars.payPlanSelectedClass = 'payment-plan-selected';

    this._internalVars.carouselClass = 'carousel';
    this._internalVars.carouselObjects = this._internalVars.node.querySelectorAll('.' + this._internalVars.carouselClass);
    this._internalVars.carouselInMotion = false;

    this._internalVars.carouselBtnClass = 'carousel-icon';
    this._internalVars.carouselBtnObjects = this._internalVars.node.querySelectorAll('.' + this._internalVars.carouselBtnClass);

    this._internalVars.carouselDisabledClass = 'disabled';

    this._internalVars.payPlanWrapperClass = 'payment-plan-wrapper';
    this._internalVars.payPlanWrapperObjects = this._internalVars.payPlanObject.querySelectorAll('.' + this._internalVars.payPlanWrapperClass);

    this._internalVars.defaultsSet = false;

    this._internalVars.payPlanInnerWrapperClass = 'payment-plan-inner-wrapper';
    this._internalVars.payPlanInnerWrapperObjects = this._internalVars.payPlanObject.querySelectorAll('.' + this._internalVars.payPlanInnerWrapperClass);
    if (this._internalVars.payPlanWrapperObjects[1]) {
        this._internalVars.carouselMoveObject = this._internalVars.payPlanWrapperObjects[1].querySelector('.' + this._internalVars.payPlanInnerWrapperClass);
    } else {
        this._internalVars.carouselMoveObject = this._internalVars.payPlanWrapperObjects[0].querySelector('.' + this._internalVars.payPlanInnerWrapperClass);
    }

    this._internalVars.viewPortCheck = false;
    this._internalVars.rowIndex = null;
    this._internalVars.cardIndex = null;
    this._internalVars.eventType = null;

    this._internalVars.expandLinkClass = 'payment-schedule-link';
    this._internalVars.expandLinkWrapper = this._internalVars.payPlanObject.querySelector('.' + this._internalVars.expandLinkClass);
    this._internalVars.expandLink = this._internalVars.expandLinkWrapper.querySelectorAll('span');
    this._internalVars.premiumClass = '.payment-schedule';
}

export default PayPlans;