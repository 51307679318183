import * as utils from '../utils';
import baseComponent from '../baseComponent';
import UserAgentService from '../services/UserAgentService';

let PromiseIE = require('es6-promise-polyfill').Promise;

const validateSettings = [
    {
        setting                 :   "content",
        isRequired              :   true,
        validate                :   "type",
        possibleValues          :   ["string","object"],
        errorMessage            :   ["GDK Modal : Content must be defined and set to a DOM selector or Node"]
    },
    {
        setting                 :   "autoShow",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK Modal : autoShow must be a boolean"]
    },
    {
        setting                 :   "overlayShouldCloseModal",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK Modal : overlayShouldCloseModal must be a boolean"]
    },
    {
        setting                 :   "hideCloseButton",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["boolean"],
        errorMessage            :   ["GDK Modal : hideCloseButton must be a boolean"]
    },
    {
        setting                 :   "onOpened",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK Modal : onOpened must be a function"]
    },
    {
        setting                 :   "onClosed",
        isRequired              :   false,
        validate                :   "type",
        possibleValues          :   ["function"],
        errorMessage            :   ["GDK Modal : onClosed must be a function"]
    }

];


const modalCloseBtn = '<button class="btn-close icon-close" type="button" aria-label="Close modal"></button>';


class Modal{
    /**
     * These are settings for the instantiation. Refer to the design kit section of this component for JS setting examples.
     * @param {string|Object} content
     * A reference to the modal node
     *
     * @param {boolean} [autoShow=false]
     * Use if you want to model to auto show when instantiated
     *
     * @param {boolean} [overlayShouldCloseModal=true]
     * Set to false if you do not want the overlay to have click to close functionality
     *
     * @param {boolean} [hideCloseButton=false]
     * Set to true if you want to hide the close button in the upper right corner of the modal
     *
     * @param {function} [onOpened]
     * A callback function that gets fired when the modal has been opened
     *
     * @param {function} [onClosed]
     * A callback function that gets fired when the modal has been closed
     *
     */
    constructor(options) {

        this._internalVars = {
            node: null,//used for current node
            closeBtn : null
        };

        //options with defaults set
        this._defaults = {
            autoShow : false,
            overlayShouldCloseModal : true,
            hideCloseButton : false
        };

        // Create options by extending defaults with the passed in arugments
        if (options && typeof options === "object") {
            this._options = baseComponent.extendDefaults(this._defaults, options);
        }

        //if the required options are valid set up the environment
        if( baseComponent.validateSettings(this._options, validateSettings) ){
            this._internalVars.contentType = baseComponent.getContentType(this);
            setLocalVars.call(this);
            buildModal.call(this);
            setEvents.call(this);

            if(this._options.autoShow){
                this.show();
            }
        }

    }

    //Public Methods

    /**
    * show()
    * shows the modal
    */
    show(){

        this._internalVars.node.style.display = "block";

        setTimeout( ()=> {
            this._internalVars.node.classList.add("modal--show");
            if(this._options.onOpened)
                this._options.onOpened();
        }, 10);
        this._internalVars.inModalArray = [];
        document.addEventListener('keyup', this._internalVars.modalTabHandler);

    }

    /**
    * hide()
    * hides the modal
    */
    hide(duration = 500){
        this._internalVars.inModalArray = [];
        document.removeEventListener('keyup', this._internalVars.modalTabHandler);

        return new PromiseIE((resolve, reject) => {
            setTimeout( ()=> {
                this._internalVars.node.classList.remove("modal--show");
                this._internalVars.node.style.display = "none";
                if(this._options.onClosed)
                    this._options.onClosed();
                resolve();
            }, duration);
        });
    }

    /**
    * destroy()
    * removes the node from the dom and any events attached
    */
    destroy(){
        removeEvents.call(this);
        this._internalVars.node.parentNode.removeChild(this._internalVars.node);

        //a little garbage collection
        for (var variableKey in this){
            if (this.hasOwnProperty(variableKey)){
                delete this[variableKey];
            }
        }
    }

}


/**
* setLocalVars()
* set all the local vars to passed in options
*/
function setLocalVars() {
    //determine the type of content passed in
    if(this._internalVars.contentType === 'string'){
        this._internalVars.node = document.querySelector(this._options.content);
    }else if(this._internalVars.contentType === 'domNode'){
        this._internalVars.node = this._options.content;
    }

    this._internalVars.modalTabHandler = isolateTab.bind(this);
}


/**
* modalClicked()
* Gets fired when you click anywhere on a model
*/
function modalClicked(e) {

    //NodeList of items that the mouse is currently over in document order.
    //The last element in the NodeList is the most specific, each preceding
    //one should be a parent, grandparent, and so on.
    let nodes = document.querySelectorAll( ":hover" );

    if(this._internalVars.node == nodes[nodes.length- 1]){
        this.hide();
    }


}

/**
 * setEvents()
 * Sets all the events needed for this component
 */
function setEvents() {

    const eventName = UserAgentService._clickEventName();

    if(this._options.overlayShouldCloseModal){
        this._internalVars.node.addEventListener(eventName, modalClicked.bind(this) );
    }

    if(this._options.hideCloseButton){
        this._internalVars.closeBtn.classList.add("hidden");
    }
    else {
        this._internalVars.closeBtn.addEventListener(eventName, this.hide.bind(this) );
    }





}

/**
 * removeEvents()
 * removes all events from this component
 */
function removeEvents() {

    const eventName = UserAgentService._clickEventName();
    
    if(this._options.overlayShouldCloseModal){
        this._internalVars.node.removeEventListener(eventName, modalClicked.bind(this) );
    }
    this._internalVars.closeBtn.removeEventListener(eventName, this.hide.bind(this) );
}




/**
* buildModal()
* Adds needed markup this component
*/
function buildModal() {
    let modalContentDom = this._internalVars.node.querySelector(".modal-container");
    modalContentDom.insertAdjacentHTML('afterBegin', modalCloseBtn);

    this._internalVars.closeBtn = this._internalVars.node.querySelector(".btn-close");

    let spanTab = document.createElement('span');
    spanTab.setAttribute('tabindex', '0');
    spanTab.setAttribute('class', 'tab-filler');
    let cloneSpanTab = spanTab.cloneNode(true);
    modalContentDom.appendChild(spanTab);
    modalContentDom.insertBefore(cloneSpanTab, modalContentDom.children[0]);
}

function isolateTab(e){
    if(e.keyCode == 9 || (e.keyCode == 9 && e.shiftKey)){
        if (utils.isChild("modal", document.activeElement) && !document.activeElement.classList.contains('tab-filler') && !this._internalVars.inModalArray.includes(document.activeElement)) {
            this._internalVars.inModalArray.push(document.activeElement);
        }

        if (document.activeElement.classList.contains('tab-filler')) {
            if (this._internalVars.inModalArray.length > 0) {
                this._internalVars.inModalArray[0].focus();
            }
        }
    }
}


export default Modal;